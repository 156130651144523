<template>
    
  <div class="container">
  <table>
    <tbody>
            <span>
            <a class="pull-right vx-send-no" href="">Select documents to send notifications </a>
          </span>
      <div style="margin-bottom:20px!important" v-for = "(doc,index) in documents">
        <tr>
          <span style="color: darkred" v-if="check_expired(doc.expiry_date)"><i class="fas fa-exclamation-circle"></i> (EXPIRED) </span>

          <span class="progress-title" :id="'pro_title_'+doc.document_id">{{doc.doc_type}}</span>
          <div class="checkbox" v-if="doc.expiry_date != null">
	          <div class="check">
               <input type="checkbox" @change = "updateIds(doc.document_id)"/>
	            <div class="check-container">
	              <div class="check-off"></div>
	              <div class="check-on"><i></i></div>
	
	            </div>
	          </div>
	        </div>
          <div style="float:right" v-if="doc.expiry_date == null">
            <b style="font-size: 10px">Not applicable</b>
          </div>
        </tr>
        <tr>
          <td v-if="!check_expired(doc.expiry_date)" ><i style="font-size:10px!important">{{doc.date_of_issue}} - {{doc.expiry_date}}</i></td>
        </tr>
        <tr>
          <td>
            <div style="" class="progress progress-small">
              <div class="bar" 
              :duration = "doc.duration"
              :period_type = "doc.period_type"
              :elapsed_percent = "get_elapsed_percentage(doc.duration)"
              :expiry_date = "doc.expiry_date"
              :date_of_issue = "doc.date_of_issue"
              ></div>
            </div>
          </td>
        </tr>
        <tr>
        </tr>
      <hr/>

      </div>
          <span>
            <button style="padding:5px !important" :disabled = "enable_send_mail" id="send_not" @click.prevent = "send_notification" class="label label-primary pull-right vx-send-no" href=""><i class="fas fa-envelope"></i> Send Notification</button>
          </span>
    </tbody>
  </table>

<!-- basic modal -->
<div class="modal" id="basicModal_progress" tabindex="-1" role="dialog" aria-labelledby="basicModal_progress" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">{{seafarer_info.lastname}} {{seafarer_info.othername}} {{seafarer_info.firstname}} ({{seafarer_info.rank}})</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
            <h4 class="text-center">Select Notification Options</h4>
        </div>
      <div class="button-group-pills text-center" data-toggle="buttons">
        <label class="btn btn-default active">
          <input type="checkbox" name="options" checked="">
          <div>MOBILE : <i class="fas fa-mobile"></i> {{seafarer_info.phone}}</div>
        </label>
        <label class="btn btn-default">
          <input type="checkbox" name="options">
          <div>EMAIL : <i class="fas fa-envelope"></i> {{seafarer_info.email}}</div>
        </label>
      </div>
        <div id="modal_body_progress"></div>
      <div class="modal-footer">
          <span v-if="enable_send" style="font-size:small; color:crimson"><b>Email or Phone must be available</b></span>

        <button style="padding:5px !important" :disabled = "enable_send" @click.prevent = "submitEmail" type="button" id="send_notification" class="btn btn-primary">Notify</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
  


      </div>
    </div>
  </div>
</div>




</div>
</template>

<script>
import alert_mixins from '../mixins/alertify.jsx';
export default {
    mixins : [alert_mixins],
    props : {
      flagged_docs : {

      },
      seafarer_info : {
        required : true
      },
      documents : {
        required : true,
        years_left : null,
        months_left : null,
        days_left : null,
        minutes_left : null,
      }  
    },
   data(){
        return {
          percentage_complete : 0,
          doc_ids : []
        }
      },
    methods : {
      check_expired(expiry_date){
        var today = moment()
        var future = moment(expiry_date)

        var check_dur = future.diff(today);
        console.log(check_dur);
        if(check_dur < 1){
          return true
        }else{
          return false
        }

      },
       highlighExpiring(){
         if(this.flagged_docs){
          for(var item of this.flagged_docs){
            $("#pro_title_"+item.id).addClass("flagged");
            console.log("#pro_title_"+item.id);
          }
         }
      },
      submitEmail(){
        save_btn.start();
        axios.post('/test/send_manual_notification',{
          doc_ids : this.doc_ids,
          seafarer_id : this.seafarer_info.seafarer_id
        }).then(res => {
          if(res.data.status == "SUCCESS"){
            save_btn.stop();
            $('#basicModal_progress').modal('hide');
            this.alert({ 
              alert_type : "success",
              title : "Email Notification",
              alert_msg : "success",
              msg  : res.data.msg
            });
          }
        });
      },
      updateIds(id){
        var index = this.doc_ids.indexOf(id);
        if (index > -1) {
          this.doc_ids.splice(index, 1);
        }else{
          this.doc_ids.push(id);

        }
      },
      randomize() {
        const com_this = this;
        var expiry_date_att = null;
        var date_of_issue = null;
        $('.progress .bar').each(function() {
            expiry_date_att = $(this).attr('expiry_date');
            date_of_issue = $(this).attr('date_of_issue');
            if(expiry_date_att){
              com_this.ticking_time({
                element : $(this),
                expiry_date : expiry_date_att,
                date_of_issue : date_of_issue
              })
            }
        });
        return {
          date_of_issue : date_of_issue,
          date_of_exipry : expiry_date_att
        }
      },
       calculate_time_to_expiry(options){
        var date_of_issued = options.date_of_issue;
        var date_of_exipry = options.date_of_exipry;
        var current_date = moment();
        var end_date = moment(date_of_exipry);

        var period_left = moment.duration(end_date.diff(current_date))

        this.years_left  = period_left.asYears();
        this.months_left = period_left.asMonths();
        this.days_left = period_left.asDays();
        this.minutes_left = period_left.asMinutes();

        console.log({
          years_left : this.years_left,
          months_left : this.months_left,
          days_left : this.days_left,
          minutes_left : this.minutes_left
        })


        // console.log({
        //     date_of_issued : date_of_issued,
        //     current_date : current_date,
        //     endDate : end_date,
        //     endDateEndOfMonth : endDateEndOfMonth,
        // })

      },
      send_notification(){
          send_not.start()
          axios.get('/test/mail_template',{
                  params : {
                    seafarer_id: this.seafarer_info.seafarer_id
                  }
                })
               .then(res => {
                 send_not.stop()


                                      var iFrame = $('<iframe align="middle" id="thepage" name="thepage" style="width:100%;height:100%;"></iframe>');
$('#modal_body_progress').empty();
$('#modal_body_progress').append(iFrame);


                var iFrameDoc = iFrame[0].contentDocument || iFrame[0].contentWindow.document;
                iFrameDoc.write(res.data);
                iFrameDoc.close();
                $("#basicModal_progress").modal('show');

               })
      },
      cal_per_enlapsed(val){
        return val
      },
      cal_percentage(options){


        var creation_date = options.start_date;
        var completion_date = options.end_date;

        // get percentage completed (now - startDate) / (endDate - startDate)
        var now = moment();
        var startDate = moment(creation_date);
        var endDate = moment(completion_date);
        var percentage_complete = (now - startDate) / (endDate - startDate) * 100;
        var percentage_rounded = (Math.round(percentage_complete * 100) / 100); 
        // percentage rounded to 2 decimal points

        return percentage_complete;


        // set the progress bar percentages
        // var record_progress = $(this).find('.bar');
        // record_progress.css('width', percentage_rounded+'%');
        // record_progress.html(percentage_rounded+'% - Completed');
      },
      get_elapsed_percentage(duration){
        return 0
      },
      ticking_time(options){
        console.log(options);



       var $clock = options.element;
       var count_down_date = moment(options.expiry_date); 

        var eventTime = moment(count_down_date, 'DD-MM-YYYY HH:mm:ss').unix(),
            currentTime = moment().unix(),
            diffTime = eventTime - currentTime,
            duration = moment.duration(diffTime * 1000, 'milliseconds'),
            interval = 1000;


    // if time to countdown
    if(diffTime > 0) {

        // Show clock
        // $clock.show();

        var $yr = $('<td class="years" ></td>').appendTo($clock.parent().parent()),
            $mo = $('<td class="months" ></td>').appendTo($clock.parent().parent()),
            $d = $('<td class="days" ></td>').appendTo($clock.parent().parent()),
            $h = $('<td class="hours" ></td>').appendTo($clock.parent().parent()),
            $m = $('<td class="minutes" ></td>').appendTo($clock.parent().parent()),
            $s = $('<td class="seconds" ></td>').appendTo($clock.parent().parent());
            
        const com_this = this;

        setInterval(function(){

          var computed_percent =  com_this.cal_percentage({
              start_date : options.date_of_issue,
              end_date : options.expiry_date
            });

            $clock.attr('style', 'width:' + computed_percent + '%;background-position:0 ' + computed_percent + '% !important');
          

            duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');
            var yr = moment.duration(duration).years(),
                mo = moment.duration(duration).months(),
                d = moment.duration(duration).days(),
                h = moment.duration(duration).hours(),
                m = moment.duration(duration).minutes(),
                s = moment.duration(duration).seconds();

                com_this.years_left = yr;
                com_this.months_left = mo;
                com_this.days_left = d;
                com_this.hours_left = h;
                com_this.minutes_left = m;
                com_this.seconds_left = s;

            yr = $.trim(yr).length === 1 ? '0' + yr : yr;
            mo = $.trim(mo).length === 1 ? '0' + mo : mo;
            d = $.trim(d).length === 1 ? '0' + d : d;
            h = $.trim(h).length === 1 ? '0' + h : h;
            m = $.trim(m).length === 1 ? '0' + m : m;
            s = $.trim(s).length === 1 ? '0' + s : s;

            // show how many hours, minutes and seconds are left
            if(yr > 0){
              $yr.text(yr + "  YEARS |");
            }
            if(mo > 0){
              $mo.text(mo + "  MONTHS |");
            }
            if(d > 0){
              $d.text(d + "  DAYS |");
            }
            if(h > 0){
              $h.text(h + "  HOURS |");
            }
            if(m > 0){
              $m.text(m + " min : ");
            }
            if(s > 0){
              $s.text(s + " s");
            }

        }, interval);

    }

      }
    },
    computed : {
      enable_send(){
        if(!this.seafarer_info.email && !this.seafarer_info.phone){
          return true
        }else{
          return false
        }
      },
      enable_send_mail(){
        if(this.doc_ids.length < 1){
          return true
        }else{
          return false
        }
      },
    },
    mounted(){
      window.save_btn = Ladda.create( document.getElementById('send_notification'));
      window.send_not = Ladda.create( document.getElementById('send_not'));
        const com_this = this;
        const date_params = com_this.randomize();
        this.calculate_time_to_expiry(date_params);
        this.highlighExpiring();

    }
}
</script>


<style scoped>

.button-group-pills .btn {
  border-radius: 6px;
  margin-bottom: 15px;
  margin-left: 10px;
  border-color: #bbbbbb;
  background-color: #FFF;
  color: #14a4be;
  padding:7px 5px 0px 5px !important
}
.button-group-pills .btn.active {
  border-color: #14a4be;
  background-color: #14a4be;
  color: #FFF;
  box-shadow: none;
}
.button-group-pills .btn:hover {
  border-color: #158b9f;
  background-color: #158b9f;
  color: #FFF;
}
.ladda-button {
  padding: 0 !important;
      font-size: 14px !important;
      background: #00bca4 !important;
      color: #fff !important;
}

.flagged{
  color: crimson;
}


</style>

