var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-select",
        {
          attrs: {
            disabled: _vm.disable_com,
            clearable: false,
            label: "name",
            filterable: false,
            options: _vm.options
          },
          on: { input: _vm.updateInput, search: _vm.onSearch },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(option) {
                return [
                  _c("div", { staticClass: "d-center" }, [
                    _vm._v("\n      " + _vm._s(option.full_name) + "\n      ")
                  ])
                ]
              }
            },
            {
              key: "selected-option",
              fn: function(option) {
                return [
                  _c("div", { staticClass: "selected d-center" }, [
                    _vm._v("\n      " + _vm._s(_vm.selected) + "\n    ")
                  ])
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "no-options" }, [
            _vm._v("\n    enter Seafarer's ID to find...\n  ")
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }