<template>
    
</template>

<script>
export default {
    props: ["flash"],
    data(){
       return {
            email : null,
            password : null
       }
    },
    methods : {
        attempLogin(){
            axios.post("/users/sign_in",{
                data : {
                    email: this.email,
                password: this.password
                }
            }).then(res => {
                console.log(res);
            })
        }
    },
    mounted(){
        console.log(window.flash);
    }
}
</script>

<style>


</style>


