<template>
    
</template>

<script>
import vessel_current_embark from '../../utils/vessel_current_embark.vue';
import vessel_embarkation_hist from '../../utils/vessel_embarkation_hist.vue';
import comment_box from '../../utils/comment_box.vue';

export default {
    components : {vessel_current_embark,vessel_embarkation_hist,comment_box},
    props : {
        vessel : {

        }
    },
    data(){
        return {
            show_current_embark: false,
            show_vessel_report: true,
            show_embarkation_history : false,
             embark_columns : [
                {title:"Name", field:"seaf_name",width:150},
                {title:"Rank", field:"seaf_position",width:150},
                {title:"Gender", field:"seaf_gender"},
                {title:"Phone", field:"seaf_mob", align:"center"},
                {title:"Email", field:"seaf_email", align:"center"},
                {title:"Start Date", field:"start_date", formatter:"date"},
                {title:"End Date", field:"end_date"},
            ]
        }
    },
    methods : {
        toggle_embarkations_history(){
            this.show_current_embark = false;
            this.show_vessel_report = false;
            this.show_embarkation_history = true
        },
        toggle_embarkations(){
            this.show_current_embark = true;
            this.show_vessel_report = false;
            this.show_embarkation_history = false;
        },
        toggle_report(){
            this.show_current_embark = false;
            this.show_vessel_report = true;
            this.show_embarkation_history = false;
        },
         printElem(elem){
            $('#'+elem).printThis({
                importCSS: true,
                importStyle: true,
                loadCSS : "/custom_packages/form_reports.css"
            });
        },
    }
}
</script>

<style scoped>

</style>


