var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "container" } }, [
      _c("div", { staticClass: "cell" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.query_param,
              expression: "query_param"
            }
          ],
          attrs: { type: "search", placeholder: _vm.placeholder },
          domProps: { value: _vm.query_param },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.query_param = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _c(
          "button",
          {
            staticClass: "vx-search-button",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.search($event)
              }
            }
          },
          [_vm._v("Search")]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { attrs: { id: "example-table" } })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { margin: "10px 0px 10px 0px" } }, [
      _c("button", { staticClass: "btn", attrs: { id: "download-xlsx" } }, [
        _c("i", { staticClass: "fas fa-file-excel" }),
        _vm._v(" Download Excel")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "btn", attrs: { id: "download-pdf" } }, [
        _c("i", { staticClass: "fas fa-file-pdf" }),
        _vm._v(" Download PDF")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "btn", attrs: { id: "download-csv" } }, [
        _c("i", { staticClass: "fas fa-file-csv" }),
        _vm._v(" Download CSV")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }