var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-left": "10px" } },
    [
      _c(
        "div",
        {
          staticClass: "paper_no_shw col-md-12",
          staticStyle: { padding: "15px" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("li", { staticClass: "dropdown dropdown-large" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "button",
              {
                staticStyle: {
                  background: "transparent",
                  border: "none",
                  "border-bottom": "1px"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.refreshOptions($event)
                  }
                }
              },
              [
                _vm._v("Refresh Parameter "),
                _c("b", { staticClass: "fa fa-chevron-circle-down" })
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "dropdown-menu dropdown-menu-large row" },
              _vm._l(_vm.embark_options, function(value, index) {
                return index != "toString"
                  ? _c("li", { staticClass: "col-sm-4" }, [
                      _c(
                        "ul",
                        [
                          _c("li", { staticClass: "dropdown-header" }, [
                            _vm._v(_vm._s(index))
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.embark_options[index], function(
                            valu,
                            inde
                          ) {
                            return _c("div", [
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.options[
                                          _vm.embark_options[index][inde][
                                            "field"
                                          ]
                                        ],
                                      expression:
                                        "options[embark_options[index][inde]['field']]"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id:
                                      "html" +
                                      _vm.embark_options[index][inde]["field"]
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.options[
                                        _vm.embark_options[index][inde]["field"]
                                      ]
                                    )
                                      ? _vm._i(
                                          _vm.options[
                                            _vm.embark_options[index][inde][
                                              "field"
                                            ]
                                          ],
                                          null
                                        ) > -1
                                      : _vm.options[
                                          _vm.embark_options[index][inde][
                                            "field"
                                          ]
                                        ]
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a =
                                            _vm.options[
                                              _vm.embark_options[index][inde][
                                                "field"
                                              ]
                                            ],
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.options,
                                                _vm.embark_options[index][inde][
                                                  "field"
                                                ],
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.options,
                                                _vm.embark_options[index][inde][
                                                  "field"
                                                ],
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.options,
                                            _vm.embark_options[index][inde][
                                              "field"
                                            ],
                                            $$c
                                          )
                                        }
                                      },
                                      function($event) {
                                        return _vm.updateQuery(
                                          _vm.embark_options[index][inde][
                                            "field"
                                          ]
                                        )
                                      }
                                    ]
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for:
                                        "html" +
                                        _vm.embark_options[index][inde]["field"]
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.embark_options[index][inde][
                                          "field_name"
                                        ]
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card card-body bg-light" }, [
            _c("form", { attrs: { action: "/action_page.php" } }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.options, function(option, index) {
                  return option && _vm.check_key_exist(index)
                    ? _c("div", { staticClass: "col-md-3" }, [
                        _vm.getFieldType(index).type == "text"
                          ? _c(
                              "div",
                              { staticStyle: { "margin-bottom": "15px" } },
                              [
                                _c("label", { attrs: { for: "email" } }, [
                                  _vm._v(_vm._s(_vm.getFieldName(index)))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.query_fields[index],
                                      expression: "query_fields[index]"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    required: "",
                                    type: "text",
                                    placeholder:
                                      "Enter " + _vm.getFieldName(index),
                                    id: "email"
                                  },
                                  domProps: { value: _vm.query_fields[index] },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.query_fields,
                                        index,
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getFieldType(index).type == "select"
                          ? _c(
                              "div",
                              { staticStyle: { "margin-bottom": "15px" } },
                              [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.getFieldName(index)))
                                ]),
                                _vm._v(" "),
                                _c("vx_select", {
                                  attrs: {
                                    remote_options: true,
                                    remote_url: _vm.getFieldType(index).url,
                                    placeholder: _vm.getFieldType(index)
                                      .placeholder
                                  },
                                  model: {
                                    value: _vm.query_fields[index],
                                    callback: function($$v) {
                                      _vm.$set(_vm.query_fields, index, $$v)
                                    },
                                    expression: "query_fields[index]"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pull-right row",
                  staticStyle: { margin: "5px 12px 0px 0px" }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary save_record",
                      attrs: { disabled: _vm.enable_search, role: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.search($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-search fa-fw" }),
                      _vm._v(" Search")
                    ]
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      false
        ? _c("div", { attrs: { id: "container" } }, [
            _c("div", { staticClass: "cell" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.query_param,
                    expression: "query_param"
                  }
                ],
                attrs: { type: "search", placeholder: _vm.placeholder },
                domProps: { value: _vm.query_param },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.query_param = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cell" }, [
              _c(
                "button",
                {
                  staticClass: "vx-search-button",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.search($event)
                    }
                  }
                },
                [_vm._v("Search")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "datatable",
        _vm._b({ attrs: { id: "sample_id" } }, "datatable", _vm.$data, false),
        [
          _c("div", { staticStyle: { "margin-top": "30px" } }, [
            _c(
              "div",
              {
                staticClass: "btn-group",
                attrs: { role: "group", "aria-label": "Basic example" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-info",
                    attrs: { type: "button", disabled: !_vm.selection.length },
                    on: { click: _vm.show_embark_modal }
                  },
                  [
                    _c("i", { staticClass: "fas fa-anchor fa-fw" }),
                    _vm._v(" Embark")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-warning",
                    attrs: { type: "button", disabled: !_vm.selection.length },
                    on: { click: _vm.mass_delete }
                  },
                  [
                    _c("i", { staticClass: "fas fa-trash fa-fw" }),
                    _vm._v(" Delete")
                  ]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "embark_modal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "disEmbarkModal",
            "aria-hidden": "true"
          }
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body", attrs: { id: "slip_content" } },
                [
                  _c("p", { staticStyle: { margin: "0 !important" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.allow_bcb_status,
                          expression: "allow_bcb_status"
                        }
                      ],
                      staticClass: "vx_input_2",
                      attrs: { type: "checkbox", id: "allow_bcb_assng" },
                      domProps: {
                        checked: Array.isArray(_vm.allow_bcb_status)
                          ? _vm._i(_vm.allow_bcb_status, null) > -1
                          : _vm.allow_bcb_status
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.allow_bcb_status,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.allow_bcb_status = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.allow_bcb_status = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.allow_bcb_status = $$c
                            }
                          },
                          _vm.toggle_allow_bcb
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "allow_bcb_assng" } }, [
                      _vm._v("Enable back-to-back assignment")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm.already_embark_seafs.length > 0
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-8" }, [
                          _vm._m(3),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(_vm.already_embark_seafs, function(seaf) {
                              return _c("li", [
                                _vm._v(
                                  _vm._s(seaf.name) +
                                    "(" +
                                    _vm._s(seaf.seafarer_id) +
                                    ")"
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.restricted_seafs.length > 0
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-8" }, [
                          _vm._m(4),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(_vm.restricted_seafs, function(seaf) {
                              return _c("li", [
                                _vm._v(
                                  _vm._s(seaf.name) +
                                    "(" +
                                    _vm._s(seaf.seafarer_id) +
                                    ")"
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.embark_seafs.length > 0,
                          expression: "embark_seafs.length > 0"
                        }
                      ],
                      staticClass: "row"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("label", [_vm._v("*Select embarkation vessel")]),
                          _vm._v(" "),
                          _c("vx_select", {
                            attrs: {
                              remote_options: true,
                              remote_url: "/search/get_vessels",
                              placeholder: "Vessel"
                            },
                            model: {
                              value: _vm.embark_vessel_id,
                              callback: function($$v) {
                                _vm.embark_vessel_id = $$v
                              },
                              expression: "embark_vessel_id"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "p-2 col-md-5",
                        staticStyle: {
                          border: "1px solid teal",
                          "border-radius": "5px"
                        }
                      },
                      [
                        _c("p", { staticStyle: { margin: "0 !important" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.allow_date_toggle,
                                expression: "allow_date_toggle"
                              }
                            ],
                            staticClass: "vx_input_4",
                            attrs: {
                              type: "checkbox",
                              id: "allow_bcb_use_dte"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.allow_date_toggle)
                                ? _vm._i(_vm.allow_date_toggle, null) > -1
                                : _vm.allow_date_toggle
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.allow_date_toggle,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.allow_date_toggle = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.allow_date_toggle = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.allow_date_toggle = $$c
                                  }
                                },
                                _vm.toggle_use_dates
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "allow_bcb_use_dte" } }, [
                            _vm._v("Use Embarkation Start/End dates for all")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _c("div", { staticClass: "p-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.embark_start_date,
                                  expression: "embark_start_date"
                                }
                              ],
                              staticClass: "input false false",
                              attrs: { type: "date" },
                              domProps: { value: _vm.embark_start_date },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.embark_start_date = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("i", { staticStyle: { "margin-top": "6px" } }, [
                            _vm._v("- to -")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "p-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.embark_end_date,
                                  expression: "embark_end_date"
                                }
                              ],
                              staticClass: "input false false",
                              attrs: { type: "date" },
                              domProps: { value: _vm.embark_end_date },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.embark_end_date = $event.target.value
                                }
                              }
                            })
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "p-2" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "p-2 col-md-6",
                        staticStyle: {
                          border: "1px solid teal",
                          "margin-left": "26px",
                          "border-radius": "5px"
                        }
                      },
                      [
                        _c("p", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.allow_bcb_end,
                                expression: "allow_bcb_end"
                              }
                            ],
                            staticClass: "vx_input_4",
                            attrs: { type: "checkbox", id: "allow_bcb_end" },
                            domProps: {
                              checked: Array.isArray(_vm.allow_bcb_end)
                                ? _vm._i(_vm.allow_bcb_end, null) > -1
                                : _vm.allow_bcb_end
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.allow_bcb_end,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.allow_bcb_end = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.allow_bcb_end = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.allow_bcb_end = $$c
                                  }
                                },
                                _vm.allow_end_toggle
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "allow_bcb_end" } }, [
                            _vm._v("Use Embarkation End date for all")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _c("div", { staticClass: "p-2" }, [
                            _vm._v(
                              "\n                                  Embarkation end\n                                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "p-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.bcb_end_date,
                                  expression: "bcb_end_date"
                                }
                              ],
                              staticClass: "input false false",
                              attrs: { type: "date" },
                              domProps: { value: _vm.bcb_end_date },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.bcb_end_date = $event.target.value
                                }
                              }
                            })
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._l(_vm.embark_seafs, function(seafarer, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row disable_me_now",
                        staticStyle: {
                          "border-bottom": "1px solid whitesmoke",
                          "margin-bottom": "10px"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "p-2 col-md-5",
                            attrs: { id: seafarer.com_date_id }
                          },
                          [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: seafarer.name }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "d-flex flex-row" }, [
                              _c("div", { staticClass: "p-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: seafarer.embark_start_date,
                                      expression: "seafarer.embark_start_date"
                                    }
                                  ],
                                  staticClass: "input false false",
                                  attrs: { type: "date" },
                                  domProps: {
                                    value: seafarer.embark_start_date
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        seafarer,
                                        "embark_start_date",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "i",
                                { staticStyle: { "margin-top": "6px" } },
                                [_vm._v("- to -")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "p-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: seafarer.embark_end_date,
                                      expression: "seafarer.embark_end_date"
                                    }
                                  ],
                                  staticClass: "input false false",
                                  attrs: { type: "date" },
                                  domProps: { value: seafarer.embark_end_date },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        seafarer,
                                        "embark_end_date",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-2" }, [
                          _c("p", { staticStyle: { margin: "0 !important" } }, [
                            _c("input", {
                              staticClass: "vx_input_3",
                              attrs: { type: "checkbox", id: "test2" + index },
                              domProps: { checked: seafarer.required == false },
                              on: {
                                change: function($event) {
                                  return _vm.toggle_bcb(
                                    seafarer.com_id,
                                    seafarer.seafarer_id
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticStyle: { "font-size": "10px" },
                              attrs: { for: "test2" + index },
                              domProps: {
                                textContent: _vm._s(
                                  seafarer.required ? "required" : "skiped"
                                )
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "p-2 col-md-6",
                            attrs: { id: seafarer.com_id }
                          },
                          [
                            _c("v_select", {
                              attrs: {
                                disable_com: seafarer.required == false
                              },
                              on: {
                                input_change: function($event) {
                                  return _vm.check_id_exist(
                                    seafarer.bcb_seafarer_id,
                                    seafarer.com_id
                                  )
                                }
                              },
                              model: {
                                value: seafarer.bcb_seafarer_id,
                                callback: function($$v) {
                                  _vm.$set(seafarer, "bcb_seafarer_id", $$v)
                                },
                                expression: "seafarer.bcb_seafarer_id"
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "d-flex flex-row" }, [
                              _c("div", { staticClass: "p-2" }, [
                                _vm._v(
                                  "\n                                  Embarkation end\n                                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "p-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: seafarer.bcb_end_date,
                                      expression: "seafarer.bcb_end_date"
                                    }
                                  ],
                                  staticClass: "input false false",
                                  attrs: { type: "date" },
                                  domProps: { value: seafarer.bcb_end_date },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        seafarer,
                                        "bcb_end_date",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12",
                        staticStyle: { padding: "10px 40px 0px 40px" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-md btn-primary pull-right",
                            attrs: {
                              id: "process_embark",
                              disabled: _vm.enable_embark_process
                            },
                            on: { click: _vm.process_embark }
                          },
                          [_vm._v("Procees Embarkation")]
                        )
                      ]
                    )
                  ])
                ],
                2
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.show_loading ? _c("loader") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "entry_heading" }, [
      _c("h5", [_vm._v("Search seafarer")]),
      _vm._v(" "),
      _c("hr")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "dropdown-toggle", attrs: { href: "#" } }, [
      _vm._v("Query Parameters "),
      _c("b", { staticClass: "fa fa-chevron-circle-down" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }, [
        _vm._v("Seafarer embarkments / Back-to-back")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "alert alert-danger" }, [
      _c("i", { staticClass: "fas fa-info-circle fa-fw" }),
      _vm._v(
        " The following seaferars are currently embarked from the selection"
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "alert alert-info" }, [
      _c("i", { staticClass: "fas fa-info-circle fa-fw" }),
      _vm._v(" You need access to embark the following seafarers")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-row" }, [
      _c("div", { staticClass: "p-2 col-md-5" }, [
        _c("h4", { staticStyle: { "font-weight": "800" } }, [
          _vm._v("Seafarer Embarking "),
          _c("i", { staticClass: "fas fa-sign-in-alt" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "p-2" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-2 col-md-6", staticStyle: { "margin-left": "26px" } },
        [
          _c(
            "h4",
            { staticStyle: { "margin-left": "8%", "font-weight": "800" } },
            [
              _vm._v("Embarking seafarer's back-to-back "),
              _c("i", { staticClass: "fas fa-sign-out-alt" })
            ]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }