var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { name: "Datatable" } },
    [
      _vm.$slots.default || _vm.HeaderSettings
        ? _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-bottom": "10px" }
            },
            [
              _vm.HeaderSettings
                ? _c("header-settings", {
                    staticClass: "pull-right",
                    attrs: {
                      columns: _vm.columns,
                      "support-backup": _vm.supportBackup
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("tbl", _vm._b({}, "tbl", _vm.$props, false)),
      _vm._v(" "),
      _vm.Pagination
        ? _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("strong", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$i18nForDatatable("Total")) +
                        " " +
                        _vm._s(_vm.total) +
                        " " +
                        _vm._s(_vm.$i18nForDatatable(",")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("page-size-select", {
                    attrs: {
                      query: _vm.query,
                      "page-size-options": _vm.pageSizeOptions
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("pagination", {
                    staticClass: "pull-right",
                    attrs: { total: _vm.total, query: _vm.query }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }