<template>
    <select :multiple = "multiple_sel">
      <option></option>
      <slot>
      <option></option>

      </slot>
    </select>
</template>

<script>
  export default {
    return_value : {
      default : false
    },
    props : {
      remote_options : {
        type : Boolean,
        default : false
      },
      payslip : {
        default : false
      },
      remote_url : {
        type : String,
        default : "/admin/seafarer/get_towns"
      },
      params_obj : {
        type : Object,
        default : function(){
          return {
            state_id : 100
          }
        }
      },
      'min_res_sch':{
        type : Number,
        default : null
      },
      'options' : {
        type : Array,
        default : function(){
          return []
        }
      },
      'value' : [String,Number,Array,Object],
      'sel_value' : [String,Number,Array,Object],
      'filter_value' : [String,Number],
      'filter_field' : [String,Number],
      'multiple_sel' : {
          type : Boolean,
          default : false
        },
      'placeholder' : {
        default : 'Please select a value',
        type : String
      }
      },
  mounted: function () {
    var vm = this;

    $(this.$el)
      .select2({ 
        data: this.filtered_options,
        placeholder : this.placeholder,
        allowClear: true,
        ignoreAccents: false,
        minimumResultsForSearch: this.min_res_sch
       })
      .val(this.value)
      .trigger('change')                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
      .on('change', function () {
        vm.$emit('input', $(this).val());
        vm.$emit('select_value',$(this).val());
      });


      if(this.options.length == 0){
        if(this.remote_options){
          this.loadRemoteOptions();
        }
      }
    
  },
  watch: {
    sel_value: function (value) {
      // $(this.$el)
      // 	.val(this.value)
      // 	.trigger('change')
    },
    value: function (value) {
        if(value){
          this.clear_errors(this.$el);
        }
        if(!this.remote_options){
            if(value != null){
              if ([...value].sort().join(",") !== [...$(this.$el).val()].sort().join(","))
            $(this.$el).val(value).trigger('change');
            }
        }
    },
    options : {
      handler(){
        if(this.payslip){
        var vm = this;
        $(this.$el)
          .select2({ 
            data: this.filtered_options,
            placeholder : this.placeholder,
            allowClear: true,
            ignoreAccents: false,
            minimumResultsForSearch: this.min_res_sch
          })
          .val(this.value)
          .trigger('change')                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
          .on('change', function () {
            vm.$emit('input', $(this).val());
            vm.$emit('select_value',$(this).val());
          });
        }
      },
      deep : true
    },
    filter_value : function(f_value){ 
      var vm = this;
     if(!this.remote_options){
        var options_data = this.options.filter(rows => rows[this.filter_field]  == f_value);
        $(this.$el).empty().select2({ data: options_data,placeholder : this.placeholder,allowClear: true })
                      .val(this.value)
                      .trigger('change')                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                      .on('change', function () {
                        vm.$emit('input', $(this).val());
                        // vm.$emit('clear_errors',vm.$el);
                        // vm.clear_errors(vm.$el);
                      });
     }else{
         this.loadRemoteOptions(f_value);
     }
    }
  },
  destroyed: function () {
    $(this.$el).off().select2('destroy')
  },
  methods : {
    loadRemoteOptions(f_by = ""){
      var vm = this;
       const params = {
            filter_field : this.filter_field,
            filter_value : this.filter_value
          }
          axios.get(this.remote_url,{
            params : params
          })
          .then(response => {
            var options_data = response.data.options;
            if(f_by != ""){
              options_data = options_data.filter(rows => rows[this.filter_field]  == f_by);
            }
           $(this.$el).empty().select2({ data: options_data,placeholder : this.placeholder,allowClear: true })
                      .val(this.value)
                      .trigger('change')                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                      .on('change', function () {
                        vm.$emit('input', $(this).val());
                        // vm.$emit('clear_errors',vm.$el);
                        // vm.clear_errors(vm.$el);
                      });
          });
    },
    clear_errors(element){
      this.$emit('clear_errors',element);
    }
  },
  computed : {
    filtered_options : function(){
      if(this.filter_value){
        return this.options.filter(rows => rows[this.filter_field]  == this.filter_value);
      }
      return this.options;
    }
  }
  }
</script>

<style>
.select2-container {
    width: 100% !important
}

</style>
