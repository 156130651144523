<template>
  <div>


<input type="text" readonly="readonly" :id="uniq_id" :value="sel_value" />
 
  </div>
</template>

<script>
const uuidv1 = require('uuid/v1');
export default {
  props :{
    value : {

    }
  },
  data() {
    return {
      sel_value : "",
      uniq_id : uuidv1()

    }
  },
  mounted(){
  
const com_this = this;
  
    $('#'+this.uniq_id).daterangepicker({
      autoUpdateInput: false,
      showDropdowns : true,
      locale: {
          cancelLabel: 'Clear'
      }
  });

  $('#'+this.uniq_id).on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
      com_this.$emit('input',picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'))
      com_this.$emit('input_changed',picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'))
  
  });

  $('#'+this.uniq_id).on('cancel.daterangepicker', function(ev, picker) {
      com_this.$emit('input','')
      com_this.$emit('input_changed','')
  });
  },
  watch: {
    value : {
      handler(value){
        this.sel_value = value
      }
    }
  },
}
</script>