<template>

    <div class="container">
        <div style="margin-bottom:20px!important" v-for="(doc,index) in documents">

            <td><i style="font-size:10px!important">{{doc.date_of_issue}} - {{doc.expiry_date}}</i></td>
            <div style="" class="progress-count progress-small">
                <div class="bar" :duration="doc.duration" :period_type="doc.period_type" :elapsed_percent="get_elapsed_percentage(doc.duration)" :expiry_date="doc.expiry_date" :date_of_issue="doc.date_of_issue"></div>
            </div>

        </div>
    </div>
</template>

<script>
import alert_mixins from '../mixins/alertify.jsx';
export default {
    mixins : [alert_mixins],
    props : {
      documents : {
        required : true
      }  
    },
   data(){
        return {
          percentage_complete : 0,
          doc_ids : [],
          required : true,
          years_left : null,
          months_left : null,
          days_left : null,
          hours_left : null,
          minutes_left : null,
          seconds_left : null,
        }
      },
    methods : {
      
      updateIds(id){
        var index = this.doc_ids.indexOf(id);
        if (index > -1) {
          this.doc_ids.splice(index, 1);
        }else{
          this.doc_ids.push(id);

        }
      },
      randomize() {
        const com_this = this;
        var expiry_date_att = null;
        var date_of_issue = null;
        $('.progress-count .bar').each(function() {
            expiry_date_att = $(this).attr('expiry_date');
            date_of_issue = $(this).attr('date_of_issue');
            if(expiry_date_att){
              com_this.ticking_time({
                element : $(this),
                expiry_date : expiry_date_att,
                date_of_issue : date_of_issue
              })
            }
        });
        return {
          date_of_issue : date_of_issue,
          date_of_exipry : expiry_date_att
        }
      },
      calculate_time_to_expiry(options){
        var date_of_issued = options.date_of_issue;
        var date_of_exipry = options.date_of_exipry;
        var current_date = moment();
        var end_date = moment(date_of_exipry);

        var period_left = moment.duration(end_date.diff(current_date))

        this.years_left  = period_left.asYears();
        this.months_left = period_left.asMonths();
        this.days_left = period_left.asDays();
        this.minutes_left = period_left.asMinutes();

        console.log({
          years_left : this.years_left,
          months_left : this.months_left,
          days_left : this.days_left,
          minutes_left : this.minutes_left
        })


        // console.log({
        //     date_of_issued : date_of_issued,
        //     current_date : current_date,
        //     endDate : end_date,
        //     endDateEndOfMonth : endDateEndOfMonth,
        // })

      },
      
      cal_per_enlapsed(val){
        return val
      },
      cal_percentage(options){


        var creation_date = options.start_date;
        var completion_date = options.end_date;

        // get percentage completed (now - startDate) / (endDate - startDate)
        var now = moment();
        var startDate = moment(creation_date);
        var endDate = moment(completion_date);
        var percentage_complete = (now - startDate) / (endDate - startDate) * 100;
        var percentage_rounded = (Math.round(percentage_complete * 100) / 100); 
        // percentage rounded to 2 decimal points

        return percentage_complete;


        // set the progress bar percentages
        // var record_progress = $(this).find('.bar');
        // record_progress.css('width', percentage_rounded+'%');
        // record_progress.html(percentage_rounded+'% - Completed');
      },
      get_elapsed_percentage(duration){
        return 0
      },
    ticking_time(options){
        console.log(options);



       var $clock = options.element;
       var count_down_date = moment(options.expiry_date); 

        var eventTime = moment(count_down_date, 'DD-MM-YYYY HH:mm:ss').unix(),
            currentTime = moment().unix(),
            diffTime = eventTime - currentTime,
            duration = moment.duration(diffTime * 1000, 'milliseconds'),
            interval = 1000;


    // if time to countdown
    if(diffTime > 0) {

        // Show clock
        // $clock.show();

        var $yr = $('<td class="years" ></td>').appendTo($clock.parent().parent()),
            $mo = $('<td class="months" ></td>').appendTo($clock.parent().parent()),
            $d = $('<td class="days" ></td>').appendTo($clock.parent().parent()),
            $h = $('<td class="hours" ></td>').appendTo($clock.parent().parent()),
            $m = $('<td class="minutes" ></td>').appendTo($clock.parent().parent()),
            $s = $('<td class="seconds" ></td>').appendTo($clock.parent().parent());
            
        const com_this = this;

        setInterval(function(){

          var computed_percent =  com_this.cal_percentage({
              start_date : options.date_of_issue,
              end_date : options.expiry_date
            });

            $clock.attr('style', 'width:' + computed_percent + '%;background-position:0 ' + computed_percent + '% !important');
          

            duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');
            var yr = moment.duration(duration).years(),
                mo = moment.duration(duration).months(),
                d = moment.duration(duration).days(),
                h = moment.duration(duration).hours(),
                m = moment.duration(duration).minutes(),
                s = moment.duration(duration).seconds();

                com_this.years_left = yr;
                com_this.months_left = mo;
                com_this.days_left = d;
                com_this.hours_left = h;
                com_this.minutes_left = m;
                com_this.seconds_left = s;

            yr = $.trim(yr).length === 1 ? '0' + yr : yr;
            mo = $.trim(mo).length === 1 ? '0' + mo : mo;
            d = $.trim(d).length === 1 ? '0' + d : d;
            h = $.trim(h).length === 1 ? '0' + h : h;
            m = $.trim(m).length === 1 ? '0' + m : m;
            s = $.trim(s).length === 1 ? '0' + s : s;

            // show how many hours, minutes and seconds are left
            if(yr > 0){
              $yr.text(yr + "  YEARS |");
            }
            if(mo > 0){
              $mo.text(mo + "  MONTHS |");
            }
            if(d > 0){
              $d.text(d + "  DAYS |");
            }
            if(h > 0){
              $h.text(h + "  HOURS |");
            }
            if(m > 0){
              $m.text(m + " min : ");
            }
            if(s > 0){
              $s.text(s + " s");
            }

        }, interval);

    }

      }
    },
    computed : {

    },
    mounted(){
      window.save_btn = Ladda.create( document.getElementById('send_notification'));
        const com_this = this;
        const date_params = com_this.randomize();

        this.calculate_time_to_expiry(date_params);


    }
}
</script>


<style scoped>

.button-group-pills .btn {
  border-radius: 6px;
  margin-bottom: 15px;
  margin-left: 10px;
  border-color: #bbbbbb;
  background-color: #FFF;
  color: #14a4be;
  padding:7px 5px 0px 5px !important
}
.button-group-pills .btn.active {
  border-color: #14a4be;
  background-color: #14a4be;
  color: #FFF;
  box-shadow: none;
}
.button-group-pills .btn:hover {
  border-color: #158b9f;
  background-color: #158b9f;
  color: #FFF;
}
.ladda-button {
  padding: 0 !important;
      font-size: 14px !important;
      background: #00bca4 !important;
      color: #fff !important;
}


</style>

