var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticStyle: { margin: "10px 0px 10px 0px" } }, [
      _c("button", { staticClass: "btn", attrs: { id: "download-xlsx" } }, [
        _c("i", { staticClass: "fas fa-file-excel" }),
        _vm._v(" Download Excel")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "btn", attrs: { id: "download-pdf" } }, [
        _c("i", { staticClass: "fas fa-file-pdf" }),
        _vm._v(" Download PDF")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "btn", attrs: { id: "download-csv" } }, [
        _c("i", { staticClass: "fas fa-file-csv" }),
        _vm._v(" Download CSV")
      ])
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      {
        staticClass: "table table-condensed",
        staticStyle: { "border-collapse": "collapse" },
        attrs: { id: "vx_table" }
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("#")]),
            _vm._v(" "),
            _c("th", [_vm._v("Date")]),
            _vm._v(" "),
            _c("th", [_vm._v("Description")]),
            _vm._v(" "),
            _c("th", [_vm._v("Credit")]),
            _vm._v(" "),
            _c("th", [_vm._v("Debit")]),
            _vm._v(" "),
            _c("th", [_vm._v("Balance")])
          ])
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c(
            "tr",
            {
              staticClass: "accordion-toggle",
              attrs: { "data-toggle": "collapse", "data-target": "#demo1" }
            },
            [
              _c("td", [_vm._v("1")]),
              _vm._v(" "),
              _c("td", [_vm._v("05 May 2013")]),
              _vm._v(" "),
              _c("td", [_vm._v("Credit Account")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$150.00")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-error" }),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$150.00")])
            ]
          ),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "hiddenRow", attrs: { colspan: "6" } }, [
              _c(
                "div",
                {
                  staticClass: "accordian-body collapse",
                  attrs: { id: "demo1" }
                },
                [
                  _c("table", [
                    _c("thead", [
                      _c("th", [_vm._v("item 1")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("item 2")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("item 3")])
                    ])
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tr",
            {
              staticClass: "accordion-toggle",
              attrs: { "data-toggle": "collapse", "data-target": "#demo2" }
            },
            [
              _c("td", [_vm._v("2")]),
              _vm._v(" "),
              _c("td", [_vm._v("05 May 2013")]),
              _vm._v(" "),
              _c("td", [_vm._v("Credit Account")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$11.00")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-error" }),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$161.00")])
            ]
          ),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "hiddenRow", attrs: { colspan: "6" } }, [
              _c(
                "div",
                {
                  staticClass: "accordian-body collapse",
                  attrs: { id: "demo2" }
                },
                [_vm._v("Demo Content2")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tr",
            {
              staticClass: "accordion-toggle",
              attrs: { "data-toggle": "collapse", "data-target": "#demo3" }
            },
            [
              _c("td", [_vm._v("3")]),
              _vm._v(" "),
              _c("td", [_vm._v("05 May 2013")]),
              _vm._v(" "),
              _c("td", [_vm._v("Credit Account")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$500.00")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-error" }),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$661.00")])
            ]
          ),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "hiddenRow", attrs: { colspan: "6" } }, [
              _c(
                "div",
                {
                  staticClass: "accordian-body collapse",
                  attrs: { id: "demo3" }
                },
                [_vm._v("Demo Content3")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tr",
            {
              staticClass: "accordion-toggle",
              attrs: { "data-toggle": "collapse", "data-target": "#demo4" }
            },
            [
              _c("td", [_vm._v("4")]),
              _vm._v(" "),
              _c("td", [_vm._v("05 May 2013")]),
              _vm._v(" "),
              _c("td", [_vm._v("Credit Account")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$500.00")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-error" }),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$661.00")])
            ]
          ),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "hiddenRow", attrs: { colspan: "6" } }, [
              _c(
                "div",
                {
                  staticClass: "accordian-body collapse",
                  attrs: { id: "demo4" }
                },
                [_vm._v("Demo Content4")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tr",
            {
              staticClass: "accordion-toggle",
              attrs: { "data-toggle": "collapse", "data-target": "#demo5" }
            },
            [
              _c("td", [_vm._v("5")]),
              _vm._v(" "),
              _c("td", [_vm._v("05 May 2013")]),
              _vm._v(" "),
              _c("td", [_vm._v("Credit Account")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$500.00")]),
              _vm._v(" "),
              _c("td", { staticClass: "text-error" }),
              _vm._v(" "),
              _c("td", { staticClass: "text-success" }, [_vm._v("$661.00")])
            ]
          ),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "hiddenRow", attrs: { colspan: "6" } }, [
              _c(
                "div",
                {
                  staticClass: "accordian-body collapse",
                  attrs: { id: "demo5" }
                },
                [_vm._v("Demo Content5")]
              )
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }