var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tfoot",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.data.length,
          expression: "data.length"
        }
      ]
    },
    [
      _c(
        "tr",
        { staticClass: "-summary-row" },
        [
          _vm.shouldRenderSelection ? _c("td") : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.columns, function(col, idx) {
            return [
              typeof _vm.summary[col.field] !== "undefined"
                ? _c(
                    "td",
                    { class: col.tdClass, style: col.tdStyle },
                    [
                      col.tdComp
                        ? _c(
                            _vm.forDynCompIs(col.tdComp),
                            _vm._b(
                              {
                                tag: "component",
                                attrs: {
                                  row: _vm.summary,
                                  field: col.field,
                                  value: _vm.summary[col.field]
                                }
                              },
                              "component",
                              _vm.$props,
                              false
                            )
                          )
                        : [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.summary[col.field]) +
                                "\n        "
                            )
                          ]
                    ],
                    2
                  )
                : _c("td")
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }