var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-md-12", staticStyle: { padding: "0px !important" } },
    [
      _vm.is_super_admin
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-md-4",
                staticStyle: { "margin-bottom": "5px" }
              },
              [
                _c("label", [_vm._v("Filter by User/Author")]),
                _vm._v(" "),
                _c("vx_select", {
                  attrs: {
                    remote_options: true,
                    remote_url: "/search/get_users",
                    placeholder: "User"
                  },
                  model: {
                    value: _vm.user_id,
                    callback: function($$v) {
                      _vm.user_id = $$v
                    },
                    expression: "user_id"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "paper_no_shw ",
          staticStyle: { "margin-bottom": "15px" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-2 col-xs-12 col-sm-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                  _vm._v("Seafarer ID")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.seafarer_id,
                      expression: "seafarer_id"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.seafarer_id },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.seafarer_id = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3 col-xs-12 col-sm-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                  _vm._v("Name")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.seafarer_name,
                      expression: "seafarer_name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "(Lastname Firstname Othername)",
                    type: "text"
                  },
                  domProps: { value: _vm.seafarer_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.seafarer_name = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3" },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                      _vm._v("Company")
                    ]),
                    _vm._v(" "),
                    _c("vx_select", {
                      attrs: {
                        remote_options: true,
                        remote_url: "/search/get_companies",
                        placeholder: "Companies"
                      },
                      model: {
                        value: _vm.company_id,
                        callback: function($$v) {
                          _vm.company_id = $$v
                        },
                        expression: "company_id"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3" },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                      _vm._v("Vessel")
                    ]),
                    _vm._v(" "),
                    _c("vx_select", {
                      attrs: {
                        filter_value: _vm.company_id,
                        filter_field: "company_id",
                        remote_options: true,
                        remote_url: "/search/get_vessels",
                        placeholder: "Vessel"
                      },
                      model: {
                        value: _vm.vessel_id,
                        callback: function($$v) {
                          _vm.vessel_id = $$v
                        },
                        expression: "vessel_id"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-md-1",
                staticStyle: { "margin-top": "15px", padding: "0 !important" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    staticStyle: { "min-width": "5px !important" },
                    on: { click: _vm.search }
                  },
                  [_vm._v("Search")]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-left": "310px" } }, [
          _c("p", { staticStyle: { margin: "0 !important" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.export_all,
                  expression: "export_all"
                }
              ],
              staticClass: "vx_input_2",
              attrs: { type: "checkbox", id: "allow_all" },
              domProps: {
                checked: Array.isArray(_vm.export_all)
                  ? _vm._i(_vm.export_all, null) > -1
                  : _vm.export_all
              },
              on: {
                change: function($event) {
                  var $$a = _vm.export_all,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.export_all = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.export_all = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.export_all = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _vm._m(3)
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm.show_loading ? _c("loader") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-wrap" }, [
      _c(
        "button",
        {
          staticClass: "rad-button small light gradient",
          attrs: { id: "download-csv" }
        },
        [
          _c("i", { staticClass: "fas fa-file-excel fa-lg" }),
          _vm._v(" Export EXCEL")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-wrap" }, [
      _c(
        "button",
        {
          staticClass: "rad-button small light gradient",
          attrs: { id: "download-pdf" }
        },
        [
          _c("i", { staticClass: "fas fa-file-excel fa-file-pdf" }),
          _vm._v(" Export PDF")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-wrap" }, [
      _c(
        "button",
        {
          staticClass: "rad-button small light gradient",
          attrs: { id: "download-json" }
        },
        [
          _c("i", { staticClass: "fas fa-file-csv fa-lg" }),
          _vm._v(" Export JSON")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "allow_all" } }, [
      _c("b", [_vm._v("Include All Records")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "project box col-md-12" }, [
      _c("div", { attrs: { id: "example-table" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }