<template>
    
</template>


<script>
import tnl_frm_1 from './tnl_frm_1.vue';
import vx_progress from '../../utils/vx_progress.vue';
import count_down from '../../utils/count_down.vue';
import vx_tabulator from '../../utils/vx_tabulator.vue';
import vx_tabs from '../../utils/tabs.vue';
import request_approve from '../../utils/request_approve.vue';
import vx_tab from '../../utils/tab.vue';
import sea_embarkation_history from '../../utils/sea_embarkation_history.vue';
import alert_mixins from '../../mixins/alertify.jsx';
import form_mixins from '../../mixins/form_mix.jsx';
import record_access_request from '../../mixins/record_access.jsx';
import vx_folder_tree from '../../utils/folder_tree.vue';
import v_select from '../../utils/v_select.vue';
import vx_select from '../../utils/vx_select.vue';
import comment_box from '../../utils/comment_box.vue';
import multi_select from 'vue-multiselect'
import FormData from '../../../scripts/form_data.js';



export default {
    object_name : "seafarer_embarkment",
    components : {sea_embarkation_history,count_down,tnl_frm_1,v_select,request_approve,vx_tabulator,vx_progress,comment_box,vx_tabs,vx_tab,multi_select,vx_folder_tree,vx_select},
    mixins : [alert_mixins,form_mixins,record_access_request],
    form_ids : ['sea_embar_form_id'],
    props : {
      current_embark_vessel :{

      },
      back_to_back_info : {

      },
      has_access : {
        default : false
      },
      approved_msg : {
        default : ""
      },
      flagged_docs : {

      },
      record_access : {
        
      },
      seaferar : {
        required : true,
        type : Object
      }
    },
    data(){
        return {
            bcb_seafarer_id : null,
            formData : null,
            show_sea_cv : false,
            show_sea_doc : true,
            assigned_company : "",
            show_approval_msg : false,
            show_embarkation_history : false,
            dynamic_sections : [],
            bcb_seafarer_end_date : "",
            company_assin : null,
            value: [],
            current_tab_id : '',
            group_sections : [],
            options: [],
            disembark_comment : null,
            vessel_info : null,
            embark_columns : [
                {title:"Vessel", field:"vessel_name", width:200},
                {title:"Name", field:"seaf_name",width:150},
                {title:"Rank", field:"seaf_position",width:150},
                {title:"Gender", field:"seaf_gender"},
                {title:"Phone", field:"seaf_mob", align:"center"},
                {title:"Email", field:"seaf_email", align:"center"},
                {title:"Start Date", field:"start_date"},
                {title:"End Date", field:"end_date"},
            ]
        }
    },
    methods : {
      check_bcb_date (){
          var result = true;
          if(moment(this.bcb_seafarer_end_date).diff(moment(this.seafarer_embarkment.embarkment_end)) <= 0){
            if(!$("#back_to_back_date").hasClass('vx-input-error')){
              $("#back_to_back_date").addClass('vx-input-error');
              result = false;
            }
            if($("#back_to_back_date").find('.vx-field-error').length == 0){
              var txt1 = $("<p class='vx-field-error'></p>").text("Back-to-back disembarkation date less/equal seafarer's disembarkation date"); 
              $("#back_to_back_date").append(txt1);
              result = false;
            }
            if($("#back_to_back_date").find('.vx-field-error').length > 0){
              $("#back_to_back_date").find('.vx-field-error').text("Back-to-back disembarkation date less/equal seafarer's disembarkation date")
              result = false;
            }
        }else{
            $("#back_to_back_date").removeClass('vx-input-error');
            $("#back_to_back_date").find('.vx-field-error').remove();
            result = true
        }
        return result;
      },
        requestAccess(){
          this.sendRequestAccess({
            request_access_btn : 'request_access_btn',
            req_acces_url : "/seafarer/request_access",
            record_id : this.seaferar.id
          })
        },
        beginEmbark(edit = false){
          if(edit == true){
            this.vessel_info = {};
          }
          $('#basicModal').modal('show');
        },
        disEmbarkShow(){
          $('#disEmbarkModal').modal('show');
        },
        approve_rem_reg(){   
             this.company_assin = {
               company_id : this.assigned_company
             };
        },
        disEmbark(){
          save_record_disembark.start();
          axios.post("/seafarer_embarkment/disembark",{
            seafarer_id : this.seaferar.id,
            comment: this.disembark_comment
          }).then(response => {
            console.log(response.data.status)
                        if(response.data.status == "SUCCESS"){
                            save_record_disembark.stop();
                            this.record_id = response.data.record_id;
                            this.alert({
                              alert_type : "success",
                              title : "Seafarer Disembarkation",
                              alert_msg : "success",
                              msg : response.data.msg
                            }).set({onclose:function(){
                               window.location.reload();
                               }})
                        }
                    })
                    .catch(data => {
                        if(data.data.status == "FAIL"){
                            this.alert({
                              alert_type : "error",
                              title : "Seafarer Disembarkation Failed",
                              alert_msg : "Disembarkation failed",
                              msg : data.data.msg
                            })
                        }
                        save_record_disembark.stop();
                    });
        },
        update_vessel_info(value){
          axios.get('/seafarer_embarkment/vessel_info',{
          params : {
            vessel_id : value
          }
        })
        .then(res => {
          this.vessel_info = res.data.data
        })

        },
        group_selection(){
          var titles = this.value.map(({name}) => name).sort();
          var new_group_name = titles.join("-").toLowerCase().replace(/ /g, '_');

          var new_group = this.value.forEach(sec => {
            sec.group_name = new_group_name
          }); 
          this.group_sections.push(this.groupByfunction(this.value, 'group_name'))
          this.value = [];
        },
        refresh_selection(){
          this.group_sections = [];
          this.value = [];
        },
        getTitleName(name){
          return name.replace(/_/g,' ').split('-').join("__").toUpperCase();
        },
        viewSeafarerCV(){
            this.show_sea_cv = true;
            this.show_sea_doc = false;
            this.show_embarkation_history = false;
        },
        viewSeafarerDoc(){
            this.show_sea_cv = false;
            this.show_sea_doc = true;
            this.show_embarkation_history = false;
        },
        check_id_exist(seafarer_id,evnt){
          var status = false;
          axios.get("/seafarer_embarkment/check_embark_status",{
            params : {
              seafarer_id : seafarer_id
            }
          }).then(res => {
            if(res.data.status == "SUCCESS"){
              status = res.data.embark_status;
              if(status == false){

            $("#"+evnt).removeClass('vx-input-error');
            $("#"+evnt).find('p.vx-field-error').remove();
          }
          
          if(status == true){

              if($("#"+evnt).hasClass('vx-input-error')){

              }else{
                $("#"+evnt).addClass('vx-input-error');
                var txt1 = $("<p class='vx-field-error'></p>").text("Seafarer already assigned"); 
                $("#"+evnt).append(txt1);
              }
          }
            }
          }).catch(res => {
            alert(res)
          })
          
          
     
        },
        viewEmbarkHistory(){
          this.show_embarkation_history = true;
          this.show_sea_cv = false;
          this.show_sea_doc = false;
        },
        alertInfoApproval(){
          if(this.approved_msg != ""){
            this.show_approval_msg =  true
            setTimeout(() => {
              this.show_approval_msg = false
            }, 9000);
          }
        },
        saveEmbark(id){
          console.log(id);
           if(this.bcb_seafarer_id != null && this.bcb_seafarer_end_date != ""){
              var result = this.check_bcb_date();
              if(result == false){
                return null
              }
           }
                this.seafarer_embarkment["seafarer_id"] = this.seaferar.id;
                const com_this = this;
                var req_url = '/seafarer_embarkment/process_embarkment'
                if(id > 0){
                  req_url = '/seafarer_embarkment/update_embarkment'
                  this.seafarer_embarkment.id = id;
                }
                this.seafarer_embarkment.bcb_seafarer_id = this.bcb_seafarer_id
                this.seafarer_embarkment.bcb_seafarer_end_date = this.bcb_seafarer_end_date

                this.formData = new FormData({
                    data : this.seafarer_embarkment,
                    alertify : true
                })
                this.formData.post(req_url)
                    .then(response => {
                        if(response.status == "SUCCESS"){
                            save_record_embark.stop();
                            this.record_id = response.data.record_id;
                            this.alert({
                              alert_type : "success",
                              title : "Seafarer Embarkment",
                              alert_msg : "success",
                              msg : response.msg
                            }).set({onclose:function(){
                               window.location.reload();
                               }})


                            document.getElementById('id2').addEventListener('click', function () {
                               window.location.replace("/seafarer/view/"+com_this.record_id);
                            });
                        }
                    })
                    .catch(data => {
                        if(data.data.status == "FAIL"){
                            this.alert({
                              alert_type : "error",
                              title : "Seafarer Embarkation Failed",
                              alert_msg : "Embarkation failed",
                              msg : data.data.msg
                            })
                        }
                        save_record_embark.stop();
                    });
        },
        printElem(elem)
        {
          if(!elem){
            this.alert({
              alert_type : "error",
              title : "Document Printing",
              alert_msg : "Select a sheet to print",
              msg : "Sheet not selected"
            })

            return null;
          }

          printJS({
              printable: elem,
              type : 'html',
              css : [
               "/custom_packages/bootstrap.css",
                "/custom_packages/global_styles.css",
                "/custom_packages/form_reports.css",
              ]
            })

          // var restrict = $('#'+elem).find("[data-restrict=true]")
          // console.log(restrict);
          // if(restrict.attr("data-restrict") === "true"){
          //   this. toast({
          //     alert_type : "error",
          //     msg : "Can not print restricted content |"+restrict.attr("title")+"|",
          //     delay : 10,
          //   })

          // }else{

          //   printJS({
          //     printable: elem,
          //     type : 'html',
          //     css : [
          //      "/custom_packages/bootstrap.css",
          //       "/custom_packages/global_styles.css",
          //       "/custom_packages/form_reports.css",
          //     ]
          //   })

          //   //  window.print_doc = Ladda.create( document.getElementById('print_doc'));
          //   //  print_doc.start()
          //   // $('#'+elem).printThis({
              
          //   //   importCSS: true,
          //   //   importStyle: true,
          //   //   loadCSS : [
          //   //     "/custom_packages/bootstrap.css",
          //   //     "/custom_packages/global_styles.css",
          //   //     "/custom_packages/form_reports.css",
          //   //   ],
          //   //   afterPrint : function(){
          //   //     print_doc.stop()
          //   //   }
          //   // })
            
          // }
        },
        update_print_id(id){
          this.current_tab_id = id;
          console.log("received" + id);
        },
        getSelectedValues(){
            var selectedVal = $("#multiselect").val();
            for (var i = 0; i < selectedVal.length; i++) {
                function innerFunc(i) {
                setTimeout(function () {
                    location.href = selectedVal[i];
                }, i * 2000);
                }
                innerFunc(i);
            }
        },
        groupByfunction(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        gen_group_name(name){
          return name.toLowerCase().replace(/ /g,'_');
        },
            addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
            }
        
    },
    mounted(){
      if(this.current_embark_vessel){
        this.vessel_info = this.current_embark_vessel
      }
      this.alertInfoApproval();
      $('[data-toggle="popover"]').popover({
        trigger: 'focus'
      });
      console.log(this.$store.state.current_user);
      window.save_record_embark = Ladda.create( document.getElementById('save_record_embark'));
      window.save_record_disembark = Ladda.create( document.getElementById('save_record_disembark'));
      
        var divs = document.getElementsByClassName("contents");
        for (let index = 0; index < divs.length; index++) {
            this.options.push({
                div : divs[index],
                title : divs[index].getAttribute('title'),
                name : divs[index].getAttribute('title'),
                code : this.gen_group_name(divs[index].getAttribute('title')),
                group_name : this.gen_group_name(divs[index].getAttribute('title'))
            })   
        }
        this.options = this.options.filter((thing,index) => {
          return index === this.options.findIndex(obj => {
            return JSON.stringify(obj) === JSON.stringify(thing);
          });
        });
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

.new
{
    width:100%; 
    border:1px solid; 
    margin:10px; 
}


.dropdown-menu {
  border-radius: 0;
}
.multiselect-native-select {
  position: relative;
}
.multiselect-native-select select {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px -1px -1px -3px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  left: 50%;
  top: 30px;
}
.multiselect-container {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.multiselect-container .input-group {
  margin: 5px;
}
.multiselect-container li {
  padding: 0;
}
.multiselect-container li .multiselect-all label {
  font-weight: 700;
}
.multiselect-container li a {
  padding: 0;
}
.multiselect-container li a label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
  padding: 3px 20px 3px 40px;
}
.multiselect-container li a label input[type=checkbox] {
  margin-bottom: 5px;
}
.multiselect-container li a label.radio {
  margin: 0;
}
.multiselect-container li a label.checkbox {
  margin: 0;
}
.multiselect-container li.multiselect-group label {
  margin: 0;
  padding: 3px 20px 3px 20px;
  height: 100%;
  font-weight: 700;
}
.multiselect-container li.multiselect-group-clickable label {
  cursor: pointer;
}
.btn-group .btn-group .multiselect.btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.form-inline .multiselect-container label.checkbox {
  padding: 3px 20px 3px 40px;
}
.form-inline .multiselect-container label.radio {
  padding: 3px 20px 3px 40px;
}
.form-inline .multiselect-container li a label.checkbox input[type=checkbox] {
  margin-left: -20px;
  margin-right: 0;
}
.form-inline .multiselect-container li a label.radio input[type=radio] {
  margin-left: -20px;
  margin-right: 0;
}
.btn {
  border-radius: 0;
  padding: 10px 0;
}
.btn-primary {
  background-color: #ff0000;
  border: none;
  border-radius: 0;
  padding: 11px 15px;
  text-transform: uppercase;
}
.vx-form-group{
  margin : 0px 0px 22px 0px !important;
}   
.vx-form-group input{
  font-size : 12px !important
} 
.vx-bold-text1  {
  text-transform: uppercase !important;
  color: #191970 !important;
}   
.vx-bold-text2  {
  text-transform: uppercase !important;
  color : #A61414 !important;
}   
  .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
        color: inherit
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
 

 
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
    }
    
    .btn-primary:hover {
        background-color: #566f7b;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
</style>


