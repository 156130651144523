<template>
  <div class="-td-color-comp" :style="{ 'background-color': gen_color }">
    {{ the_value }}
  </div>
</template>
<script>
export default {
  props: ['value'],
  data(){
    return {
      the_value : ''
    }
  },
  mounted(){
    if(this.value.toUpperCase() === 'SAVE FOR LATER'){
        this.the_value = "LATER";
        return "#ff7f50"
      }
      if(this.value == "SAVE RECORD" || this.value == "COMPLETE"){
        this.the_value = "COMPLETE";
        return "#3cb371"
      }
      if(this.value == "REQUEST APPROVAL"){
        this.the_value = "APPROVAL";
        return "#b0e0e6"
      }
      if(this.value == "SAVE FOR LATER PORTAL"){
        this.the_value = "REMOTE";
        return "#b0e0e6"
      }
      if(this.value == "EMBARKED"){
        this.the_value = "EMBARKED";
        return "#ACD1E9"
      }
  },
  computed : {
    gen_color(){
      if(this.value.toUpperCase() === 'SAVE FOR LATER'){
        this.the_value = "SAVE LATER";
        return "#ff7f50"
      }
      if(this.value == "SAVE RECORD" || this.value == "COMPLETE"){
        this.the_value = "COMPLETE";
        return "#3cb371"
      }
      if(this.value == "REQUEST APPROVAL"){
        this.the_value = "APPROVAL";
        return "#b0e0e6"
      }
      if(this.value == "SAVE FOR LATER PORTAL"){
        this.the_value = "REMOTE APPROVAL";
        return "#b0e0e6"
      }
      if(this.value == "EMBARKED"){
        this.the_value = "EMBARKED";
        return "#ACD1E9"
      }
    }
  }
}
</script>
<style>
.-td-color-comp {
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  background-color: rgb(255, 127, 80);
  font-size: 10px;
  border-radius: 2px;
}
</style>
