var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "thead",
    [
      _c(
        "transition-group",
        { attrs: { name: "fade", tag: "tr" } },
        [
          _vm.shouldRenderSelection
            ? _c(
                "th",
                { key: "--th-multi" },
                [
                  _c("multi-select", {
                    attrs: { selection: _vm.selection, rows: _vm.data }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.columns, function(col, idx) {
            return _c(
              "th",
              {
                key: col.title || col.field || idx,
                class: col.thClass,
                style: col.thStyle
              },
              [
                col.thComp
                  ? _c(
                      _vm.forDynCompIs(col.thComp),
                      _vm._b(
                        {
                          tag: "component",
                          attrs: {
                            column: col,
                            field: col.field,
                            title: col.title
                          }
                        },
                        "component",
                        _vm.$props,
                        false
                      )
                    )
                  : [_vm._v("\n        " + _vm._s(col.title) + "\n      ")],
                _vm._v(" "),
                col.explain
                  ? _c("i", {
                      staticClass: "fa fa-info-circle",
                      staticStyle: { cursor: "help" },
                      attrs: { title: col.explain }
                    })
                  : _vm._e(),
                _vm._v(" "),
                col.sortable
                  ? _c("head-sort", {
                      attrs: { field: col.field, query: _vm.query }
                    })
                  : _vm._e()
              ],
              2
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }