<template>
    
</template>

<script>
import registration from '../seafarer_reg/registration.vue'
import tnl_frm_1_entry_portal from '../seafarer_reg/tnl_frm_1_entry_portal.vue'


export default {
    components : {registration,tnl_frm_1_entry_portal},
    props : ["expiry_date"],
    methods : {
        show_instruction(){
            $("#basicModalInstruction").modal('show');
        }
    },
    mounted(){
      const com_this = this;
        $('#jcountdown').countdown(moment(com_this.expiry_date).format("MM/DD/YYYY hh:mm:ss"), function(event) {
          var $this = $(this).html(event.strftime(''
            + '<span>%-D</span> days '
            + '<span>%H</span> hr '
            + '<span>%M</span> min '
            + '<span>%S</span> sec'));
        }).on('finish.countdown',function(){
           window.location.reload(); 
        });



var index = 0;
var maxIndex = 4;

function updateBtnState() {
  if (index == 0) {
    $('.prever').hide();
  } else {
    $('.prever').show();
  }
  if (index == maxIndex) {
    $('.nexter').hide();
  } else {
    $('.nexter').show();
  }
}
$('.nexter').on('click', function(e) {
  index++;
  if (index > maxIndex) {
    e.preverentDefault();
    return;
  }
  updateBtnState();
  var slidecontentWrap = $(this).parent().find('.slidecontentWrap'),
    slidecontentPercent = (-index * 100) + '%';
  slidecontentWrap.animate({
    marginLeft: slidecontentPercent
  }, 300);
});
$('.prever').on('click', function(e) {
  index--;
  if (index < 0) {
    e.preverentDefault();
    return;
  }
  updateBtnState();
  var slidecontentWrap = $(this).parent().find('.slidecontentWrap'),
    slidecontentPercent = (-index * 100) + '%';
  slidecontentWrap.animate({
    marginLeft: slidecontentPercent
  }, 300);
});
updateBtnState();
    }
}
</script>

<style scoped>

.countdown {
    position: relative;
    color: lightblue;
    /* font-size: 16px; */
    font-family: arial;
    display: block;
    /* width: 400px; */
    margin: auto;
    text-align: center;
    background: rgba(243, 95, 95, 0.7);
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}
#jcountdown > span {font-size:36px; margin-left:10px; color:white;}

body {
  background: #bdc3c7 none repeat scroll top left;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  position: relative;
  line-height: normal;
}

.separator {
  overflow: hidden;
  max-height: 240px;
  margin: 0 0 20px 0;
}

.separator img {
  background: #eee;
  max-width: 100%;
  vertical-align: middle;
  background-position: middle;
  border: 0;
  height: auto;
  width: 100%;
}

.next-wrap img,
.next-wrap video,
.next-wrap object {
  max-width: 100%
}

.next-wrap {
  position: relative;
  max-width: 728px;
  padding: 10px 20px 60px 20px;
  background: #fff;
  color: #666;
  font-size: 1em;
  line-height: normal;
  border-radius: 10px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
}

.next-wrap h1,
.next-wrap h2 {
  display: block;
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 700;
  color: red;
}

.next-wrap h2 {
  margin-top: 30px;
}

.instruction {
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.slidecontentWrap {
  width: 1000%;
}

.slidecontent {
  float: left;
  width: 10%;
  font-size: 16px;
  line-height: 161%;
  overflow: hidden;
}

.movepg {
  display: none;
  position: absolute;
  bottom: 20px;
  background: #e74c3c;
  color: #fff !important;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 80%;
  outline: none;
  border-radius: 3px;
  transition: background .6s;
}

.movepg:hover {
  background: #c0392b;
}

.movepg.prever {
  left: 20px;
}

.movepg.nexter {
  right: 20px;
}

</style>


