var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-container" }, [
    _c("div", { staticClass: "text-left" }, [
      _c("div", { staticClass: "well vx-well" }, [
        _c("h4", [_vm._v("Notes")]),
        _vm._v(" "),
        _vm.view_only
          ? _c("div", { staticClass: "input-group" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.comment_text,
                    expression: "comment_text"
                  }
                ],
                attrs: {
                  name: "",
                  id: "comment_box_id",
                  cols: "30",
                  rows: "2"
                },
                domProps: { value: _vm.comment_text },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.comment_text = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "input-group-btn",
                attrs: { onclick: "addComment()" }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("hr", { attrs: { "data-brackets-id": "12673" } }),
        _vm._v(" "),
        _vm.comments.length == 0
          ? _c("p", [_vm._v("No comments yet")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "list-unstyled ui-sortable",
            attrs: { "data-brackets-id": "12674", id: "sortable" }
          },
          _vm._l(_vm.comments, function(comment) {
            return _c("div", [
              _c("strong", { staticClass: "pull-left primary-font" }, [
                _vm._v(_vm._s(comment.user_email))
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("li", { staticClass: "ui-state-default" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(comment.comment) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "pull-right text-muted" }, [
                _c("span", { staticClass: "glyphicon glyphicon-time" }),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.time_from_now(comment.comment_date)) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("br")
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }