<template>
    <div class="fixed_height_pr_tb" v-show="excel_rows.length > 0">
        <div class="fixed_content_pr_tb">
            <h3>Sheet Details</h3>
            <div id="example"></div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
            excel_rows : {

            }
    },
    data(){
        return {
            data : [
                ['', 'Tesla', 'Volvo', 'Toyota', 'Ford'],
                ['2019', 10, 11, 12, 13],
                ['2020', 20, 11, 14, 13],
                ['2021', 30, 15, 12, 13]
            ]
        }
    },
    mounted(){
        const container = document.getElementById('example');
        const hot = new Handsontable(container, {
            data: this.excel_rows,
            rowHeaders: true,
            colHeaders: true
        });
    },
    watch: {
        excel_rows : {
            handler(){
                const com_this = this;
                const container = document.getElementById('example');
                const hot = new Handsontable(container, {
                    data: this.excel_rows,
                    rowHeaders: true,
                    licenseKey: 'non-commercial-and-evaluation',
                    colHeaders: true,
                    allowRemoveRow: true,
                    autoColumnSize : true
                });
                hot.addHook('afterSelectionEnd', function(r, c, r2, c2) {
                    console.log('coordinates: ', r, c, r2, c2);

                    for (var i = r; i < (r2 + 1); i++) {
                    if (!(r === 0 && r2 === (hot.countRows() - 1)) && c === 0) {
                        console.log(hot.getDataAtRow(i));
                        com_this.$emit("selected_row",hot.getDataAtRow(i));
                    }
                    }
                })
            },
            deep: true
        }
    },

}
</script>

<style scoped>
.fixed_height_pr_tb
{
  height: 750px;
  width:100%; 
  padding:3px; 
}
.fixed_content_pr_tb
{
  height:700px;
  overflow:auto;
}
</style>