<template>
    <div>
        <div class="vx_div_container" :id="'container'+unique_id">
            <div class='cell'>
                <input type='search' v-model="query_param" :placeholder='placeholder'>
            </div>
            <div class='cell'>
                <button @click.prevent="search" class='vx-search-button'>Search</button>
            </div>
        </div>
        <div class="" style="margin:10px 0px 10px 0px">
            <button class="btn" :id="'download-xlsx'+unique_id"><i class="fas fa-file-excel"></i> Download Excel</button>
            <button class="btn" :id="'download-pdf'+unique_id"><i class="fas fa-file-pdf"></i> Download PDF</button>
            <button class="btn" :id="'download-csv'+unique_id"><i class="fas fa-file-csv"></i> Download CSV</button>
        </div>
        <br/>
        <div v-if="options">
            <div class="row" style="margin-bottom:5px">
                <div class="col-md-8">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" :disabled="!selected_ids.length"  @click="send_ids" class="btn btn-secondary btn-info"><i class="fas fa-anchor fa-fw"></i> Disembark</button>
                        <button type="button" class="btn btn-secondary btn-warning"><i class="fas fa-trash fa-fw"></i> Delete</button>
                    </div>
                </div>
            </div>
            <div class="chiller_cb">
                <p class="" style="margin:0 !important">
                    <input id="select-row" @click="toggle_row_select" v-model="select_all_val" class="vx_input_2" type="checkbox">
                    <label for="select-row">{{select_all_val ? 'Unselect all' : "Select all" }}</label>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">

            </div>
        </div>
        <div :id="'example-table'+unique_id"></div>
    </div>
</template>

<script>
const uuidv1 = require('uuid/v1');

export default {
    
    props : {
        options : {
            default : false
        },
        pdf_heading : {
            default : "Report"
        },
        "columns" : {},
        "group_by" : {
           default : false, 
        },
        remote_url : {

        },
        placeholder : {
            default : "Search by name"
        }
    },
    mounted(){
        const com_this = this
        const table = "table" + this.unique_id
        window[table] = new Tabulator("#example-table"+this.unique_id, {
            height:"400px",
            layout:"fitColumns",
            groupToggleElement:"header",
            movableRows:true,
            selectable:true,
            groupBy:this.group_by,
            columns: this.columns,
            pagination:"local", //enable local pagination.
            paginationSize:20,
            rowSelectionChanged:function(data, rows){
                com_this.selected_ids = data.map(element => {
                    return element.id
                });
            },
        });
        $("#download-csv"+com_this.unique_id).click(function(){
            window["table" + com_this.unique_id].download("csv", "data.csv");
        });
        $("#download-xlsx"+com_this.unique_id).click(function(){
            window["table" + com_this.unique_id].download("xlsx", "data.xlsx", {sheetName:"My Data"});
        });

        //trigger download of data.pdf file
        $("#download-pdf"+com_this.unique_id).click(function(){
            window["table" + com_this.unique_id].download("pdf", "data.pdf", {
                orientation:"landscape", //set page orientation to portrait
                title:com_this.pdf_heading, //add title to report
            });
        });
                this.loadDate()
    },

    data(){
        return {
            unique_id : uuidv1(),
            query_param : null,
            select_all_val : false,
            selected_ids : [],
             res_data : [],
             rows : [],
             toggle_group_show : "vessel_name"

        }
    },
    methods : {
        send_ids(){
            this.$emit('table_ids',this.selected_ids
            );
        },
        toggle_row_select(){
            this.select_all_val != this.select_all_val;
            if(this.select_all_val == false){
                window["table" + this.unique_id].selectRow();
            }else{
                window["table" + this.unique_id].deselectRow();;
            }
        },
        loadDate(){
            axios.get(this.remote_url)
                 .then(res => {
                     this.res_data = res.data.data
                 })
        },
        search(){
            axios.get(this.remote_url,{
                params : {
                name : this.query_param
                }
            })
            .then(res => {
                this.res_data =res.data.data
            });
        }
    },
    watch : {
        res_data : {
            handler : function(){
                const com_this = this;
                window["table" + this.unique_id].redraw()
                window["table" + this.unique_id].setData(this.res_data).then(function(){
                 setTimeout(function(){ 
                  window["table" + com_this.unique_id].redraw()
                }, 4000);
                })
                
            },
            deep : true
        }
    }
}
</script>

<style scoped>
.tabulator {
    font-size: 11.5px !important;
}
.w-240 {
  width: 240px;
}
.fa-file-excel{
  color: green !important;
}
.fa-file-csv{
  color: blue !important;
}
caption {
  margin-bottom: -30px !important
}
</style>



