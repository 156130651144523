<template>
    <div>
        <div class="loading">
            <div class="spinner">
                <i class="vx_spinner fas fa-ship fa-lg"></i>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        var loadingOverlay = document.querySelector('.loading');
function toggleLoading(event){
 
  
  document.activeElement.blur();
  
  if (loadingOverlay.classList.contains('hidden')){
    loadingOverlay.classList.remove('hidden');
  } else {
    loadingOverlay.classList.add('hidden');
  }
}
    }
}
</script>


<style scoped>
*.hidden {
  display: none !important;
}

div.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
}

.spinner{
    width:100px;
  height:100px;
  border-radius:50%;
  position:relative;
  z-index:1;
}
.spinner:before{
  content:'';
  width:100px;
  height:100px;
  border-radius:50%;
  /* border-bottom-color: #0099cc; */
  border-bottom-color:#fff;
  border-bottom-style: solid;
  position:absolute;
  z-index:9;
  top: -30px;
  left: -1px;
  animation:rt 2s infinite;
}


@keyframes rt{
  from{
    transform:rotate(0deg);
  }
  to{
    transform:rotate(360deg);
  }
}
.spinner > .fa{
  font-size:32px;
  text-align:center;
  display:block;
  margin:30% 0;
}
.vx_spinner {
    font-size:30px !important;
    color: white !important
}
.uil-ring-css {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
}
.uil-ring-css > div {
  position: absolute;
  display: block;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 80px;
  box-shadow: 0 6px 0 0 #ffffff;
  -ms-animation: uil-ring-anim 1s linear infinite;
  -moz-animation: uil-ring-anim 1s linear infinite;
  -webkit-animation: uil-ring-anim 1s linear infinite;
  -o-animation: uil-ring-anim 1s linear infinite;
  animation: uil-ring-anim 1s linear infinite;
}
</style>
