<template>
    <div>
        
    </div>
</template>

<script>
import payslip_table from '../../utils/payslip_table.vue';
import alert_mixins from '../../mixins/alertify.jsx';

export default {
    components : {payslip_table},
    mixins : {alert_mixins},
    data(){
        return {
            columns : [
                {title:"header1", field:"header1"},
                {title:"header2", field:"header2"},
                {title:"header3", field:"header3"},
                {title:"header4", field:"header4"},
                {title:"header5", field:"header5"},
                {title:"header6", field:"header6"},
                {title:"header7", field:"header7"},
            ]
        }
    }
}
</script>

<style scoped>

</style>
