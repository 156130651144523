var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    _vm._l(_vm.documents, function(doc, index) {
      return _c("div", { staticStyle: { "margin-bottom": "20px!important" } }, [
        _c("td", [
          _c("i", { staticStyle: { "font-size": "10px!important" } }, [
            _vm._v(_vm._s(doc.date_of_issue) + " - " + _vm._s(doc.expiry_date))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "progress-count progress-small" }, [
          _c("div", {
            staticClass: "bar",
            attrs: {
              duration: doc.duration,
              period_type: doc.period_type,
              elapsed_percent: _vm.get_elapsed_percentage(doc.duration),
              expiry_date: doc.expiry_date,
              date_of_issue: doc.date_of_issue
            }
          })
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }