var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.is_super_admin
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-md-4",
                staticStyle: { "margin-bottom": "5px" }
              },
              [
                _c("label", [_vm._v("Filter by User/Author")]),
                _vm._v(" "),
                _c("vx_select", {
                  attrs: {
                    remote_options: true,
                    remote_url: "/search/get_users",
                    placeholder: "User"
                  },
                  model: {
                    value: _vm.user_id,
                    callback: function($$v) {
                      _vm.user_id = $$v
                    },
                    expression: "user_id"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-nowrap bd-highlight" }, [
        _c(
          "div",
          { staticClass: "p-2 bd-highlight col-md-3" },
          [
            _c("label", { attrs: { for: "exampleInputEmail1" } }, [
              _vm._v("Company")
            ]),
            _vm._v(" "),
            _vm.is_super_admin == true
              ? _c("vx_select", {
                  attrs: {
                    filter_field: "user_id",
                    filter_value: _vm.user_id,
                    remote_options: true,
                    remote_url: "/search/get_companies",
                    placeholder: "Companies"
                  },
                  model: {
                    value: _vm.company_id,
                    callback: function($$v) {
                      _vm.company_id = $$v
                    },
                    expression: "company_id"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.is_super_admin == false
              ? _c("vx_select", {
                  attrs: {
                    remote_options: true,
                    remote_url: "/search/get_companies",
                    placeholder: "Companies"
                  },
                  model: {
                    value: _vm.company_id,
                    callback: function($$v) {
                      _vm.company_id = $$v
                    },
                    expression: "company_id"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "p-2 bd-highlight col-md-3" },
          [
            _c("label", { attrs: { for: "exampleInputEmail1" } }, [
              _vm._v("Vessel")
            ]),
            _vm._v(" "),
            _c("vx_select", {
              attrs: {
                filter_value: _vm.company_id,
                filter_field: "company_id",
                remote_options: true,
                remote_url: "/search/get_vessels",
                placeholder: "Vessel"
              },
              model: {
                value: _vm.vessel_id,
                callback: function($$v) {
                  _vm.vessel_id = $$v
                },
                expression: "vessel_id"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 col-md-2 bd-highlight" }, [
          _c(
            "div",
            { attrs: { id: "container" + _vm.unique_id } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Go to period")]),
              _vm._v(" "),
              _vm.is_super_admin == false
                ? _c("vx_select", {
                    attrs: {
                      filter_field: "vessel_id",
                      filter_value: _vm.vessel_id,
                      payslip: true,
                      options: _vm.select_options,
                      placeholder: "Select Period"
                    },
                    model: {
                      value: _vm.selected_period,
                      callback: function($$v) {
                        _vm.selected_period = $$v
                      },
                      expression: "selected_period"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.is_super_admin
                ? _c("vx_select", {
                    attrs: {
                      filter_field: "vessel_id",
                      filter_value: _vm.vessel_id,
                      payslip: true,
                      options: _vm.select_options,
                      placeholder: "Select Period"
                    },
                    model: {
                      value: _vm.selected_period,
                      callback: function($$v) {
                        _vm.selected_period = $$v
                      },
                      expression: "selected_period"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 col-md-3 bd-highlight" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.seaf_name,
                expression: "seaf_name"
              }
            ],
            staticClass: "form-control",
            staticStyle: { margin: "20px 0px 0px 5px" },
            attrs: { type: "text", placeholder: "Seafarer's Name" },
            domProps: { value: _vm.seaf_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.seaf_name = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-nowrap bd-highlight" }, [
        _c("div", { staticClass: "p-2 bd-highlight" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              staticStyle: { margin: "20px 0px 0px 5px" },
              attrs: { disabled: !_vm.selected_period, id: "find_period" },
              on: { click: _vm.loadDate }
            },
            [_c("i", { staticClass: "fas fa-search fa-fw" }), _vm._v(" Search")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 bd-highlight" }, [
          !_vm.is_super_admin
            ? _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  staticStyle: { margin: "20px 0px 0px 5px" },
                  attrs: {
                    disabled: !_vm.selected_period,
                    id: "delete_period_btn"
                  },
                  on: { click: _vm.deletePeriod }
                },
                [
                  _c("i", { staticClass: "fas fa-trash fa-fw" }),
                  _vm._v(" Delete Period")
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("p", {}, [
        _c("input", {
          staticClass: "vx_input_2",
          attrs: { type: "checkbox", id: "sel_payslip" },
          on: { change: _vm.toggle_row_select }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "sel_payslip" } }, [_vm._v("Select All")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "btn-group",
          staticStyle: { "margin-bottom": "15px" },
          attrs: { role: "group", "aria-label": "Basic example" }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-info",
              attrs: { type: "button", disabled: _vm.selected_ids.length == 0 },
              on: { click: _vm.enter_bulk_email }
            },
            [
              _c("i", { staticClass: "fas fa-envelope" }),
              _vm._v(" Send bulk to mail")
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "accordion_headers",
          staticStyle: { "margin-bottom": "15px" }
        },
        [
          _c("div", { staticClass: "accordion" }, [
            _c("div", { staticClass: "heading" }, [_vm._v("Payslip headers")]),
            _vm._v(" "),
            _c("div", { staticClass: "contents" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "bg-light",
                    staticStyle: { padding: "5px", border: "1px solid gray" }
                  },
                  _vm._l(_vm.selected_row_header, function(x, index) {
                    return _c("span", { staticClass: "button-group" }, [
                      x.value != "" && x.value != null
                        ? _c("input", {
                            attrs: {
                              id: "checkbox1" + index,
                              type: "checkbox"
                            },
                            domProps: { checked: x.required },
                            on: {
                              click: function($event) {
                                return _vm.toggle_check(x)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      x.value != "" && x.value != null
                        ? _c(
                            "label",
                            {
                              staticClass: "vx_selct_btn",
                              attrs: { for: "checkbox1" + index }
                            },
                            [_vm._v(_vm._s(x.value))]
                          )
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    staticStyle: { "margin-top": "5px" },
                    attrs: { id: "update_header" },
                    on: { click: _vm.updateSlipHeaders }
                  },
                  [_vm._v("Update")]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { attrs: { id: "example-table" + _vm.unique_id } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "preview_slip",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "disEmbarkModal",
            "aria-hidden": "true"
          }
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-lg-offset-0 col-lg-6 col-xs-12 col-sm-6",
                      staticStyle: { "margin-top": "15px" }
                    },
                    [
                      _c("div", { staticClass: "form-group" }, [
                        _c("span", [
                          _c(
                            "label",
                            { attrs: { for: "exampleInputEmail1" } },
                            [_vm._v("Send Email")]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("p", { staticStyle: { margin: "0 !important" } }, [
                            _c("input", {
                              staticClass: "vx_input_2",
                              attrs: { type: "checkbox", id: "test2" },
                              on: { click: _vm.toggle_manual_email }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "test2" } }, [
                              _vm._v(" Enter email address")
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "div_colLeft",
                            staticStyle: { "margin-right": "5px" }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.manual_email_show,
                                  expression: "manual_email_show"
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.manual_email,
                                  expression: "manual_email"
                                }
                              ],
                              staticClass: "form-control",
                              staticStyle: { "margin-top": "5px" },
                              attrs: {
                                type: "text",
                                placeholder: "seafarer's email"
                              },
                              domProps: { value: _vm.manual_email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.manual_email = $event.target.value
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "div_colwrap" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              staticStyle: { margin: "3px 0px 0px 0px" },
                              attrs: {
                                id: "send_manual_mail",
                                disabled: _vm.allow_manual_email
                              },
                              on: { click: _vm.sendManualMail }
                            },
                            [_vm._v("Send")]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-m btn-info pull-right",
                      staticStyle: { "margin-right": "15px" },
                      on: { click: _vm.print_slip }
                    },
                    [
                      _c("i", { staticClass: "fas fa-print fw" }),
                      _vm._v(" Print Paylsip")
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "modal-body",
                attrs: { id: "slip_content" }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "bulk_modal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "disEmbarkModal",
            "aria-hidden": "true"
          }
        },
        [
          _c("div", { staticClass: "modal-dialog vx_modal" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { padding: "15px" } },
                  [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [_vm._v("Provide a valid email address")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.bulk_email,
                            expression: "bulk_email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.bulk_email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.bulk_email = $event.target.value
                          }
                        }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { attrs: { id: "modal_body" } }),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  {
                    staticClass: "pull-left row",
                    staticStyle: { margin: "5px 12px 0px 0px" }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary save_record",
                        staticStyle: { color: "white" },
                        attrs: {
                          disabled: _vm.bulk_email == "",
                          id: "request_access_btn",
                          role: "button"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.send_bulk_mails($event)
                          }
                        }
                      },
                      [_vm._v("Send mail")]
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.show_loading ? _c("loader") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", { staticClass: "fas fa-exclamation-circle fa-fw" }),
      _vm._v(" Click on headers to exlude / include in payslip")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }, [
        _vm._v("Pay slip preview")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }, [
        _vm._v("Bulk payslip mailing")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }