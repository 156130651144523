var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass: "table table-striped table-hover",
      class: _vm.tblClass,
      staticStyle: { "margin-bottom": "0" },
      style: _vm.tblStyle
    },
    [
      _c(
        "colgroup",
        [
          _vm.shouldRenderSelection
            ? _c("col", { staticStyle: { width: "30px" } })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.columns, function(col) {
            return _c("col", { class: col.colClass, style: col.colStyle })
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }