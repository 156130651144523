<template>
    <div>
                <div class="row" v-if="is_super_admin">
<div class="col-md-4" style="margin-bottom:5px">
    <label>Filter by User/Author</label>
        <vx_select 
        v-model="user_id"
        :remote_options="true"
        :remote_url="'/search/get_users'"
        placeholder="User">
    </vx_select>
</div>
</div>
       <div class="paper_no_shw col-md-12" style="margin-bottom:15px">
            <div class="row">
                <div class="col-lg-2 col-xs-12 col-sm-2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Seafarer ID</label>
                        <input type="text" v-model="seafarer_id" class="form-control">
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12 col-sm-3">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Name</label>
                        <input type="text" placeholder="(Lastname Firstname Othername)" v-model ="seafarer_name" class="form-control">
                    </div>
                </div>

                <div class="col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Company</label>
                        <vx_select 
                            v-model="company_id"
                            :remote_options="true"
                            :remote_url="'/search/get_companies'"
                            placeholder="Companies">
                        </vx_select>
                    </div>
                </div>

                <div class="col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Vessel</label>
                        <vx_select 
                            v-model="vessel_id"
                            :filter_value = "company_id"
                            filter_field = "company_id"
                            :remote_options="true"
                            :remote_url="'/search/get_vessels'"
                            placeholder="Vessel">
                        </vx_select>
                    </div>
                </div>
                <div class="col-lg-offset-0 col-lg-1 col-xs-12 col-sm-1">
 <button style="margin-top:15px" @click="search" class="btn btn-primary">Search</button>
                    
                </div>
            </div>

            
        </div>
        <span class="chiller_cb">
            <p class="" style="margin-bottom: 15px !important;">
                <input id="select-row" @click="toggle_row_select" v-model="select_all_val" class="vx_input_2" type="checkbox">
                <label for="select-row">{{select_all_val ? 'Unselect all' : "Select all" }}</label>
            </p>
        </span>
                    <div class="row" style="margin-bottom:5px">
                <div class="col-md-8">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" :disabled="selected_ids.length < 1" @click="mass_disembark" class="btn btn-secondary btn-info"><i class="fas fa-anchor fa-fw"></i> Disembark</button>
                        <button type="button" :disabled="selected_ids.length < 1" @click="mass_delete(null)" class="btn btn-secondary btn-warning"><i class="fas fa-trash fa-fw"></i> Delete</button>
                    </div>
                </div>
            </div>
            <div class="project box col-md-12" style="margin-bottom:15px">
<div id="embar_list_table"></div>
            </div>
        
        <div>

        </div>
    </div>

</template>

<script>
import vx_tabulator from '../../utils/vx_tabulator.vue';
import alert_mixins from '../../mixins/alertify.jsx';
import vx_select from '../../utils/vx_select.vue'

export default {
    mixins : [alert_mixins],
    components : {vx_tabulator,vx_select},
    props : {
        is_super_admin : {
            default : false
        }
    },
    data(){
        return {
            query_param : null,
            select_all_val : false,
            selected_ids : [],
            user_id : "",
            vessel_id : "",
            seafarer_id : "",
            seafarer_name : "",
            company_id : "",
             res_data : [],
             rows : [],
             toggle_group_show : "vessel_name"
        }
    },
    methods : {
         search(){
            const com_this = this;
            window.embark_table.setData("/search/embarkments",{
                seafarer_id : com_this.seafarer_id,
                seafarer_name : com_this.seafarer_name,
                company_id : com_this.company_id,
                user_id : com_this.user_id,
                vessel_id : com_this.vessel_id,
            })
        },
        view(data){
            window.location =  "/seafarer/view/"+data.seaf_id
        },
              toggle_row_select(){
            this.select_all_val != this.select_all_val;
            if(this.select_all_val == false){
                embark_table.selectRow();
            }else{
                embark_table.deselectRow();;
            }
        },
        mass_delete(cell = null){
            if(cell){
               this.selected_ids.push(cell.getRow().getData().id)
            }
            const com_this = this;

            var data = {
                delete_ids : this.selected_ids
            }

            alertify.confirm('Alert are you sure you want to delete?',function(e){
                if(e){
                    axios.post("/seafarer_embarkment/mass_delete",data)
                    .then(response => {
                        if(response.data.status == "SUCCESS"){
                                com_this.alert({
                                alert_type : 'success',
                                title : 'Seafarer embarkation',
                                alert_msg : "Succes",
                                msg : response.data.msg
                                }).set({title : "Delete record...", onclose:function(){
                                    window.location.reload()
                                }})
                        }    
                    })
                }
            }).set({title : "Delete Record.."})

        },
      mass_disembark(){
            var data = {
                disembark_ids : this.selected_ids
            }
            axios.post("/seafarer_embarkment/mass_disembark",data)
                 .then(response => {
                     if(response.data.status == "SUCCESS"){
                            this.record_id = response.data.record_id;
                            this.alert({
                              alert_type : 'success',
                              title : 'Seafarer embarkation',
                              alert_msg : "Succes",
                              msg : response.data.msg
                            }).set({onclose:function(){
                                window.location.reload()
                               }})
                     }    
                 })
        }
    },
    mounted(){
      const com_this = this;
                    var deleteIcon = function(){
                    return "<i class='fas fa-trash-alt'></i>";
                }
                var obj0 = {
                    formatter: deleteIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.mass_delete(cell)}
                }
                var viewIcon = function(){
                    return "<i class='fas fa-file'></i>";
                }
                var obj1 = {
                    formatter: viewIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.view(cell.getRow().getData())}
                }

        window.embark_table = new Tabulator("#embar_list_table", {
            height:"500px",
            layout:"fitColumns",
            ajaxURL:"/search/embarkments",
            ajaxProgressiveLoad:"scroll",
            paginationSize:20,
            pagination : "remote",
            groupBy : 'vessel_name',
            groupToggleElement:"header",
            movableRows:true,
            selectable:true,
            placeholder:"No Data Set",
            rowSelectionChanged:function(data, rows){
                com_this.selected_ids = data.map(element => {
                    return element.id
                });
            },
            columns:[
                {title:"Vessel", field:"vessel_name", width:200},
                {title:"Name", field:"seaf_name",width:150},
                {title:"Rank", field:"seaf_position",width:150},
                // {title:"Gender", field:"seaf_gender"},
                {title:"Embarked Date", field:"start_date", formatter:"date",width: 100},
                {title:"Disembark Date", field:"end_date",width: 100},
                {title:"Days Left", field:"progress", width:90, formatter:function(cell, formatterParams){
                    var value = cell.getData().days_left;
                    var days_left_perce = cell.getData().progress
                    var new_per = days_left_perce
                    if(value < 0){
                        value = "+"+Math.abs(value)
                        new_per = "100"
                    }
                    cell.getElement().classList.add('my_custom_format');
                    var color_it = 'rgb(' + ((new_per *2.56) +',' + (100 - new_per) *2.56) +',0)';
                    return '<div style="position:relative; text-align:center; height:100%; width:calc('+new_per+'%); background-color:'+color_it+'; display:inline-block;"><span class="vx_formatter" style="text-align:center">'+value+'</span></div>'
                    // return value;
                }},
                {title:"User", field:"user"},

            ],
            footerElement : '<div style="float:left" id="table-footer"> <span style="font-weight:100">Total records:</span> <b><span class="max"></span></b></div>',
            ajaxResponse:function(url, params, response){
     
        $("#table-footer .max").html(response.total)

        return response; //return the response data to tabulator (you MUST include this bit)
    }
        });
        embark_table.addColumn(obj0)
        embark_table.addColumn(obj1)

    }
    
}
</script>

<style scoped>
.w-240 {
  width: 240px;
}
.fa-file-excel{
  color: green !important;
}
.fa-file-csv{
  color: blue !important;
}
caption {
  margin-bottom: -30px !important
}

 .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
    
    label {
        cursor: pointer;
    }
    
    :-ms-input-placeholder {
        color: #ccc;
    }
    
    ::-moz-placeholder {
        color: #ccc;
    }
    
    ::-webkit-input-placeholder {
        color: #ccc;
    }
    /* control search */
    
    .control-search {
        -webkit-border-radius: 40px;
        border-radius: 40px;
    }
    /* group addon */
    
    .paper_no_shw{
        padding:10px !important
    }
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    display: none !important
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel{
    font-size: 10px !important
}
input[type="text"]{
    text-transform: uppercase !important
}
</style>


