import { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

const store = new Store({
    state : {
        current_user : {
           
        }
    },
    mutations : {
        user_update(state,user){
            state.current_user = user;
        }
    },
    plugins: [
        createPersistedState({
        getState: (key) => Cookies.getJSON(key),
        setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true })
        })
    ]
})

export default store;