var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "-td-color-comp",
      style: { "background-color": _vm.gen_color }
    },
    [_vm._v("\n  " + _vm._s(_vm.the_value) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }