<template>
    
</template>

<script>
import vx_select from '../../utils/vx_select.vue';
import comment_box from '../../utils/comment_box.vue';
import form_mixins from '../../mixins/form_mix.jsx';
import alert_mixins from '../../mixins/alertify.jsx';
import vx_field_set from '../../utils/fieldset.vue';
import FormData from '../../../scripts/form_data.js';

export default {
    object_name : "company",
    form_ids : ['company_form'],
    components : {vx_select,vx_field_set,comment_box},
    mixins : [form_mixins,alert_mixins],
    props : { 
    },
    data(){
        return {
            formData : null,
            company_ref_fields : []
        }
    },
    methods : {
        
         save_record(){
        window.save_btn = Ladda.create( document.getElementById('save_record'));

                save_btn.start();

                if(this.company.id != ""){
                    this.company["save_mode"] = "UPDATE_RECORD"
                    this.company["trans_code"] = "SVL001"
                }else{
                    this.company["save_mode"] = "SAVE_RECORD"
                    this.company["trans_code"] = "SVE001"
                }
                
                const com_this = this;

                this.formData = new FormData({
                    data : this.company,
                    alertify : true
                })
                this.formData.post('/admin/company/create')
                    .then(response => {
                         if(response.status == "PROCEED"){
                             this.form_validation = true
                         }

                        if(response.status == "SUCCESS"){
                            save_btn.stop();
                            this.record_id = response.data.record_id;
                            this.showConfirm({
                                title : "Company Registration",
                                msg : response.msg
                            },[
                              
                            ]);

                            document.getElementById('id1').addEventListener('click', function () {
                                location.reload()
                            });


                            document.getElementById('id2').addEventListener('click', function () {
                                window.location.replace("/company/view/"+com_this.record_id);
                            });
                        }
                    })
                    .catch(data => {
                        save_btn.stop();
                    });
            },
            addCompanyRef(objct){
                this.company_ref_fields.push(objct)
                this.company.company_refs_attributes.push(objct);
            }
    },
    mounted(){

  $(".form-control.naija_number").keyup(function(){
    var prefix = "+234"

    if(this.value.indexOf(prefix) !== 0 ){
        this.value = prefix + this.value;
    }
});

  $(".form-control.naija_number").keypress(function (e) {
 //if the letter is not digit then display error and don't type anything
 if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
    //display error message
           return false;
}
});
    }
}
</script>

<style scoped>

.push_down{
    margin-top:30px;
}
.entry_heading h5{
margin-bottom: -8px;
color:#191970;
text-transform: uppercase
}
    body {
        padding: 5%;
        font-size: 16px;
        line-height: 1.6;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    
    .form-group {
        margin-bottom: 0;
        padding: 10px 0;
    }
    
    .form-group:first-child {
        border-color: transparent;
    }
    
    .form-group.has-icon .control-group {
        position: relative;
    }
    
    .form-group.has-icon .control-group .btn-search {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 16px;
        height: 16px;
        border: transparent;
        background: transparent;
    }
    
    .form-group.has-icon .control-group .btn-search > .icon {
        top: 0;
        left: 0;
    }
    
    .form-group.has-icon .control-group .icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        fill: #757575;
    }
    
    .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
    }
    
    .btn-primary:hover {
        background-color: #566f7b;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
    
    label {
        cursor: pointer;
    }
    
    :-ms-input-placeholder {
        color: #ccc;
    }
    
    ::-moz-placeholder {
        color: #ccc;
    }
    
    ::-webkit-input-placeholder {
        color: #ccc;
    }
    /* control search */
    
    .control-search {
        -webkit-border-radius: 40px;
        border-radius: 40px;
    }
    input[type="text"],input[type="textarea"],textarea{
  /*  text-transform: uppercase !important */
}

    /* group addon */
    
    .input-group-addon {
        border-width: 2px;
    }
    .paper_no_shw{
        padding:30px !important
    }
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    display: none !important
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel{
    font-size: 10px !important
}
</style>


