<template>
    
</template>

<script>
import vessel_table from '../../utils/vessel_table.vue'

export default {
    components : {vessel_table},
    data(){
        return {
            vessel_cols : [
                {title: 'ID', field: 'id',sortable: true,visible: 'true',thComp: 'FilterTh'},
                {title: 'Name', field: 'name', sortable: true,visible : 'true',thComp: 'FilterTh'},
                {title: 'Gross Tonage', field: 'gross_tonage', sortable: true,thComp: 'FilterTh'},
                {title: 'Company Phone', field: 'company_phone',visible : 'true', sortable: true,thComp: 'FilterTh'},
                {title: 'Flagship', field: 'flagship', sortable: true,thComp: 'FilterTh'},
                {title: 'Kilowat', field : 'kilowat',sortable : true,thComp: 'FilterTh'},
                {title: 'Operation', tdComp: 'Opt', visible: 'true' },
            ],

            groups_def : {
                Normal: ['Name','Surname','Gross_Tonnage'],
                Sortable: ['Flagship','Company Name','Company Phone'],
                Extra: ['Operation','Kilowat']
            },
            filter_headings : ['id','name','company_name','company_email'],
            summary : {"uid":120,"age":52,"country":7},
            total : 120
        }
    },
    methods : {
        delete_record(id){
            alert("record "+id+" will be deleted")
        }
    },
    mounted(){
    document.addEventListener("deleteRecord",function(e){
       console.log(alert("Implement Deleting for :" + e.record_id));
     });
     document.addEventListener("editRecord",function(e){
       console.log(alert("Implement EditRecord for :" + e.record_id));
     });
    document.addEventListener("viewRecord",function(e){
       window.location = "/vessel/view/"+e.record_id
     });
    }
}

</script>

<style scoped>

.table {
    font-size: 11.5px !important;
}
</style>

