<template>
    <div class="col-md-12" style="padding:0px !important">
                        <div class="row" v-if="is_super_admin">
<div class="col-md-4" style="margin-bottom:5px">
    <label>Filter by User/Author</label>
        <vx_select 
        v-model="user_id"
        :remote_options="true"
        :remote_url="'/search/get_users'"
        placeholder="User">
    </vx_select>
</div>
</div>
        <div class="paper_no_shw " style="margin-bottom:15px">
            <div class="row">
                            <div class="col-lg-2 col-xs-12 col-sm-2">
                <div class="form-group">
                    <label for="exampleInputEmail1">Seafarer ID</label>
                    <input type="text" v-model="seafarer_id" class="form-control">
                </div>
            </div>
            <div class="col-lg-3 col-xs-12 col-sm-3">
                <div class="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input placeholder="(Lastname Firstname Othername)" type="text" v-model ="seafarer_name" class="form-control">
                </div>
            </div>

            <div class="col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3">
                <div class="form-group">
                    <label for="exampleInputEmail1">Company</label>
                    <vx_select 
                        v-model="company_id"
                        :remote_options="true"
                        :remote_url="'/search/get_companies'"
                        placeholder="Companies">
                    </vx_select>
                </div>
            </div>

            <div class="col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3">
                <div class="form-group">
                    <label for="exampleInputEmail1">Vessel</label>
                     <vx_select 
                        v-model="vessel_id"
                        :filter_value = "company_id"
                        filter_field = "company_id"
                        :remote_options="true"
                        :remote_url="'/search/get_vessels'"
                        placeholder="Vessel">
                    </vx_select>
                </div>

      
            </div>
                <div class="col-md-1" style="margin-top:15px; padding:0 !important">
                    <button @click="search" class="btn btn-primary" style="min-width:5px !important">Search</button>
                </div>
            </div>


        </div>
  <div class = "center">
      <div class = "button-wrap">
      <button id="download-csv" class = "rad-button small light gradient"> <i class="fas fa-file-excel fa-lg"></i> Export EXCEL</button>
    </div>
      <div class = "button-wrap">
      <button id="download-pdf" class = "rad-button small light gradient"><i class="fas fa-file-excel fa-file-pdf"></i> Export PDF</button>
    </div>
      <div class = "button-wrap">
      <button id="download-json" class = "rad-button small light gradient"><i class="fas fa-file-csv fa-lg"></i> Export JSON</button>
    </div>

    <div style="margin-left: 310px">
        <p style="margin:0 !important">
            <input v-model="export_all" class="vx_input_2" type="checkbox" id="allow_all">
            <label for="allow_all"><b>Include All Records</b></label>
        </p>
    </div>


    
  </div>
        <div class="project box col-md-12">
            <div id="example-table"></div>
        </div>
        <loader v-if="show_loading"></loader>

    </div>
</template>

<script>
import alert_mixins from '../../mixins/alertify.jsx';
import vx_select from '../../utils/vx_select.vue'
import loader from '../../utils/loader.vue';
export default {
    mixins : [alert_mixins],
    components : {vx_select,loader},
    props : {
        is_super_admin : {
            default : false,
        }
    },
    data(){
        return {
            total_records : 0,
            seafarer_id : "",
            seafarer_name : "",
            company_id : "",
            vessel_id : "",
            export_all : false,
            user_id : "",
            all_rows : null
        }
    },
    methods : {
        search(){
            const com_this = this;
            window.table.setData("/document_tracker/expiring_documents",{
                seafarer_id : com_this.seafarer_id,
                seafarer_name : com_this.seafarer_name,
                company_id : com_this.company_id,
                vessel_id : com_this.vessel_id,
                user_id : com_this.user_id,
            })
        },
        view(data){
            window.location = "/seafarer/view/" + data.seaf_id
        },
        deleteVessel(data){
            const com_this = this;
            alertify.confirm('Warning! Deleting a vessel will remove all associated embarkations with it.',function(e){
                if(e){
                axios.post('/vessel/delete_vessel',{
                    id : data.getRow().getData().id
                }).then(res => {
                    if(res.data.status == "SUCCESS"){
                        com_this.alert({
                            alert_type : "success",
                            title : "Vessel deletion...",
                            alert_msg : "success",
                            msg : res.data.msg
                        }).set({title : "Vessel deletion..."});
                        data.getRow().delete()
                    }
                }).catch(res => {
                    com_this.alert(res.data)
                }) 
                    }
                }).set({title: "Vessel deletion"});
        }
    },
    mounted(){
        const com_this = this;
                    var deleteIcon = function(){
                    return "<i class='fas fa-trash-alt'></i>";
                }
                var obj0 = {
                    formatter: deleteIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.deleteVessel(cell)}
                }
                var viewIcon = function(){
                    return "<i class='fas fa-file'></i>";
                }
                var obj1 = {
                    formatter: viewIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.view(cell.getRow().getData())}
                }

        window.table = new Tabulator("#example-table", {
            height:"500px",
            layout:"fitColumns",
            ajaxURL:"/document_tracker/expiring_documents",
            ajaxProgressiveLoad:"scroll",
            paginationSize:50,
            pagination : "remote",
            placeholder:"No records found..",
            downloadDataFormatter: function (data) {
                if(com_this.export_all){
                    return {
                        calcs : {
                            top: {},
                            bottom: {}
                        },
                        data: com_this.all_rows
                    };
                }else{
                    return data
                }              
            },
            columns:[
                // {title:"ID", field:"id", sorter:"string"},
                {title:"Seafarer ID", field:"seafarer_id", sorter:"string", width: 120},
                {title:"Name", field:"seafarer_name", sorter:"string", width: 220},
                {title:"Position", field:"position", sorter:"string", width: 120},
                {title:"Email", field:"email", sorter:"string", width: 120},
                {title:"Phone", field:"phone", sorter:"string", width: 120},
                    {title:"Status", field:"is_exp", width:100, formatter:function(cell, formatterParams){
                        var value = cell.getValue();
                        if(value === "EXP"){
                            return '<div style="margin: 0 auto;text-align:center;border-radius:100%; width: 20px; height:20px;position:relative; height:100%; background-color:red"></div>'
                        }if(value === "OK"){
                            return '<div style="margin: 0 auto;text-align:center;border-radius:100%; width: 20px; height:20px;position:relative; height:100%; background-color:green"></div>'
                        }
                        // return value;
                    }},
                {title:"User", field:"user", sorter:"string", sortable:false, width:100},
            ],
        footerElement : '<div style="float:left" id="table-footer"> <span style="font-weight:100">Total records:</span> <b><span class="max"></span></b></div>',
            ajaxResponse:function(url, params, response){
     
        $("#table-footer .max").html(response.total)

        return response; //return the response data to tabulator (you MUST include this bit)
    }
        });
        // table.addColumn(obj0)
        table.addColumn(obj1)

        //trigger download of data.csv file
$("#download-csv").click(function(){
    var csv_button = Ladda.create( document.getElementById('download-csv'));
    csv_button.start();
    if(com_this.export_all){
        axios.get("/document_tracker/expiring_documents")
        .then(response => {
            com_this.all_rows = response.data.data;
        })
        .then(() => {
            table.download("csv", "document_tracker_all.csv");
        })
    }else{
        table.download("csv", "document_tracker.csv");
    }
    csv_button.stop();

});

//trigger download of data.json file
$("#download-json").click(function(){
    var csv_button = Ladda.create( document.getElementById('download-json'));
    csv_button.start();
    if(com_this.export_all){
        axios.get("/document_tracker/expiring_documents")
        .then(response => {
            com_this.all_rows = response.data.data;
        })
        .then(() => {
            table.download("json", "document_tracking_all.json");;
        })
    }else{
        table.download("json", "document_tracking.json");
    }
    csv_button.stop();
});

//trigger download of data.xlsx file
$("#download-xlsx").click(function(){
    var xl_button = Ladda.create( document.getElementById('download-xlsx'));
    xl_button.start();
    if(com_this.export_all){
        axios.get("/document_tracker/expiring_documents")
        .then(response => {
            com_this.all_rows = response.data.data;
        })
        .then(() => {
            table.download("xlsx", "document_tracking_all.xlsx", { sheetName  :"My Data All"});
        })
    }else{
        table.download("xlsx", "document_tracking.xlsx", { sheetName  :"My Data"});
    }
    xl_button.stop();
});

//trigger download of data.pdf file
$("#download-pdf").click(function(){
    var pdf_button = Ladda.create( document.getElementById('download-pdf'));
    pdf_button.start();
    if(com_this.export_all){
        axios.get("/document_tracker/expiring_documents")
        .then(response => {
            com_this.all_rows = response.data.data;
        })
        .then(() => {
            table.download("pdf", "document_tracking.pdf", {
        orientation:"portrait", //set page orientation to portrait
        title:"Tosdere NG - Document Tracking List", //add title to report
    });
        })
    }else{
        table.download("pdf", "document_tracking.pdf", {
        orientation:"portrait", //set page orientation to portrait
        title:"Tosdere NG - Document Tracking List", //add title to report
    });
    }
    pdf_button.stop();
});

    }
}
</script>

<style scoped>
 .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
        color: white !important
    }
    
    .btn-primary:hover {
        background-color: #38474f;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
    
    label {
        cursor: pointer;
    }
    
    :-ms-input-placeholder {
        color: #ccc;
    }
    
    ::-moz-placeholder {
        color: #ccc;
    }
    
    ::-webkit-input-placeholder {
        color: #ccc;
    }
    /* control search */
    
    .control-search {
        -webkit-border-radius: 40px;
        border-radius: 40px;
    }
    /* group addon */
    
    .paper_no_shw{
        padding:10px !important
    }
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    display: none !important
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel{
    font-size: 10px !important
}
input[type="text"]{
    text-transform: uppercase !important
}
</style>

