<template>
    <div>
                <div id='container'>  
        <div class='cell'><input type='search' v-model = "query_param" :placeholder='placeholder'></div>
        <div class='cell'><button @click.prevent = "search" class='vx-search-button'>Search</button></div>
        </div>
            <div class="" style="margin:10px 0px 10px 0px">
                <button class="btn" id="download-xlsx"><i class="fas fa-file-excel"></i> Download Excel</button>
                <button class="btn" id="download-pdf"><i class="fas fa-file-pdf"></i> Download PDF</button>
                <button class="btn" id="download-csv"><i class="fas fa-file-csv"></i> Download CSV</button>
            </div>
        <div id="example-table"></div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            cheeseData : [
                {id:1, type:"Brie", rind:"mould", age:"4 weeks", color:"white", image:"brie.jpg"},
                {id:2, type:"Cheddar", rind:"none", age:"1 year", color:"yellow", image:"cheddar.jpg"},
                {id:3, type:"Gouda", rind:"wax", age:"6 months", color:"cream", image:"gouda.jpg"},
                {id:4, type:"Swiss", rind:"none", age:"9 months", color:"yellow", image:"swiss.jpg"},
            ]
        }

    },
    mounted(){
        window.crew_table = new Tabulator("#example-table", {
    height:"311px",
    layout:"fitColumns",
    resizableColumns:false,
    columns:[
        {title:"Cheese", field:"type", sorter:"string"},
    ],
    rowFormatter:function(row){
        console.log(row);   
        var element = row._row.element,
        data = row.getData(),
        width = 100,
        rowTable, cellContents;

        //clear current row data

        //define a table layout structure and set width of row
        rowTable = document.createElement("table")
        rowTable.style.width = (width - 18) + "px";

        var rowTabletr = document.createElement("tr");

        //add image on left of row
        cellContents = "<td><img src='/sample_data/row_formatter/" + data.image + "'></td>";

        //add row data on right hand side
        cellContents += "<td><div><strong>Type:</strong> " + data.type + "</div><div><strong>Age:</strong> " + data.age + "</div><div><strong>Rind:</strong> " + data.rind + "</div><div><strong>Colour:</strong> " + data.color + "</div></td>"

        rowTabletr.innerHTML = cellContents;

        rowTable.appendChild(rowTabletr);

        //append newly formatted contents to the row
        element.append(rowTable);
    },
})

//Load Data
crew_table.setData(this.cheeseData);
        //trigger download of data.pdf file
        $("#download-pdf").click(function(){
            crew_table.download("pdf", "data.pdf", {
                orientation:"landscape", //set page orientation to portrait
                title:"Report", //add title to report
            });
        });
    }
}
</script>

