<template>
    
</template>

<script>
import company_vessels from '../../utils/company_vessels.vue';
import alert_mixins from '../../mixins/alertify.jsx';

export default {
    components : {company_vessels},
    mixins : [alert_mixins],
    data(){
        return {
            show_com_vessels : false,
            show_com_cv : true,
        }
    },
    methods : {
        printElem(elem){
            $('#'+elem).printThis({
                importCSS: true,
                importStyle: true,
                loadCSS : "/custom_packages/form_reports.css"
            });
        },
        vieCompanyVessels(){
            this.show_com_vessels = true;
            this.show_com_cv = false;
        },
        viewCompanyCV(){
            this.show_com_vessels = false;
            this.show_com_cv = true;
        }
    }
}
</script>

<style scoped>


</style>


