var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { class: [{ not_attached: !_vm.doc_upload_verifed }, "scanned_doc"] },
      [
        _c("dropzone", {
          staticStyle: { display: "none" },
          attrs: {
            doc_name: _vm.doc_desc,
            vx_dropzone_template: "vx_dropzone_template",
            vx_preview_loc: "card_block_2" + _vm.unq_id_gen,
            dropzone_preview: "dropzone_preview",
            accepted_files: _vm.accepted_files,
            upload_files: "upload_files_2" + _vm.unq_id_gen,
            scanned_files: _vm.scanned_files,
            flag_state: _vm.flag_state,
            other_type: _vm.other_type,
            document_id: _vm.update_obj,
            unq_id_gen_man: _vm.unq_id_gen,
            unq_id_gen: _vm.unq_id_gen,
            upload_btn_id: _vm.upload_btn_id,
            send_or_not: _vm.send_on_no_file,
            req_doc_id: _vm.req_doc_id,
            issue_exp: _vm.issue_exp,
            cert_no: _vm.cert_no,
            cert_inst: _vm.cert_inst,
            required_fields: _vm.required_fields,
            expiry_date: _vm.expiry_date
          },
          on: {
            file_attached_status: _vm.file_attached_status,
            file_attached: _vm.file_attached,
            remove_scanned_file: _vm.remove_scanned_file,
            file_count: _vm.update_file_count,
            remove_file: _vm.remove_file_count,
            file_uploaded: _vm.track_uploads,
            file_not_attached: _vm.file_not_attached
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c(
              "div",
              { staticClass: "bd-example", attrs: { "data-example-id": "" } },
              [
                _c(
                  "div",
                  {
                    attrs: {
                      id: "accordion_2" + _vm.unq_id_gen,
                      role: "tablist",
                      "aria-multiselectable": "true"
                    }
                  },
                  [
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-header",
                          attrs: {
                            role: "tab",
                            id: "headingOne" + _vm.unq_id_gen
                          }
                        },
                        [
                          _c("div", { staticClass: "mb-0" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  overflow: "hidden"
                                }
                              },
                              [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-left": "20%" } },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "collapsed",
                                        attrs: {
                                          "data-toggle": "collapse",
                                          "data-parent":
                                            "#accordion_2" + _vm.unq_id_gen,
                                          href:
                                            "#collapseOne_2" + _vm.unq_id_gen,
                                          "aria-expanded": "false",
                                          "aria-controls": "collapseOne_2"
                                        }
                                      },
                                      [
                                        _c("h5", [
                                          _vm._v(_vm._s(this.doc_desc))
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "files_button" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.scan_enable,
                                                expression: "scan_enable"
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-xs btn-default",
                                            attrs: {
                                              disabled:
                                                _vm.scanned_files.length > 0
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.update_scan_vis(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Scan")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.upload_enable,
                                                expression: "upload_enable"
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-xs btn-default",
                                            attrs: {
                                              disabled:
                                                _vm.scanned_files.length > 0,
                                              id:
                                                "upload_files_2" +
                                                _vm.unq_id_gen
                                            }
                                          },
                                          [_vm._v("Upload")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.upload_enable,
                                                expression: "upload_enable"
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-xs btn-default",
                                            attrs: {
                                              id: "upload_skip" + _vm.unq_id_gen
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.toggle_skip(
                                                  $event.target
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Skip")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: [{ in: _vm.open }, "collapse", "dropzone"],
                          attrs: {
                            id: "collapseOne_2" + _vm.unq_id_gen,
                            role: "tabpanel",
                            "aria-labelledby": "headingOne",
                            "aria-expanded": "false"
                          }
                        },
                        [
                          _vm.enable_cert_field
                            ? _c("div", { staticClass: "date-picker" }, [
                                _c(
                                  "label",
                                  { staticClass: "scan_upload_label" },
                                  [_vm._v("Cert Institute")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cert_no,
                                      expression: "cert_no"
                                    }
                                  ],
                                  staticClass: "cert_no_input",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.cert_no },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.cert_no = $event.target.value
                                    }
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.enable_cert_field
                            ? _c("div", { staticClass: "date-picker" }, [
                                _c(
                                  "label",
                                  { staticClass: "scan_upload_label" },
                                  [_vm._v("Certificate No")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cert_inst,
                                      expression: "cert_inst"
                                    }
                                  ],
                                  staticClass: "cert_no_input",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.cert_inst },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.cert_inst = $event.target.value
                                    }
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.scan_enable
                            ? _c(
                                "div",
                                {
                                  staticClass: "doc_inputs",
                                  staticStyle: { "padding-right": "8px" }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "scan_upload_label",
                                      attrs: { for_flag_state: "" }
                                    },
                                    [_vm._v("Certificate Country")]
                                  ),
                                  _vm._v(" "),
                                  _c("vx_select", {
                                    staticStyle: { width: "60% !important" },
                                    attrs: {
                                      options: _vm.countries,
                                      multiple_sel: false,
                                      placeholder: "Select Flag State",
                                      name: "flag_state"
                                    },
                                    model: {
                                      value: _vm.flag_state,
                                      callback: function($$v) {
                                        _vm.flag_state = $$v
                                      },
                                      expression: "flag_state"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.enable_exp_date
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "date-picker",
                                          staticStyle: {
                                            "margin-top": "10px !important"
                                          }
                                        },
                                        [
                                          _vm.duration == null
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    margin: "0 !important"
                                                  }
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "vx_input_2",
                                                    attrs: {
                                                      type: "checkbox",
                                                      id:
                                                        "test2" + _vm.unq_id_gen
                                                    },
                                                    on: {
                                                      click: _vm.toggle_iss_date
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for:
                                                          "test2" +
                                                          _vm.unq_id_gen
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.show_range_date
                                                            ? "Issue Date only"
                                                            : "Duration select"
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.over_show_range
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "scan_upload_label"
                                                },
                                                [
                                                  _vm._v(
                                                    "Select Issue / Expiration"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.over_show_range
                                            ? _c("range_pick", {
                                                on: {
                                                  input_changed:
                                                    _vm.date_input_changed
                                                },
                                                model: {
                                                  value: _vm.issue_exp,
                                                  callback: function($$v) {
                                                    _vm.issue_exp = $$v
                                                  },
                                                  expression: "issue_exp"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.over_single_date
                                            ? _c("flat-pickr", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  config: _vm.config,
                                                  placeholder: "Select date",
                                                  name: "date"
                                                },
                                                model: {
                                                  value: _vm.issue_exp,
                                                  callback: function($$v) {
                                                    _vm.issue_exp = $$v
                                                  },
                                                  expression: "issue_exp"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "card-block",
                            attrs: { id: "card_block_2" + _vm.unq_id_gen }
                          })
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ]
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-lg",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.scanImage()
                }
              }
            },
            [_vm._v(" Documents")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-lg",
              attrs: { type: "button" },
              on: { click: _vm.showModalDemo }
            },
            [_vm._v(" Demo")]
          ),
          _vm._v(" "),
          _c("br")
        ]
      ),
      _vm._v(" "),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: { id: "the-canvas" + _vm.unq_id_gen }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: { role: "dialog", id: "scan_modal" + _vm.unq_id_gen }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-lg",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-header",
                    staticStyle: { padding: "0 !important" }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "100%", overflow: "hidden" } },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "60px",
                              padding: "20px 0px"
                            }
                          },
                          [
                            _c(
                              "h4",
                              {
                                staticStyle: { "text-transform": "uppercase" }
                              },
                              [_vm._v(_vm._s(_vm.doc_desc))]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "col-md-5" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row Content",
                        attrs: { id: "selectedFiles" + _vm.unq_id_gen }
                      },
                      _vm._l(_vm.scannedImages, function(scanImage, index) {
                        return _c(
                          "div",
                          {
                            staticClass:
                              "col-sm-5 col-md-5 text-center snip0013"
                          },
                          [
                            _c("img", {
                              staticClass: "selFile",
                              attrs: {
                                height: "100%",
                                width: "100%",
                                src: scanImage.image_base_64,
                                "data-file": scanImage.data_file,
                                title: "Click to remove"
                              }
                            }),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(
                              _vm._s(scanImage.scan_no) +
                                "\n                                    "
                            ),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.delScanDoc(index)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-trash left-icon"
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-7" }, [
                    _c(
                      "div",
                      { attrs: { id: "iframeContainer" + _vm.unq_id_gen } },
                      [
                        _c("iframe", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.scan_prev_show,
                              expression: "scan_prev_show"
                            }
                          ],
                          attrs: {
                            src: "",
                            id: "pdf_prev_iframe" + _vm.unq_id_gen
                          }
                        })
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c("div", [
                    _vm.loadDemo
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-info",
                            attrs: { type: "button" },
                            on: { click: _vm.loadDemoScan }
                          },
                          [_vm._v("Scan Demo Scan")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.scanImage()
                          }
                        }
                      },
                      [_vm._v("Scan Documents")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: {
                          disabled: _vm.enable_att_doc,
                          id: "attachFile" + _vm.unq_id_gen,
                          type: "button"
                        },
                        on: { click: _vm.attach_document }
                      },
                      [_vm._v("Attach Scanned File")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        attrs: { type: "button" },
                        on: { click: _vm.modalClose }
                      },
                      [_vm._v("Cancel")]
                    )
                  ])
                ])
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "5%", float: "left" } }, [
      _c("i", {
        staticClass: "fas fa-file-upload",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "60px", float: "left" } }, [
      _c("button", { staticClass: "print-button" }, [
        _c("span", { staticClass: "print-icon" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }