<template>
	<fieldset>    	
		<legend><slot name="title"></slot></legend>
		<div class="">
			<slot name="body"></slot>
		</div>	
	</fieldset>	
</template>

<script>
export default {

  name: 'Fieldset',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
    fieldset 
	{
		border: 1px solid #ddd !important;
		margin: 0;
		xmin-width: 0;
		/* padding: 10px;        */
		position: relative;
		border-radius:4px;
		background-color: transparent;
		padding-left:10px!important;
		padding-right: 10px !important;
		margin-bottom: 15px
	}	
	
		legend
		{
			font-size:12px;
			font-weight:bold;
			margin-bottom: 0px; 
			font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
			width: 100%; 
			border: 1px solid #ddd;
			border-radius: 4px; 
			padding: 3px; 
			padding-left:8px;
			background-color: #ffffff;
		}
	.dropzone .dz-message {
		text-align: center;
		margin: 13px !important;
	}	
</style>
