<template>
    <div class="comment-container">
    <div class="text-left">
    <div class="well vx-well">
        <h4>Notes</h4>
    <div class="input-group" v-if="view_only">
	    <textarea v-model="comment_text" name="" id="comment_box_id" cols="30" rows="2"></textarea>
        <span class="input-group-btn" onclick="addComment()"> 

        </span>
    </div>
    <hr data-brackets-id="12673">
        <p v-if="comments.length == 0">No comments yet</p>

    <ul data-brackets-id="12674" id="sortable" class="list-unstyled ui-sortable">
        <div v-for ="comment in comments">
            <strong class="pull-left primary-font">{{comment.user_email}}</strong>


            <br/>
            <li class="ui-state-default">
                {{comment.comment}}
            </li>
            <small class="pull-right text-muted">
                <span class="glyphicon glyphicon-time"></span>
                {{time_from_now(comment.comment_date)}}
            </small>

            
            <br/>
        </div>
        
        
    </ul>
    </div>
    </div>
</div>
</template>


<script>
export default {
    props : {
        comments : {
            default : function(){
                return []
            }
        },
        view_only : {
            default : true
        }
    },
    data(){
        return  {
            comment_text : ""
        }
    },
    methods : {
        time_from_now(date){
            return moment(date).fromNow()
        }
    },
    mounted(){
        const com_this = this;
        $("#comment_box_id").keyup(function(e){
            com_this.$emit('input',com_this.comment_text)
        });
    }
}
</script>



<style scoped>
.vx-well{
background: whitesmoke !important;
padding: 5px 10px 5px 10px !important
}
textarea {
background: none repeat scroll 0 0 #fff;
border: medium none;
-webkit-border-radius: 4px 4px 0 0;
-moz-border-radius: 4px 4px 0 0;
-ms-border-radius: 4px 4px 0 0;
-o-border-radius: 4px 4px 0 0;
border-radius: 4px 4px 0 0;
color: #777777;
float: left;
font-family: Lato;
font-size: 14px;
letter-spacing: 0.3px;
padding: 20px;
width: 100%;
resize:vertical;
outline:none;
border: 1px solid #F2F2F2;
}

.comment-container {
    width: 100% !important
}
li.ui-state-default{
    background:#fff0;
    border:none;
    border-bottom:1px solid #ddd;
    text-align: left;
    padding-bottom: 10px;
}

li.ui-state-default:last-child{
    border-bottom:none;
}




.well,.panel {
    border-color:#d2d2d2;
    box-shadow:0 1px 0 #cfcfcf;
    border-radius:3px;
}

hr {
 border-color:#ececec;
}

button {
 outline: 0;
}

.btn span{
 color:#666666;
}

.list-group-item:first-child,.list-group-item:last-child {
 border-radius:0;
}

h3 { 
 border:0 solid #efefef; 
 border-bottom-width:1px;
 padding-bottom:10px;
}
</style>

