<template>
    
</template>

<script>

import company_table from '../../utils/company_table.vue';

export default {
    components : {company_table},
    data(){
        return {
        }
    },
    mounted(){

    }
}
</script>

