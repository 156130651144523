var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        {
          staticClass: "tab-wrap full-width-tabs col-md-10",
          staticStyle: { padding: "0 !important" }
        },
        [
          _c(
            "ul",
            { staticClass: "nav nav-tabs" },
            _vm._l(_vm.tabs, function(tab, index) {
              return _c(
                "li",
                {
                  class: [{ active: tab.tab_active }, "take-all-space-you-can"],
                  attrs: {
                    "data-toggle": "tab",
                    href: "#" + _vm.id_gen(tab, index)
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.selectTab(tab, index)
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "vx-tab-bg",
                      attrs: { "data-toggle": "tab", href: "#home" }
                    },
                    [_vm._v(_vm._s(tab.title))]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm._l(_vm.tabs, function(tab, index) {
            return _c("div", { staticClass: "tab-content" }, [
              _c("div", {
                class: [
                  { active: tab.tab_active },
                  { in: tab.tab_active },
                  "tab-pane",
                  "fade"
                ],
                attrs: { id: _vm.id_gen(tab, index) },
                domProps: { innerHTML: _vm._s(tab.$el.innerHTML) }
              })
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }