<template>
    <div>
  <div :class="[{'not_attached' : !doc_upload_verifed},'scanned_doc']">
            <dropzone style="display: none"
                :doc_name = "doc_desc"
                vx_dropzone_template = "vx_dropzone_template"
                :vx_preview_loc =  "'card_block_2'+unq_id_gen"
                dropzone_preview = "dropzone_preview"
                :accepted_files = "accepted_files"
                :upload_files = "'upload_files_2'+unq_id_gen"
                :scanned_files = "scanned_files"
                :flag_state = "flag_state"
                :other_type = "other_type"
                :document_id = "update_obj"
                :unq_id_gen_man = "unq_id_gen"
                :unq_id_gen = "unq_id_gen" 
                :upload_btn_id = "upload_btn_id"
                :send_or_not = "send_on_no_file"
                :req_doc_id = "req_doc_id"
                :issue_exp = "issue_exp"
                :cert_no = "cert_no"
                :cert_inst = "cert_inst"
                :required_fields = "required_fields"
                @file_attached_status = "file_attached_status"
                @file_attached = "file_attached"
                @remove_scanned_file = "remove_scanned_file"
                @file_count = "update_file_count"
                @remove_file = "remove_file_count"
                @file_uploaded = "track_uploads"
                @file_not_attached = "file_not_attached"
                :expiry_date = "expiry_date"
                >
            </dropzone>
            <div class="row">
    <div class="col-md-12">
        <div class="bd-example" data-example-id="">
            <div :id = "'accordion_2'+unq_id_gen" role="tablist" aria-multiselectable="true">
                <div class="card">
                    <div class="card-header" role="tab" :id = "'headingOne'+unq_id_gen">
                        <div class="mb-0">
                            <!-- <a data-toggle="collapse" data-parent="#accordion_2" href="#collapseOne_2" aria-expanded="false" aria-controls="collapseOne_2" class="collapsed">
                                <i class="fas fa-file-upload" aria-hidden="true"></i>
                                <h5>Dynamic Positioning Maintainance</h5>
                            </a>
                            <span class="files_button">
                                <button class="btn btn-xs btn-default">Scan</button>
                                <button class="btn btn-xs btn-default" :id="upload_files_2+'unq_id_gen'">Upload</button>
                            </span> -->
                            <div style="width: 100%; overflow: hidden;">
                                <div style="width: 5%; float: left;"> 
                                    <i class="fas fa-file-upload" aria-hidden="true"></i>
                                </div>
                                <div style="margin-left: 20%;">
                                    <a data-toggle="collapse" :data-parent = "'#accordion_2'+unq_id_gen" :href = "'#collapseOne_2'+unq_id_gen" aria-expanded="false" aria-controls="collapseOne_2" class="collapsed">
                                        <h5>{{this.doc_desc}}</h5>
                                    </a>
                                <span class="files_button">
                                    <button v-show = "scan_enable" :disabled="scanned_files.length > 0" @click.prevent="update_scan_vis" class="btn btn-xs btn-default">Scan</button>
                                    <button v-show= "upload_enable" :disabled="scanned_files.length > 0" class="btn btn-xs btn-default" :id = "'upload_files_2'+unq_id_gen">Upload</button>
                                    <button v-show= "upload_enable" @click="toggle_skip($event.target)" class="btn btn-xs btn-default" :id = "'upload_skip'+unq_id_gen">Skip</button>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :id = "'collapseOne_2'+unq_id_gen" :class = "[{'in' : open},'collapse','dropzone']" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false" style="">
                    <div class="date-picker" v-if = "enable_cert_field">
                         <label class="scan_upload_label">Cert Institute</label>
                        <input type="text" v-model="cert_no" class="cert_no_input">
                    </div> 
                    <div class="date-picker" v-if = "enable_cert_field">
                         <label class="scan_upload_label">Certificate No</label>
                        <input type="text" v-model="cert_inst" class="cert_no_input">
                    </div>   
                        <div style="padding-right:8px" class="doc_inputs" v-if="scan_enable">
                            <label class="scan_upload_label" for_flag_state>Certificate Country</label>
                                <vx_select style="width:60% !important"
                                    :options = "countries"  
                                    :multiple_sel = "false"
                                    v-model = "flag_state"
                                    placeholder = "Select Flag State"
                                    name = "flag_state" >
                                </vx_select>
                        <div v-if="enable_exp_date" class="date-picker" style="margin-top:10px !important">
                                <p style="margin:0 !important" v-if="duration == null">
                                    <input @click = "toggle_iss_date" class="vx_input_2" type="checkbox" :id="'test2'+unq_id_gen">
                                    <label :for="'test2'+unq_id_gen">{{ show_range_date ? 'Issue Date only' : 'Duration select'}}</label>
                                </p>
                            <label  v-if="over_show_range" class="scan_upload_label">Select Issue / Expiration</label>
                            <range_pick v-model="issue_exp" @input_changed = "date_input_changed" v-if="over_show_range"></range_pick>
                            <flat-pickr v-if="over_single_date" v-model="issue_exp" :config="config" class="form-control" placeholder="Select date" name="date">
            </flat-pickr>
                        </div>
                        </div>
                        <div class="card-block" :id = "'card_block_2'+unq_id_gen">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
        </div>

         <div>
        <div v-show="false">
            <button type="button" @click="scanImage();" class="btn btn-primary btn-lg"> Documents</button>
            <button type="button" @click="showModalDemo" class="btn btn-primary btn-lg"> Demo</button>
            <br/>
        </div>
        <canvas style="display: none" :id="'the-canvas'+unq_id_gen"></canvas>


        <div class="modal" role="dialog" :id="'scan_modal'+unq_id_gen">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="padding:0 !important">
                        <div style="width: 100%; overflow: hidden;">
                            <div style="width: 60px; float: left;">
                                    <button class="print-button"><span class="print-icon"></span></button>
                            </div>
                            <div style="margin-left: 60px;padding:20px 0px">
                                <h4 style="text-transform:uppercase;">{{doc_desc}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-5">
                            <div :id="'selectedFiles'+unq_id_gen" class="row Content">
                                <div v-for="(scanImage,index) in scannedImages" class="col-sm-5 col-md-5 text-center snip0013">
                                    <img height="100%" width="100%" :src="scanImage.image_base_64" :data-file="scanImage.data_file" class='selFile' title='Click to remove'>
                                    <br/>{{scanImage.scan_no}}
                                    <div>
                                        <a href='#' @click.prevent="delScanDoc(index)">
                                            <i class='fa fa-trash left-icon'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div :id="'iframeContainer'+unq_id_gen">
                                <iframe v-show="scan_prev_show" src="" 
                                :id="'pdf_prev_iframe'+unq_id_gen"></iframe>
                            </div>
                        </div>

                    </div>
       <div class="modal-footer">
                   <div>
                        <button v-if="loadDemo" type="button" @click="loadDemoScan" class="btn btn-info">Scan Demo Scan</button>
                        <button type="button" @click="scanImage()" class="btn btn-primary">Scan Documents</button>
                        <button :disabled="enable_att_doc" :id="'attachFile'+unq_id_gen" type="button" @click = "attach_document" class="btn btn-success">Attach Scanned File</button> 
                        <button type="button" @click="modalClose" class="btn">Cancel</button> 
                   </div>                  
                </div>      
                </div>

            </div>

        </div>
    </div>

    </div>
</template>

<script>
import dropzone from './dropzone.vue';
import scanner from './scanner.vue';
import vx_field_set from './fieldset.vue';
import range_pick from './daterange_picker.vue';
import vx_select from './vx_select.vue';
import flatPickr from 'vue-flatpickr-component';
const uuidv1 = require('uuid/v1');

export default {
    props : {
        duration : {
            default : null
        },
        other_type : {
            default: false
        },
        update_obj : {
            default : function(){
                return {}
            }
        },
        required_fields : {
            type : Array
        },
        enable_cert_field : {
            default : true,
        },
        enable_exp_date : {
            default : false
        },
        req_doc_id : {
            required : true
        },
        other_reqdoc_id : {
            required : false
        },
        accepted_files : {

        },
        countries : {
            required : true
        },
        is_other_type : {
            default : "false"
        },
        open : {
            type : Boolean
        },
        scan_enable : {
            required : true,
            type : Boolean,
            default : false
        },
        send_on_no_file : {
            required: true
        },
        upload_enable : {
            required : true,
            type : Boolean,
            default : false
        },
        doc_desc : {
            required : true,
            type : String
        },
        unq_id_gen : {
            required : true
        },
        upload_name : {
            // required : true,
            type : String
        },
        upload_btn_id : {
            required : true,
            type : String
        }
    },
    components : {dropzone,vx_field_set,scanner,range_pick,vx_select,flatPickr},
    data(){
        return {
            date: moment(new Date()).add(1,'days').format("YYYY-MM-DD HH:mm:ss"),
            config: {
                wrap: true,
                altFormat: 'Y/m/d',
                altInput: true,
                dateFormat: 'Y/m/d',
                allowInput: true,
            },  
            set_visibility : 'hide',
            document_id : null,
            scanned_files : [],
            expiry_date : [],
            attached_files : [],
            scan_doc_dataUrl : "",
            file_not_class : false,
            scanned_1st_page : null,
            show_range_date : true,
            show_sing_date : false,
            issue_exp : null,
            skip_upload : false,
            scan_complete : false,
                temp_scan_file : null,
                file_size : null,
                file_blob : null,
                number_of_pages : null,
                selDiv : "",
                storedFiles : [],
                merged_file : [],
                scannedImages : [],
                scannerDoc : [],
                scan_prev_show : true,
                loadDemo : false,
            flag_state : null,
            cert_no : null,
            cert_inst : null,
            file_att_status : false,
            rmv_scanned_uuids : null,
        }
    },
    computed : {
        enable_att_doc(){
            var result = false
            if(this.scan_complete == false){
                result = true
            }
            if(this.file_blob == null){
                result = true
            }
            if(this.file_size == null){
                result = true
            }
            if(this.temp_scan_file == null){
                result = true
            }
            return result
        },
        over_single_date(){
            if(this.over_show_range == false){
                return true
            }
            return this.show_sing_date
        },
        over_show_range(){
            if(this.duration != null){
                return false
            }
            return this.show_range_date
            
        },
        doc_upload_verifed(){
                  if (this.skip_upload == true) {
        return true;
      }

      if (
        !this.cert_inst ||
        !this.cert_no ||
        !this.flag_state ||
        !this.issue_exp
      ) {
        return false;
      } else {
        return true;
      }
        },
        req_not_passed(){
                        
               if(this.required_fields){
                    var error_fields = false;
                this.required_fields.forEach(fld => {
                    if(this[fld] == "" || this[fld] == null){
                        error_fields = true
                    }
                    if(Array.isArray(this[fld])){
                        if(this[fld].length < 1){
                            error_fields = true
                        }
                    }
                });
                return error_fields;
               }else{
                   return false
               }
            
        }
    },
        mounted(){
            const self = this;
                $('#scan_modal'+this.unq_id_gen).on('hidden.bs.modal', function () {
        self.resetScanData();  
    });
            if(this.update_obj.hasOwnProperty("document_id")){

                // PDF Documents Setup
                if(this.update_obj.type != "PROFILE_PIC"){
                    if(this.update_obj.id != ""){
                        // document id is not empty -> set as values
                        
                        this.flag_state = this.update_obj.flag_state;
                        this.cert_no = this.update_obj.cert_no;
                        this.cert_inst = this.update_obj.cert_inst;
                        
                        // Set range date
                        if(this.update_obj.duration == null && this.update_obj.expiry_date != ""){
                            // Update range select
                            if(this.update_obj.issue_on_cert != null && this.update_obj.expiry_date!= null){
                                this.issue_exp = moment(this.update_obj.issue_on_cert).format('MM/DD/YYYY') + " - " + moment(this.update_obj.expiry_date).format('MM/DD/YYYY');
                            }
                        }
                        if(this.update_obj.duration == null && this.update_obj.expiry_date == ""){
                            // N/A use single select update**
                            if(his.update_obj.issue_on_cert != null){
                                this.issue_exp = moment(this.update_obj.issue_on_cert).format('Y/M/D')
                            }
                        }
                        if(this.update_obj.duration != null && this.update_obj.expiry_date == ""){
                            // N/A use single select update**
                            if(this.update_obj.issue_on_cert != null){
                                this.issue_exp = moment(this.update_obj.issue_on_cert).format('Y/M/D')
                            }
                        }
                        if(this.update_obj.duration != null && this.update_obj.expiry_date != ""){
                            // N/A use single select update**
                            if(this.update_obj.issue_on_cert != null){
                                this.issue_exp = moment(this.update_obj.issue_on_cert).format('Y/M/D')
                            }
                        }
                        if(!this.update_obj.duration && !this.update_obj.expiry_date && this.update_obj.issue_on_cert){
                            // N/A use single select update**
                            if(this.update_obj.issue_on_cert){
                                this.issue_exp = moment(this.update_obj.issue_on_cert).format('Y/M/D')
                                this.show_range_date = false;
                                this.show_sing_date = true;
                                $('#test2'+this.unq_id_gen).prop('checked',true);
                            }
                        }
                        if(!this.update_obj.duration && this.update_obj.expiry_date && this.update_obj.issue_on_cert){
                            this.issue_exp = moment(this.update_obj.issue_on_cert).format('MM/DD/YYYY') + " - " + moment(this.update_obj.expiry_date).format('MM/DD/YYYY');
                        }
                    }else{
                        // document id is empty -> set as skipped
                        this.skip_upload = true;
                        $("#upload_skip"+this.unq_id_gen).addClass('active')
                    }

                }
            }
            const com_this = this;
            document.addEventListener("gather_files",function(e){
                if(com_this.skip_upload == false){
                    com_this.emitAndAlert()
                }
            });
            document.addEventListener("send_to_"+com_this.unq_id_gen,function(e){
                com_this.scannedImages = e.document
                com_this.scan_complete = e.scan_complete
            })
        },
    methods : {
        set_visibility_hide(){
            this.set_visibility = "hide"
        },
        pdfgen(){
        var com_this = this;
      var pdfMake = require('pdfmake/build/pdfmake.js')

            if(pdfMake.vfs == undefined){
                var pdfFonts = require('pdfmake/build/vfs_fonts.js')
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
            }
            
            var docDefinition = { 
                content: []
            };
            

            this.scannedImages.forEach(function(element) {
                 docDefinition.content.push({
                    image : element.image_base_64,
                    width : 500
                });
            });


            // pdfMake.createPdf(docDefinition).download('optionalName.pdf');

            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            const pdfDocGeneratorUrl = pdfMake.createPdf(docDefinition);

            pdfDocGenerator.getBase64((data) => {
                var pdf_file = "data:application/pdf;base64,"+data;
                this.merged_file = pdf_file;
            });

            const pdfDocGeneratorBlob = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
                com_this.scanned_1st_page = com_this.getFirstPage(blob);
                com_this.file_size = com_this.file_size + blob.size;
            });


            pdfDocGenerator.getDataUrl((dataUrl) => { 
                const pdf_prev_iframe = document.querySelector('#pdf_prev_iframe'+this.unq_id_gen);
                pdf_prev_iframe.src = dataUrl;
                com_this.scan_doc_dataUrl = dataUrl;
                com_this.file_blob = com_this.dataURLtoFile(dataUrl,"vx_document.pdf")
            });

            
        
    },
    getFirstPage(pdf){
       if (true) {
        const com_this = this;
        var fileReader = new FileReader();
        fileReader.onload = function(ev) {
        //   console.log(ev);
          pdfjsLib.getDocument(fileReader.result).then(function getPdfHelloWorld(pdf) {
            //
            // Fetch the first page
            //
            // console.log(pdf._pdfInfo.numPages);
            pdf.getPage(1).then(function getPageHelloWorld(page) {
              var scale = 1.5;
              var viewport = page.getViewport(scale);
              //
              // Prepare canvas using PDF page dimensions
              //
              var canvas = document.getElementById('the-canvas'+com_this.unq_id_gen);
              var context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              
              
            //   Render PDF page into canvas context
              
              var task = page.render({canvasContext: context, viewport: viewport})
              task.promise.then(function(){
                com_this.temp_scan_file = canvas.toDataURL('image/jpeg');
                com_this.number_of_pages = pdf._pdfInfo.numPages;
              });
            });
            
          }, function(error){
            // console.log(error);
          });
        };
        fileReader.readAsArrayBuffer(pdf);
      }

    },
        update_scan_vis(){
            this.temp_scan_file = null;
            this.file_size = null;
            this.file_blob = null;
             this.$emit('scan_document',this.unq_id_gen)
            $('#scan_modal'+this.unq_id_gen).modal('show');
        },
        date_input_changed(val){
            this.issue_exp = val
            
        },
        toggle_iss_date(){
            this.issue_exp = null;
            this.show_range_date = !this.show_range_date;
            this.show_sing_date = !this.show_sing_date;
        },
         scanImage() {
            window['ws'].send("1100") 
        // var result = new Promise((resolve) =>)
        //  result.then((value) => {
        //         console.log(value)
        //     })
    },
    sendFile(){
        this.$emit('scanned_file',
             {
                scanned_pdf : this.merged_file,
                scanned_1st_page : this.temp_scan_file,
                file_size : this.file_size,
                num_pages : this.number_of_pages,
                blob_file : this.file_blob,
                is_dummy : false,
                uuid : uuidv1()
             });
             $('#scan_modal'+this.unq_id_gen).modal('hide');
        // console.log("Scanned file emited");
    },
    modalClose(){
        $('#scan_modal'+this.unq_id_gen).modal('hide');
        this.resetScanData();
    },
    delScanDoc(index){
        this.scannedImages.splice(index, 1);
        this.pdfgen();
    },
     dataURLtoFile(dataurl, filename) {
            var ext = this.base64MimeType(dataurl);
            ext = ext.split('/')[1];
            ext = ext.split(';')[0];

            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], 'filename.'+ext, {type:mime});
        },
 base64MimeType(encoded) {
            var result = null;

            if (typeof encoded !== 'string') {
                return result;
            }

            var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

            if (mime && mime.length) {
                result = mime[1];
            }

        return result;
        },
    attach_document(){
        this.scanned_files = []
        this.scanned_files.push({
            file_name: this.doc_desc,
            blob_file : this.file_blob,
            file_size : this.file_size,
            scanned_1st_page : this.temp_scan_file,
            com_id : this.unq_id_gen,
            id_dummy : "false",
            uuid : uuidv1()
         })
         $('#scan_modal'+this.unq_id_gen).modal('hide');
    },
    resetScanData(){
        this.storedFiles = [];
        this.merged_file = [];
        this.temp_scan_file = null;
        this.file_size = null;
        this.file_blob = null;
        this.scannedImages = [];
        this.scan_prev_show = true;
        this.scan_complete = false;
    },
    showModalDemo(){
        $('#scan_modal'+this.unq_id_gen).modal('show');
        this.scannedImages = this.scannedImages_demo;
        this.scan_complete = true;        
    },
    loadDemoScan(){
        // this.scannedImages = this.scannedImages_demo;
        this.scan_complete = true
    },
        emitAndAlert () {
            const com_this = this;

            this.$emit('upload_verification',{
                id : this.req_doc_id,
                attached : false
            }); 

                 if (com_this.doc_upload_verifed && com_this.skip_upload == false) {
        let docInfor = [
          {
            req_doc_id: com_this.req_doc_id,
            doc_type: com_this.doc_desc,
            issue_exp: com_this.issue_exp,
            other_type: com_this.other_type,
            is_other_type: com_this.is_other_type,
            flag_state: com_this.flag_state,
            cert_no: com_this.cert_no,
            cert_inst: com_this.cert_inst,
            document_id: com_this.update_obj["document_id"],
          },
        ];

        var result = new Promise((resolve) =>
          this.$emit("attach_sea_docs", docInfor, resolve)
        );

        result.then((value) => {
          if (value == "completed") {
            com_this.$emit("upload_verification", {
              id: com_this.req_doc_id,
              attached: true,
            });
          }
        });
      }

        },
        toggle_skip(element){
            this.skip_upload = !this.skip_upload
            if(element.classList.contains('active')){
                element.classList.remove('active')
            }else{
                element.classList.add('active');
            }
            if(this.other_type){
                this.$emit('update_track_list',{
                    id : this.other_reqdoc_id,
                    attached : this.doc_upload_verifed
                });
                }else{
                    this.$emit('update_track_list',{
                        id : this.req_doc_id,
                        attached : this.doc_upload_verifed
                    });
                }
        },
        file_attached(file_doc){
            this.scanned_files = []
            this.scanned_files.push(file_doc)
        },
        file_attached_status(status){
            this.file_att_status = status;
        },
        remove_scanned_file(uuid){
            for (var file of this.scanned_files){
                if(file.uuid == uuid){
                    this.scanned_files.splice(this.scanned_files.indexOf(file),1);
                }
            }
        },
        receivedScannedFiles(scanned_file){
            this.scanned_files = []
            this.scanned_files.push(scanned_file);
        },
        update_file_count(file){
            this.file_not_class = false
            this.$emit("files_count",file);
        },
        remove_file_count(count){
            this.$emit("files_remove",count);
        },
        track_uploads(uploaded_file){
            this.$emit('track_uploads',uploaded_file);
        },
        update_expiry(expiry_range){
            this.expiry_date = expiry_range
        },
        file_not_attached(file){
            this.file_not_class = true;
            this.$emit('file_not_attached',file);

        }
    },
    watch : {
     scannedImages : {
          handler : function(newValue){
              this.pdfgen();
          },
          deep : true
      },
            scan_visibility : {
          handler : function (scan_visibility) {
              $('#scan_modal'+this.unq_id_gen).modal(scan_visibility);
          }
      },
        doc_upload_verifed : {
            handler(){
                if(this.other_type){
                this.$emit('update_track_list',{
                    id : this.other_reqdoc_id,
                    attached : this.doc_upload_verifed
                });
                }else{
                    this.$emit('update_track_list',{
                        id : this.req_doc_id,
                        attached : this.doc_upload_verifed
                    });
                }
                if(this.other_type){
                this.$emit('upload_verification',{
                    id : this.other_reqdoc_id,
                    attached : this.doc_upload_verifed
                });
                }else{
                    this.$emit('upload_verification',{
                        id : this.req_doc_id,
                        attached : this.doc_upload_verifed
                    });
                }
            },
            deep : true
        }
    }
}
</script>

<style scoped>
.btn.btn-xs{
    outline: none !important;
}
.btn.btn-xs.active{
    background: orange
}
.fas{
    color:#195C9D;
    font-size:3.5em;
    margin-bottom: 5px
}
.select2-selection .select2-selection--single{
    width: 90% !important
}
.btn:focus, .btn.focus {
    /* outline: 0; */
    box-shadow: none !important;
}
.files_button{
    /* border:1px solid red;
    margin: -40px 0px 0px 30px !important; */
}
.files_button.date_range input[type="text"]{
    margin-top:5px;
    margin-left:1px;
    width:90%;
    font-size:11px
}

.not_attached{
    border:1px solid crimson !important;
    margin-bottom:5px!important;
    border-radius: 10px !important
}
.cert_no_input{
    width: 95%;
    margin: 0px 5px 0px 5px;
}
.scan_upload_label{
    font-size:12px; 
    padding-left:5px
}

    #iframeContainer, #iframeContainer > iframe , #iframeContainer *, iframe{
        width : 100% !important;
        height : 500px !important
    }
.FixedHeightContainer
{
  height: 500px;
}
.Content
{
  height:500px;
   overflow:auto;
    background:#fff;
}
.snip0013 {
  text-align: center;
  border: 1px solid black;
  margin: 10px;
  width : 100%;
  height: 40%;
  padding :15px !important
}
.snip0013 img {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
.snip0013 > div {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 80%;
  position: absolute;
}
.snip0013 > div a {
  color: #ffffff;
}
.snip0013 > div a i {
  font-size: 15px;
  opacity: 0;
  top: 50%;
  color : black;
  position: relative;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  display: inline-block;
}
.snip0013 > div a i.left-icon {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.snip0013 > div a i.right-icon {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.snip0013 > div::before {
  position: absolute;
  top: 30px;
  right: 50%;
  bottom: 30px;
  left: 50%;
  border-left: 1px solid rgba(255, 255, 255, 0.8);
  border-right: 1px solid rgba(255, 255, 255, 0.8);
  content: '';
  opacity: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.snip0013:hover img {
  opacity: 0.8;
}
.snip0013:hover > div i {
    color : #CC3300;
  opacity: 0.9;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.snip0013 div i{
    background-color: #333;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin: auto 3px;
    width: 30px;
    font-size: 24px;
    text-align: center;
    font-size : whitesmoke !important
}
.snip0013:hover > div i.left-icon {
  -webkit-transform: translate3d(-25%, -50%, 0);
  transform: translate3d(-25%, -50%, 0);
}
.snip0013:hover > div i.right-icon {
  -webkit-transform: translate3d(25%, -50%, 0);
  transform: translate3d(25%, -50%, 0);
}
.snip0013:hover > div::before {
  background: rgba(255, 255, 255, 0);
  left: 30px;
  right: 30px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}



button.print-button {
  width: 60px;
  height: 60px;
}
span.print-icon, span.print-icon::before, span.print-icon::after, button.print-button:hover .print-icon::after {
  border: solid 4px #333;
}
span.print-icon::after {
  border-width: 2px;
}

button.print-button {
  position: relative;
  padding: 0;
  border: 0;
  
  border: none;
  background: transparent;
}

span.print-icon, span.print-icon::before, span.print-icon::after, button.print-button:hover .print-icon::after {
  box-sizing: border-box;
  background-color: #fff;
}

span.print-icon {
  position: relative;
  display: inline-block;  
  padding: 0;
  margin-top: 20%;

  width: 60%;
  height: 35%;
  background: #fff;
  border-radius: 20% 20% 0 0;
}

span.print-icon::before {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 12%;
  right: 12%;
  height: 110%;

  transition: height .2s .15s;
}

span.print-icon::after {
  content: " ";
  position: absolute;
  top: 55%;
  left: 12%;
  right: 12%;
  height: 0%;
  background: #fff;
  background-repeat: no-repeat;
  background-size: 70% 90%;
  background-position: center;
  background-image: linear-gradient(
    to top,
    #fff 0, #fff 14%,
    #333 14%, #333 28%,
    #fff 28%, #fff 42%,
    #333 42%, #333 56%,
    #fff 56%, #fff 70%,
    #333 70%, #333 84%,
    #fff 84%, #fff 100%
  );

  transition: height .2s, border-width 0s .2s, width 0s .2s;
}

button.print-button:hover {
  cursor: pointer;
}

@keyframes animate_printer {
  from {
      height:0px;
      transition: height .2s;
  }

  to {
    height:120%;
    transition: height .2s .15s, border-width 0s .16s;
  }
}

button.print-button:hover .print-icon::before {
  height:0px;
  transition: height .2s;
}
button.print-button:hover .print-icon::after {
  height:120%;
  transition: height .2s .15s, border-width 0s .16s;
}

</style>

