var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "pagination",
      staticStyle: { margin: "0" },
      attrs: { name: "Pagination" }
    },
    [
      !_vm.isFirstPage
        ? _c(
            "li",
            {
              staticClass: "page-item",
              on: {
                click: function($event) {
                  return _vm.turnPage(-1)
                }
              }
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-arrow-left" })]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.dspBtns, function(i) {
        return _c(
          "li",
          { class: ["page-item", { active: i === _vm.curPage }] },
          [
            i
              ? _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.handleClick(i)
                      }
                    }
                  },
                  [_vm._v("\n      " + _vm._s(i) + "\n    ")]
                )
              : _c("a", { staticClass: "page-link" }, [
                  _c("i", { staticClass: "fa fa-ellipsis-h" })
                ])
          ]
        )
      }),
      _vm._v(" "),
      !_vm.isLastPage
        ? _c(
            "li",
            {
              staticClass: "page-item",
              on: {
                click: function($event) {
                  return _vm.turnPage(1)
                }
              }
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-arrow-right" })]
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }