<template>
<p class="" style="margin:0">
  <input class="vx_input_2" :title="dis_el ? 'No access to this record' : 'You have access to this record'" type="checkbox" :disabled = "dis_el" v-model="status" @change="toggle" style="margin: 0; vertical-align: middle" name="MultiSelect" :id="uuid">
  <label :for="uuid"></label>
</p>
</template>
<script>
import replaceWith from '../_utils/replaceWith'
const uuidv1 = require('uuid/v1');

/**
 * checkbox for multiple select within the leading <th>/<td>
 */
export default {
  name: 'MultiSelect',
  props: {
    dis_el : {
      default : false
    },
    selection: { type: Array, required: true },
    row: Object, // available for tbody checkbox
    rows: Array // available for thead checkbox
  },
  data: () => ({
    status: false,
    uuid: uuidv1()
  }),
  computed: {
    pos () {
      const { selection, row } = this
      if (!selection || !row) return
      return selection.indexOf(row)
    }
  },
  methods: {
    toggle () {
      const { selection, row, rows, status, pos } = this
      if (rows) {
        var filter_list = this.rows.filter(sel => {
              return sel.has_auth == true
        })
        replaceWith(selection, status ? filter_list : [])
        return
      }
      if (row) {
        if (status && pos === -1) selection.push(row)
        if (!status && pos >= 0) selection.splice(pos, 1)
      }
    }
  },
  watch: {
    rows () {
      replaceWith(this.selection, [])
    },
    selection (selection) {
      if (this.row) {
        this.status = this.pos >= 0
        return
      }
      if (this.rows) {
        // not only have same length but also non-zero
        this.status = this.rows.length === selection.length && selection.length
      }
    }
  }
}
</script>


<style scoped>
[type="checkbox"].vx_input_2:checked,
[type="checkbox"].vx_input_2:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="checkbox"].vx_input_2:checked + label,
[type="checkbox"].vx_input_2:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="checkbox"].vx_input_2:checked + label:before,
[type="checkbox"].vx_input_2:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    background: #fff;
}

[type="checkbox"].vx_input_2:checked + label:after,
[type="checkbox"].vx_input_2:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #00a69c;
    position: absolute;
    top: 6px;
    left: 6px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="checkbox"].vx_input_2:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="checkbox"].vx_input_2:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
th p{
  margin-bottom: 15px !important
}

</style>
