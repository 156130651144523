var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btn-group", attrs: { name: "HeaderSettings" } },
    [
      _c(
        "button",
        {
          ref: "dropdownBtn",
          staticClass: "btn btn-default dropdown-toggle",
          attrs: { type: "button" }
        },
        [
          _c("i", {
            staticClass: "fa",
            class: [_vm.usingBak && "text-info", _vm.processingCls || "fa-cog"]
          }),
          _vm._v(" "),
          _c("span", { staticClass: "caret" })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown-menu clearfix", style: _vm.drpMenuStyle },
        [
          _c(
            "div",
            { staticClass: "-col-group-container" },
            _vm._l(_vm.colGroups, function(columns, groupName) {
              return _c("column-group", {
                key: groupName,
                ref: "colGroups",
                refInFor: true,
                attrs: { "group-name": groupName, columns: columns }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "clearfix", staticStyle: { margin: "10px 0" } },
            [
              _c(
                "div",
                { staticClass: "btn-group btn-group-sm pull-right" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.apply()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$i18nForDatatable("Apply")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.supportBackup
                    ? [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("ul", { staticClass: "dropdown-menu" }, [
                          _c(
                            "li",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.apply(true)
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-floppy-o" }),
                                  _vm._v(
                                    " \n                " +
                                      _vm._s(
                                        _vm.$i18nForDatatable(
                                          "Apply and backup settings to local"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.usingBak
                            ? _c(
                                "li",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.rmBackup()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-trash-o text-danger"
                                      }),
                                      _vm._v(
                                        " \n                " +
                                          _vm._s(
                                            _vm.$i18nForDatatable(
                                              "Clear local settings backup and restore"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _vm.usingBak
            ? _c(
                "small",
                {
                  staticClass: "pull-left text-muted",
                  staticStyle: { "margin-top": "-8px" }
                },
                [
                  _vm._v(
                    "\n      ( " +
                      _vm._s(_vm.$i18nForDatatable("Using local settings")) +
                      " )\n    "
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-default dropdown-toggle",
        staticStyle: { "box-shadow": "none" },
        attrs: { "data-toggle": "dropdown", type: "button" }
      },
      [_c("span", { staticClass: "caret" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }