<template>
    
</template>

<script>

import vx_datatable from '../../utils/vx_datatable.vue';
import alert_mixins from '../../mixins/alertify.jsx';

export default {
    components : {vx_datatable},
    mixins : [alert_mixins],
    data(){
        return {
            seafarer_cols : [
                {title: 'ID', field: 'id',sortable: true,visible: 'true',thComp: 'FilterTh'},
                {title: 'Firstname', field: 'pd_firstname', sortable: true,visible : 'true',thComp: 'FilterTh'},
                {title: 'Surname', field: 'pd_lastname', sortable: true,thComp: 'FilterTh'},
                {title: 'Othername', field: 'pd_othername', sortable: true,thComp: 'FilterTh'},
                {title: 'Nationality', field : 'nationality',sortable : true,thComp: 'FilterTh'},
                {title: 'Embarkation', field : 'embarked_status',sortable : true,thComp: 'FilterTh'},
                {title: 'Gender', field : 'gender',sortable : true,thComp: 'FilterTh'},
                {title: 'Status', field : 'reg_status', explain: 'Favorite color', tdComp: 'Color','value' : 'red' },
                {title: 'Seafarer ID', field: 'seafarer_id',sortable: true,visible: 'true',thComp: 'FilterTh'},                
                {title: 'Operation', tdComp: 'Opt', visible: 'true' },
            ],  


            groups_def : {
                Normal: ['Firstname','Surname','Nationality','State_of_birth','Dob','Seafarer ID'],
                Sortable: ['Shoe_size','Coverall_size','Place_of_birth','Rain_coat','Boot_size','seafarer_id'],
                Extra: ['Operation','Gender','reg_status']
            },
            filter_headings : ['id','pd_firstname','pd_lastname','pd_othername','seafarer_id'],
            summary : {"uid":120,"age":52,"country":7},
            total : 120
        }
    },
    methods : {
        delete_record(id){
            alert("record "+id+" will be deleted")
        }
    },
    mounted(){
        const com_this = this;
    document.addEventListener("deleteRecord",function(e_rec){
                 alertify
            .confirm('<b class="fas fa-exclamation-circle fa-fw"></b> Warning! Deleting this records will remove all associated data with it',function(e){
                if(e){
                     axios.post('/seafarer/delete',{
                        record_id : e_rec.record_id
                    }).then(res => {
                        if(res.data.status == "SUCCESS"){
                            com_this.alert({
                                title : "Record deleted",
                                alert_type : "success",
                                alert_msg : "Record Delete",
                                msg : "Success",
                            }).set({onclose:function(){
                                location.reload()
                            }})
                        }
                    })
                }
            }).set({title : "Deleting record..."})

     });
     document.addEventListener("editRecord",function(e){
         window.location = "/seafarer/edit/"+e.record_id
     });
    document.addEventListener("viewRecord",function(e){
       window.location = "/seafarer/view/"+e.record_id
     });
    }
}
</script>

