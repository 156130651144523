<template>
    <div style="margin-left:10px">
        <div class="paper_no_shw col-md-12" style="padding:15px">
            <div class="entry_heading">
                <h5>Search seafarer</h5>
                <hr/>
            </div>
            <li class="dropdown dropdown-large">
                <a href="#" class="dropdown-toggle">Query Parameters <b class="fa fa-chevron-circle-down"></b></a>
                <button style="background:transparent;border:none; border-bottom:1px" @click.prevent="refreshOptions">Refresh Parameter <b class="fa fa-chevron-circle-down"></b></button style="background:transparent;border:none; border-bottom:1px">

                <ul class="dropdown-menu dropdown-menu-large row">
                    <li class="col-sm-4"  v-for="(value,index) in embark_options" v-if="index != 'toString'">
                        <ul>
                            <li class="dropdown-header">{{index}}</li>

                            <div v-for="(valu,inde) in embark_options[index]">
                                <div class="form-group">
                                    <input type="checkbox" @change="updateQuery(embark_options[index][inde]['field'])" v-model="options[embark_options[index][inde]['field']]" :id="'html' + embark_options[index][inde]['field']">
                                    <label :for="'html' + embark_options[index][inde]['field']">{{embark_options[index][inde]['field_name']}}</label>
                                </div>
                            </div>
                        </ul>
                    </li>
                </ul>
            </li>

            <div class="card card-body bg-light">
                <form action="/action_page.php">
                    <div class="row">
                        <div  v-for="(option,index) in options" v-if="option && check_key_exist(index)" class="col-md-3">
                            
                            <div v-if="getFieldType(index).type == 'text'" style="margin-bottom:15px">
                                <label for="email">{{getFieldName(index)}}</label>
                                <input required type="text" v-model="query_fields[index]" :placeholder="'Enter ' + getFieldName(index) " class="form-control" id="email">
                            </div>
                            <div v-if="getFieldType(index).type == 'select'" style="margin-bottom:15px">
                                <label>{{getFieldName(index)}}</label>
                                <vx_select v-model="query_fields[index]" :remote_options="true" :remote_url="getFieldType(index).url" :placeholder="getFieldType(index).placeholder">
                                </vx_select>
                            </div>
                        </div>
                    </div>
                    <div class="pull-right row" style="margin:5px 12px 0px 0px">
                        <button :disabled="enable_search" @click.prevent="search" role="button" class="btn btn-primary save_record"><i class="fas fa-search fa-fw"></i> Search</button>
                    </div>
                </form>
            </div>
        </div>
        <div id='container' v-if="false">
            <div class='cell'>
                <input type='search' v-model="query_param" :placeholder='placeholder'>
            </div>
            <div class='cell'>
                <button @click.prevent="search" class='vx-search-button'>Search</button>
            </div>
        </div>
        <datatable v-bind="$data" id="sample_id">
            <div style="margin-top:30px">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" :disabled="!selection.length" @click="show_embark_modal" class="btn btn-secondary btn-info"><i class="fas fa-anchor fa-fw"></i> Embark</button>
                    <button type="button" :disabled="!selection.length" @click="mass_delete" class="btn btn-secondary btn-warning"><i class="fas fa-trash fa-fw"></i> Delete</button>
                </div>
            </div>
        </datatable>
        <div class="modal" id="embark_modal" tabindex="-1" role="dialog" aria-labelledby="disEmbarkModal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myModalLabel">Seafarer embarkments / Back-to-back</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
                    <div class="modal-body" id="slip_content">
                        <p class="" style="margin:0 !important">
                            <input v-model="allow_bcb_status" @change="toggle_allow_bcb" class="vx_input_2" type="checkbox" id="allow_bcb_assng">
                            <label for="allow_bcb_assng">Enable back-to-back assignment</label>
                        </p>
                        <hr>
                        <div class="row" v-if="already_embark_seafs.length > 0">
                          <div class="col-md-8">
                            <h4 class="alert alert-danger"><i class="fas fa-info-circle fa-fw"></i> The following seaferars are currently embarked from the selection</h4>
                            <ul>
                              <li v-for="seaf in already_embark_seafs">{{seaf.name}}({{seaf.seafarer_id}})</li>
                            </ul>
                          </div>
                        </div>
                        <div class="row" v-if="restricted_seafs.length > 0">
                          <div class="col-md-8">
                            <h4 class="alert alert-info"><i class="fas fa-info-circle fa-fw"></i> You need access to embark the following seafarers</h4>
                            <ul>
                              <li v-for="seaf in restricted_seafs">{{seaf.name}}({{seaf.seafarer_id}})</li>
                            </ul>
                          </div>
                        </div>
                        <hr>
                          <div class="row" v-show="embark_seafs.length > 0">
                            <div class="col-md-6">
                              <label>*Select embarkation vessel</label>
                              <vx_select 
                                v-model="embark_vessel_id"
                                :remote_options="true"
                                :remote_url="'/search/get_vessels'"
                                placeholder="Vessel">
                            </vx_select>
                            </div>
                          </div>
                        <hr>
                        <div class="d-flex flex-row">
                            <div class="p-2 col-md-5" style="border: 1px solid teal; border-radius:5px">
                                <p class="" style="margin:0 !important">
                                    <input v-model="allow_date_toggle" @change="toggle_use_dates" class="vx_input_4" type="checkbox" id="allow_bcb_use_dte">
                                    <label for="allow_bcb_use_dte">Use Embarkation Start/End dates for all</label>
                                </p>
                                <div class="d-flex flex-row">
                                    <div class="p-2">
                                        <input v-model="embark_start_date" class="input false false" type="date">
                                    </div>
                                    <i style="margin-top:6px">- to -</i>
                                    <div class="p-2">
                                        <input v-model="embark_end_date" type="date" class="input false false">
                                    </div>
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="p-2 col-md-6" style="border: 1px solid teal;margin-left:26px; border-radius: 5px;">
                             <p>
                                <input v-model="allow_bcb_end" @change="allow_end_toggle" class="vx_input_4" type="checkbox" id="allow_bcb_end">
                                <label for="allow_bcb_end">Use Embarkation End date for all</label>
                             </p>
                              <div class="d-flex flex-row">
                                    <div class="p-2">
                                      Embarkation end
                                    </div>
                                    <div class="p-2">
                                        <input v-model = "bcb_end_date" type="date" class="input false false">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex flex-row">
                            <div class="p-2 col-md-5"><h4 style="font-weight:800">Seafarer Embarking <i class="fas fa-sign-in-alt"></i></h4></div>
                            <div class="p-2"></div>
                            <div class="p-2 col-md-6" style="margin-left:26px;"><h4 style="margin-left:8%; font-weight:800">Embarking seafarer's back-to-back <i class="fas fa-sign-out-alt"></i></h4></div>
                        </div>
                        <div  v-for="(seafarer,index) in embark_seafs" class="d-flex flex-row disable_me_now" style="border-bottom:1px solid whitesmoke; margin-bottom:10px">
                            <div class="p-2 col-md-5" :id="seafarer.com_date_id">
                                <input :value="seafarer.name" type="text" class="form-control">
                                <div class="d-flex flex-row">
                                    <div class="p-2">
                                        <input v-model="seafarer.embark_start_date" class="input false false" type="date">
                                    </div>  
                                    <i style="margin-top:6px">- to -</i>
                                    <div class="p-2">
                                        <input v-model="seafarer.embark_end_date" type="date" class="input false false">
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <p class="" style="margin:0 !important">
                                    <input @change="toggle_bcb(seafarer.com_id,seafarer.seafarer_id)" :checked="seafarer.required == false" class="vx_input_3" type="checkbox" :id="'test2'+index">
                                    <label style="font-size:10px" :for="'test2'+index" v-text="seafarer.required ? 'required' : 'skiped'"></label>
                                </p>
                            </div>
                            <div class="p-2 col-md-6" :id="seafarer.com_id">
                                <v_select v-model="seafarer.bcb_seafarer_id" :disable_com="seafarer.required == false" @input_change="check_id_exist(seafarer.bcb_seafarer_id,seafarer.com_id)"></v_select>
                                <div class="d-flex flex-row">
                                    <div class="p-2">
                                      Embarkation end
                                    </div>
                                    <div class="p-2">
                                        <input v-model="seafarer.bcb_end_date" type="date" class="input false false">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12" style="padding:10px 40px 0px 40px">
                                <button @click="process_embark" id="process_embark" :disabled="enable_embark_process" class="btn btn-md btn-primary pull-right">Procees Embarkation</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <loader v-if="show_loading"></loader>
    </div>
</template>

<script>
import mockData from '../_mockData'
import components from './comps'
import { setTimeout } from 'timers';
import alert_mixins from '../mixins/alertify.jsx';

export default {
  components,
  mixins : [alert_mixins],
  name: 'FriendsTable', // `name` is required as a recursive component
  props: {'seafarer_obj' : {},user:{},'row' : {},'groups_def' : {},'cols' : {},'remote_url' : {},'filter_headings' : {},
        'placeholder' : {
          default : "Search by Name"
        }
  }, // from the parent FriendsTable (if exists)
  persist : ['name','options'],
  data () {
    const amINestedComp = !!this.row
    return {
      query_fields : {},
      embark_options : {},
      enable_search : true,
      allow_date_toggle : false,
      options : {},
      bcb_end_date : "",
      allow_bcb_end : false,
      embark_start_date : "",
      embark_end_date  : "",
      already_embark_seafs : [],
      allow_bcb_status : false,
      show_search_table : false,
      query_param : "",
      embark_vessel_id : null,
      embark_seafs : [],
      sea_filter_list : [],
      supportBackup: true,
      supportNested: true,
      restricted_seafs : [],
      tblClass: 'table-bordered',
      tblStyle: 'color: #666',
      pageSizeOptions: [5, 10, 15, 20],
      columns: [],
      data: [],
      export_table : null,
      rows : [],
      total: 0,
      selection: [],
      summary: {},
      // `query` will be initialized to `{ limit: 10, offset: 0, sort: '', order: '' }` by default
      // other query conditions should be either declared explicitly in the following or set with `Vue.set / $vm.$set` manually later
      // otherwise, the new added properties would not be reactive
      query: amINestedComp ? { uid: this.row.friends } : {},
      // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
      xprops: {
        eventbus: new Vue()
      }
    }
  },
  mounted(){
    const obj = Object.create(null);

obj.toString = function () { return JSON.stringify(this); };

 this.embark_options = this.seafarer_obj.reduce(function (r, a) {
        r[a.group_name] = r[a.group_name] || [];
        r[a.group_name].push(a);
        return r;
    }, obj)

    $('li.dropdown.dropdown-large a').on('click', function (event) {
            $(this).parent().toggleClass('open');
        });

        $('body').on('click', function (e) {
            if (!$('li.dropdown.dropdown-large').is(e.target) 
                && $('li.dropdown.dropdown-large').has(e.target).length === 0 
                && $('.open').has(e.target).length === 0
            ) {
                $('li.dropdown.dropdown-large').removeClass('open');
            }
        });

        $('#rwd-table-large tbody tr').on('click',function() {
        });
    this.export_table = $("#sample_id").tableExport({
      bootstrap: true,
      position: "top"
    });
  },
  created(){
    this.columns = this.cols;
    axios.get("/search/seafarer_list")
         .then(res => {
           this.rows =res.data.data
          this.handleQueryChange();
	  this.pageSizeOptions.push(this.rows.length);
         });

        this.columns.map(col => {
          Object.keys(this.groups_def).forEach(groupName => {
            if (this.groups_def[groupName].includes(col.title)) {
              col.group = groupName;
          }
        })
      return col
    })     
  },
  watch: {
    embark_seafs : {
      handler(){
        this.embark_seafs.forEach(sel => {
          if(sel.required == true && sel.bcb_end_date != ""){
            if(this.is_assigned == false){
              $("#"+sel.com_id).removeClass('vx-input-error');
              $("#"+sel.com_id).find('.vx-field-error').remove();
            }else{
                  $("#"+sel.com_id).find('.vx-field-error').text("Seafarer already assigned")
                }
          }
          if(sel.embark_end_date != "" && sel.bcb_end_date != ""){

              if(sel.required == true){
                if(moment(sel.bcb_end_date).diff(moment(sel.embark_end_date)) <= 0){
                  sel.bcb_sel_date_err = true;
                  if(!$("#"+sel.com_id).hasClass('vx-input-error')){
                    $("#"+sel.com_id).addClass('vx-input-error');
                  }
                  if($("#"+sel.com_id).find('.vx-field-error').length == 0){
                    var txt1 = $("<p class='vx-field-error'></p>").text("Back-to-back disembarkation date less/equal seafarer's disembarkation date"); 
                    $("#"+sel.com_id).append(txt1);
                  }
                  if($("#"+sel.com_id).find('.vx-field-error').length > 0){
                    $("#"+sel.com_id).find('.vx-field-error').text("Back-to-back disembarkation date less/equal seafarer's disembarkation date")
                  }
              }else{
                if(this.is_assigned == false){
                  sel.bcb_sel_date_err = false;
                  $("#"+sel.com_id).removeClass('vx-input-error');
                  $("#"+sel.com_id).find('.vx-field-error').remove();
                }else{
                  $("#"+sel.com_id).find('.vx-field-error').text("Seafarer already assigned")
                }
              }
              }
          }
          if(sel.embark_start_date != "" && sel.embark_end_date != ""){
                              $("#"+sel.com_date_id).removeClass('vx-input-error');
                  $("#"+sel.com_date_id).find('.vx-field-error').remove();
              
                var check = moment(sel.embark_end_date).diff(moment(sel.embark_start_date))
              if(moment(sel.embark_end_date).diff(moment(sel.embark_start_date)) <= 0){
                  sel.sel_date_err = true;
                  if(!$("#"+sel.com_date_id).hasClass('vx-input-error')){
                    $("#"+sel.com_date_id).addClass('vx-input-error');
                  }
                  if($("#"+sel.com_date_id).find('.vx-field-error').length == 0){
                    var txt1 = $("<p class='vx-field-error'></p>").text("Start Date greater/equal to End Date"); 
                    $("#"+sel.com_date_id).append(txt1);
                  }
                  if($("#"+sel.com_date_id).find('.vx-field-error').length > 0){
                    $("#"+sel.com_date_id).find('.vx-field-error').text("Start Date greater/equal to End Date")
                  }
              }else{
                sel.sel_date_err = false;
                $("#"+sel.com_date_id).removeClass('vx-input-error');
                $("#"+sel.com_date_id).find('.vx-field-error').remove();
              }
              }
          
        })
      },
      deep: true
    },
    query_fields : {
      handler : function(options) {
        this.updateQueryFields()
      },
      deep : true
    },
    embark_start_date : {
      handler(){
        if(this.allow_date_toggle == true){
          this.embark_seafs.forEach(sel => {
            sel.embark_start_date = this.embark_start_date
          })
        }
      }
    },
    bcb_end_date : {
      handler(){
       if(this.allow_bcb_end == true){
          this.embark_seafs.forEach(sel => {
            sel.bcb_end_date = this.bcb_end_date
          })
        }
      }
    },
    embark_end_date : {
      handler(){
      if(this.allow_date_toggle == true){
          this.embark_seafs.forEach(sel => {
            sel.embark_end_date = this.embark_end_date
          })
        }
      }
    },
    query: {
      handler () {
        this.handleQueryChange()
      },
      deep: true
    },
    count_rows : {
      handler(){
      }
    }
  },
  methods: {
    allow_end_toggle(){
      if(this.allow_bcb_end == true){
        this.embark_seafs.forEach(sel => {
          sel.bcb_end_date = this.bcb_end_date
        })
      }
    },
    check_date_selected(){
      var result = false;
      this.embark_seafs.forEach(sel => {
            if(sel.embark_end_date == "" || sel.embark_start_date == ""){
                result = true
                sel.date_range_selected = false;
                if(!$("#"+sel.com_date_id).hasClass('vx-input-error')){
                  $("#"+sel.com_date_id).addClass('vx-input-error');
                }
                if($("#"+sel.com_date_id).find('.vx-field-error').length == 0){
                  var txt1 = $("<p class='vx-field-error'></p>").text("Enter end / start date"); 
                  $("#"+sel.com_date_id).append(txt1);
                }
                if($("#"+sel.com_date_id).find('.vx-field-error').length > 0){
                    $("#"+sel.com_date_id).find('.vx-field-error').text("Enter end / start date")
                }
            }
            if(sel.required == true && sel.bcb_end_date == ""){
                result = true
                sel.date_range_selected = false;
                if(!$("#"+sel.com_id).hasClass('vx-input-error')){
                  $("#"+sel.com_id).addClass('vx-input-error');
                }
                if($("#"+sel.com_id).find('.vx-field-error').length == 0){
                  var txt1 = $("<p class='vx-field-error'></p>").text("Back to back disembarkation date required"); 
                  $("#"+sel.com_id).append(txt1);
                }
                if($("#"+sel.com_id).find('.vx-field-error').length > 0){
                    $("#"+sel.com_id).find('.vx-field-error').text("Back to back disembarkation date required")
                }
            }
        })
        return result;
    },
    process_embark(){
      window.process_embark_btn = Ladda.create( document.getElementById('process_embark'));
      process_embark_btn.start();
      const result = this.check_date_selected();
      if(result == true){
        process_embark_btn.stop();
        return null;
      }
      axios.post('/seafarer_embarkment/batch_process',{
        embark_options : this.embark_seafs,
        vessel_id : this.embark_vessel_id
      })
           .then(res => {
             process_embark_btn.stop();
             if(res.data.status == "SUCCESS"){
                 this.alert({
                 alert_type : "success",
                 title : "Seafarer Embarkation",
                 alert_msg : "success",
                 msg : res.data.msg
                }).set({onclose:function(){
                      window.location.reload();
                  }})
             }
              if(res.data.status == "ERROR"){
                 this.alert({
                 alert_type : "Error",
                 title : "Seafarer Embarkation",
                 alert_msg : "Error",
                 msg : res.data.msg
                })
             }
           })
           .catch(res => {
             process_embark_btn.stop();
               this.alert({
                  alert_type : "error",
                  title : "Seafarer Embarkation",
                  alert_msg : "error",
                  msg : res.data.msg
              })
           })
    },
    toggle_use_dates(){
      if(this.allow_date_toggle == true){
        this.embark_seafs.forEach(sel => {
          sel.embark_start_date = this.embark_start_date
          sel.embark_end_date = this.embark_end_date
        })
      }else{
          this.embark_seafs.forEach(sel => {
          sel.embark_start_date = ""
          sel.embark_end_date = ""
        })
      }
    },
    toggle_allow_bcb(){
      this.embark_seafs.forEach(sel => {
         sel.required = this.allow_bcb_status
      })
    },
    toggle_bcb(com_id,seaferars_id){
      if(seaferars_id == ""){
        seaferars_id = "__"
      }
      this.embark_seafs.forEach(sel => {
        if(sel.com_id == com_id){
          var check = !sel.required
          sel.required = !sel.required
          if(check == false){
            this.sea_filter_list = this.embark_seafs.filter(sel => {
              return seaferars_id == sel.seafarer_id && sel.required == true;
            })
            $("#"+sel.com_id).removeClass('vx-input-error');
            $("#"+sel.com_id).find('.vx-field-error').remove();
          }else{
            this.sea_filter_list = this.embark_seafs.filter(sel => {
              return seaferars_id == sel.seafarer_id && sel.assigned == true
            })
            
          if(sel.required == true && sel.bcb_end_date != ""){
            if(sel.assigned == false){
              $("#"+sel.com_id).removeClass('vx-input-error');
              $("#"+sel.com_id).find('.vx-field-error').remove();
            }else{
              $("#"+sel.com_id).addClass('vx-input-error');
              var txt1 = $("<p class='vx-field-error'></p>").text("Seafarer already assigned"); 
              $("#"+sel.com_id).append(txt1);
            }
          }

          if(sel.embark_end_date != "" && sel.bcb_end_date != ""){
              if(sel.required == true){
                if(moment(sel.bcb_end_date).diff(moment(sel.embark_end_date)) <= 0){
                  sel.bcb_sel_date_err = true;
                  if(!$("#"+sel.com_id).hasClass('vx-input-error')){
                    $("#"+sel.com_id).addClass('vx-input-error');
                  }
                  if($("#"+sel.com_id).find('.vx-field-error').length == 0){
                    var txt1 = $("<p class='vx-field-error'></p>").text("Back-to-back disembarkation date less/equal seafarer's disembarkation date"); 
                    $("#"+sel.com_id).append(txt1);
                  }
                  if($("#"+sel.com_id).find('.vx-field-error').length > 0){
                    $("#"+sel.com_id).find('.vx-field-error').text("Back-to-back disembarkation date less/equal seafarer's disembarkation date")
                  }
              }else{
                if(this.is_assigned == false){
                  sel.bcb_sel_date_err = false;
                  $("#"+sel.com_id).removeClass('vx-input-error');
                  $("#"+sel.com_id).find('.vx-field-error').remove();
                }else{
                  $("#"+sel.com_id).find('.vx-field-error').text("Seafarer already assigned")
                }
              }
              

              }}




          }
        }
      })
    },
        updateQuery(field){
        if(this.query_fields[field]){
          delete this.query_fields[field]
          delete this.options[field]
        }
        this.updateQueryFields()
      },
      clearErrors(){
        this.allow_date_toggle = false;
        this.bcb_end_date = "";
        this.allow_bcb_end = false;
        this.embark_start_date = "";
        this.embark_end_date = "";
        this.already_embark_seafs = [];
        this.allow_bcb_status = false;
        this.embark_vessel_id = null;
        this.sea_filter_list = [];

        var evt = $.Event('clear_v_select');
        $(window).trigger(evt);

        this.embark_seafs.forEach(sel => {
          $("#"+sel.com_id).removeClass('vx-input-error');
          $("#"+sel.com_id).find('.vx-field-error').remove();
          $("#"+sel.com_date_id).removeClass('vx-input-error');
          $("#"+sel.com_date_id).find('.vx-field-error').remove();
        })
      },
      mass_delete(){
        const com_this = this;
        let seafarer_ids = this.selection.map(a => a.seafarer_id);

               alertify
            .confirm('<b class="fas fa-exclamation-circle fa-fw"></b> Warning! Deleting these records will remove all associated data with it',function(e){
                if(e){
                     axios.post('/seafarer/delete',{
                        record_id : seafarer_ids,
                        delete_type : "BULK"
                    }).then(res => {
                        if(res.data.status == "SUCCESS"){
                            com_this.alert({
                                title : "Record deleted",
                                alert_type : "success",
                                alert_msg : "Record Delete",
                                msg : res.data.msg,
                            }).set({onclose:function(){
                                location.reload()
                            }})
                        }
                    })
                }
            }).set({title : "Deleting record..."})
      },
      show_embark_modal(){
        const com_this = this
        this.embark_seafs = []
        this.selection.forEach((sel,index) => {
          this.embark_seafs.push({
              name : sel.pd_lastname+" - "+sel.pd_othername+" - "+sel.pd_firstname+" ("+sel.seafarer_id+")",
              seafarer_id : sel.seafarer_id,
              bcb_seafarer_id : "",
              embarked_status : sel.embarked_status,
              user_id : sel.user_id,
              has_auth : sel.has_auth,
              assigned : false,
              required : false,
              embark_start_date : "",
              embark_end_date : "",
              bcb_end_date : "",
              sel_date_err : false,
              bcb_sel_date_err : false,
              com_id :'input_sel'+index,
              com_date_id : 'com_date_sel'+index,
          })
        })
        // console.log(this.selection.map(({ id }) => id));
        this.clearErrors();
        this.allow_bcb_status = false;
        this.embark_seafs.forEach(sel => {
          sel.required = this.allow_bcb_status
        })
        this.already_embark_seafs = this.embark_seafs.filter(sel => {
            return sel.embarked_status == "EMBARKED"
        })
        this.restricted_seafs = this.embark_seafs.filter(sel => {
            return sel.has_auth == false
        })
        this.embark_seafs = this.embark_seafs.filter(sel => {
            return sel.embarked_status != "EMBARKED"
        })
        $("#embark_modal").modal('show');
      },
      refreshOptions(){
        this.options = {}
        
        this.query_fields = {}
        this.show_search_table = false
        this.show_seafarer_details = false

      },
      getFieldType(name){
        var found_name = null;
          this.seafarer_obj.forEach(obj => {
            if(obj.field == name){
              found_name =  obj;
            }
          });
        return found_name
      },
      check_key_exist(name){
        var result = this.seafarer_obj.filter(obj => {
          return obj.field == name
        })
        if(result.length > 0){
          return true
        }
        return false;
      },
      getFieldName(name){
        var found_name = null;
        this.seafarer_obj.forEach(obj => {
          if(obj.field == name){
            found_name =  obj.field_name;
          }
        });
        return found_name;
      },
      updateQueryFields(){
        this.enable_search = false;
        for (var key in this.options) {
          if(this.options[key]){
            if(this.options[key] == true){
              if (!this.query_fields.hasOwnProperty(key)) {
                this.enable_search = true;
            }else{
              if(this.query_fields[key] == "" || this.query_fields[key] == null){
                  this.enable_search = true;
              }
            }
           }
          }
        }
      },
    search(){
      this.columns = this.cols;
          axios.post("/search/seafarer_list",this.query_fields)
          .then(res => {
            this.rows =res.data.data
            this.handleQueryChange();
          });
          this.handleQueryChange();

          this.columns.map(col => {
            Object.keys(this.groups_def).forEach(groupName => {
              if (this.groups_def[groupName].includes(col.title)) {
                col.group = groupName;
            }
          })
        return col
      })  
    },
    exportMe(){
    this.export_table.update() 
    },
    handleQueryChange () {
      
       mockData(this.rows,this.filter_headings,this.query).then(({ rows, total, summary }) => {
        this.data = rows
        this.total = total
        this.summary = summary
      })
    },
    alertSelectedUids () {
      alert(this.selection.map(({ id }) => id))
    },
    check_id_exist(seafarer_id,evnt){
      console.log("here with :"+seafarer_id + "---"+evnt)
      this.sea_filter_list = this.embark_seafs.filter(sel => {
        return sel.bcb_seafarer_id  == seafarer_id && sel.required == true
      })

      if(this.sea_filter_list.length == 1){
        this.embark_seafs.forEach(sel=> {
            if(sel.bcb_seafarer_id == seafarer_id){
              sel.assigned = false
            }
          });
         $("#"+evnt).removeClass('vx-input-error');
         $("#"+evnt).find('p.vx-field-error').remove();
      }
      
      if(this.sea_filter_list.length > 1){
          this.embark_seafs.forEach(sel=> {
            if(sel.bcb_seafarer_id == seafarer_id){
              sel.assigned = true
            }
          });
          if($("#"+evnt).hasClass('vx-input-error')){

          }else{
            $("#"+evnt).addClass('vx-input-error');
            var txt1 = $("<p class='vx-field-error'></p>").text("Seafarer already assigned"); 
            $("#"+evnt).append(txt1);
          }
      }
     }
  },
  computed : {
    enable_embark_process(){
      if(this.is_all_entered == false){
        return true
      }
      if(this.is_assigned == true){
        return true
      }
      if(this.sel_date_errors == false){
        return true
      }
      if(this.sel_bcb_date_errors == false){
        return true
      }
      if(this.embark_vessel_id == null){
        return true
      }
      return false;
    },
    is_all_entered(){
      var all_embark_inputs = this.embark_seafs.filter(sel => {
        return sel.bcb_seafarer_id  == "" && sel.required == true
      })
      if(all_embark_inputs.length > 0){
        return false
      }else{
        return true
      }
    },
    sel_date_errors(){
      var all_embark_inputs = this.embark_seafs.filter(sel => {
        return sel.sel_date_err  == true
      })
      if(all_embark_inputs.length > 0){
        return false
      }else{
        return true
      }
    },
      sel_bcb_date_errors(){
      var all_embark_inputs = this.embark_seafs.filter(sel => {
        return sel.bcb_sel_date_err  == true && sel.required == true
      })
      if(all_embark_inputs.length > 0){
        return false
      }else{
        return true
      }
    },
    is_assigned(){
      if(this.sea_filter_list.length > 1){
        return true
      }else{
        return false
      }
    },
    count_rows() {
        var rows = $('#sample_id table tr').length;
        return rows;
    }
  }
}
</script>
<style scoped>
.w-240 {
  width: 240px;
}
.fa-file-excel{
  color: green !important;
}
.fa-file-csv{
  color: blue !important;
}
caption {
  margin-bottom: -30px !important
}
.dropdown-large {
  position: static !important;
}
.dropdown-menu-large {
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0px;
}
.dropdown-menu-large > li > ul {
  padding: 0;
  margin: 0;
}
.dropdown-menu-large > li > ul > li {
  list-style: none;
}
.dropdown-menu-large > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}
.dropdown-menu-large > li ul > li > a:hover,
.dropdown-menu-large > li ul > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu-large .disabled > a,
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  color: #999999;
}
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.dropdown-menu-large .dropdown-header {
  color: #428bca;
  font-size: 14px;
  padding: 0 !important;
  margin-bottom: 5px !important
}
@media (max-width: 768px) {
  .dropdown-menu-large {
    margin-left: 0 ;
    margin-right: 0 ;
  }
  .dropdown-menu-large > li {
    margin-bottom: 30px;
  }
  .dropdown-menu-large > li:last-child {
    margin-bottom: 0;
  }
  .dropdown-menu-large .dropdown-header {
    padding: 3px 15px !important;
  }
}

.dropdown-toggle::after {
  display : none 
}


.new {
  padding: 50px;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  
}

label {
  font-family: Arial, Helvetica, sans-serif !important
}

.form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 6px;
    width: 5px;
    height: 12px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}
 .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 40px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    

    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
    }
    
    .btn-primary:hover {
        background-color: #566f7b;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
.vx-custom-show{
  display: none;
  position: absolute
}
/* Include CSS Normalize */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 576px){
.modal-dialog {
    max-width: 900px !important;
}
}

main {
  padding: 2em;
  float: left;
  width: 100%;
}

</style>
