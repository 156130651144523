var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c("h1", [_vm._v("Documents")]),
      _vm._v(" "),
      _vm._l(_vm.parsed_files, function(file, index) {
        return _c("div", { staticClass: "tree" }, [
          _c("div", [
            _c("input", { attrs: { id: "n-" + index, type: "checkbox" } }),
            _vm._v(" "),
            _c("label", { attrs: { for: "n-" + index } }, [
              _vm._v(_vm._s(file.doc_type))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sub" }, [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "/document/seafarer_document?doc_desc=" + file.doc_url
                  }
                },
                [_vm._v(_vm._s(file.doc_data.metadata.filename))]
              )
            ])
          ])
        ])
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "zip_file_btn",
          attrs: {
            href: "/document/seafarer_doc_zip?seafarer_id=" + _vm.seafarer_id
          }
        },
        [_vm._v("Download as ZIP")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }