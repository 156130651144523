<template>
   
</template>

<script>
import scan_upload from '../../utils/scan_upload.vue';
import vx_select from '../../utils/vx_select.vue';
import vx_field_set from '../../utils/fieldset.vue';
import form_mixins from '../../mixins/form_mix.jsx';
import alert_mixins from '../../mixins/alertify.jsx';
import vxFormData from '../../../scripts/form_data.js';
import range_pick from '../../utils/daterange_picker.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import { sync } from 'glob';

    export default {
        object_name : "seafarer",
        form_ids : ['seafarer_form'],
        components : {scan_upload,flatPickr,vx_field_set,vx_select,range_pick},
        mixins : [form_mixins,alert_mixins],
        props : {
            remote_reg_request :{

            },
             officer : {
            
            },
            doc_types : {

            },
            docs_to_upload : {
                required : true
            },
            seaf_type_id : {
                type : [Array,Number,String,Object]
            },
            setup_options : {
                type : [Object,Array]
            }
        },
        data(){
            return {
                track_doc_attached : [
                    {
                        id: 1,
                        attached: false,
                    }
                ],
                config: {
                    wrap: true,
                    altInput: true,
                    dateFormat: 'd-m-Y',
                    altFormat: 'd-m-Y',
                    allowInput: true,
                },  
                finalUploadDocs : [],
                rail_form_obj_name : "",
                files_count : 0,
                up_file_count : 0,
                file_up_complete : false,
                formData : null,
                uploaded_doc_ids : [],
                unattached_files : [],
                seaf_docs : [],
                use_next_kin_emg_check : false,
                emg_contact_fields : [],
                seaterms_fields : [],
                seafarer_refs : [],
                other_doc_id : null,
                other_doc_name : "",
                other_docs : [],
                ladda_btn_disable : false,
                toggle_show_other : false,
                toggle_show_doc_type : true,
                new_other : false
               
            }
        },
        methods : {
            attach_sea_docs(files, resolve){
                for(var file of files){
                    this.finalUploadDocs.push(file);
                }
                resolve('completed')
            },
            upload_verification(ver_obj){
                for(var obj of this.track_doc_attached){
                    if(obj.id == ver_obj.id){
                        obj.attached = ver_obj.attached
                    }
                }
            },
            addEmgContact(objct){
                this.emg_contact_fields.push(objct)
                this.seafarer.seafarer_emg_contacts_attributes.push(objct);
            },
            addSeafStream(objct){
                this.seaterms_fields.push(objct)
                this.seafarer.seaterms_attributes.push(objct);
            },
            selectOtherDocuments(){
                $('#basicModal').modal('show');
            },
            other_doc_toggle(){
                this.other_doc_name = "";
                this.other_doc.document_type = "";
                if(this.toggle_show_other == true){
                    this.toggle_show_other = false;
                    this.toggle_show_doc_type = true;
                }else{
                    this.toggle_show_other = true;
                    this.toggle_show_doc_type = false;
                }
            },
            attachOtherDoc(){
                var doc = this.doc_types.filter(doc => {
                    return doc.id == this.other_doc.document_type  
                }); 
                if(this.toggle_show_other == true){
                    this.other_docs.push({
                        doc_desc : this.other_doc_name,
                        req_doc_id : 2
                    })
                    this.track_doc_attached.push({
                        id : 2,
                        attached : false,
                    })
                }else{
                    this.other_docs.push({
                        doc_desc : doc[0]["text"],
                        req_doc_id : this.other_doc.document_type   
                    })
                    this.track_doc_attached.push({
                        id : this.other_doc.document_type,
                        attached : false,
                    })
                }     
            },
            addSeaRef(objct){
                this.seafarer_refs.push(objct)
                this.seafarer.seafarer_refs_attributes.push(objct);
            },
            addOtherDoc(){
                var prompt = this.prompt({
                    title : "Seafarer Other Documents",
                    msg : "Enter Document Name",
                    ok_type : "success",
                    cancel_type : "error",
                    default_value : "",
                    cancel_msg : "Cancelled",
                    msg_ok : "Other Document added"
                });

            },
            toggle_use_next(){
                this.use_next_kin_emg_check = !this.use_next_kin_emg_check
                if(this.use_next_kin_emg_check == false){
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'lastname',"")
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'firstname',"")
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'othername',"")
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'relation',"")
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'address',"")
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'mob',"") 
                }
                if(this.use_next_kin_emg_check == true){
                    this.use_next_kin_emg()
                }
            },
            use_next_kin_emg(){

                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'lastname',this.seafarer.nxk_lastname)
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'firstname',this.seafarer.nxk_firstname)
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'othername',this.seafarer.nxk_othername)
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'relation',this.seafarer.nxk_relationship)
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'address',this.seafarer.nxk_address)
                    this.$set(this.seafarer.seafarer_emg_contacts_attributes[0],'mob',this.seafarer.nxk_mob)
            },
            save_for_later(){
                save_btn.start()
                this.seafarer.trans_code = "SVL002";
                this.save_record_portal();
            },
            request_approval(){
                save_record_approval.start()
                this.seafarer.trans_code = "RQA001";
                this.save_record_portal();
            },
            gatherDocuments(){
                var evt = document.createEvent("Event");
                    evt.initEvent("gather_files",true,true);
              
                    console.log("Send or post documents");
                    document.dispatchEvent(evt);

            },
            save_record_docs(record_id){
                const com_this = this;
                var status = "PENDING"
                this.gatherDocuments();

             
                    var form_d = new FormData();
                    var config = {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            };
                    const total_files = this.finalUploadDocs.length;
                    var uploaded_files = 0;
                    var req_doc_ids = [];

                    const uploadDocument = (file) => {
                        return new Promise((resolve, reject) => {
                            var form_data = new FormData();
                            var config = {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            };
                            form_data.append('record_id',record_id);
                            form_data.append('file',file.blob_file);
                            form_data.append('req_doc_id',file.req_doc_id);
                            form_data.append('doc_type',file.doc_type);
                            form_data.append('issue_exp',file.issue_exp);
                            form_data.append('flag_state',file.flag_state);
                            form_data.append('other_type',file.other_type);
                            axios.post('/test/create',form_data,config)
                            .then(response => {
                                return resolve(response.data.status)
                            })
                            .catch(error => {
                                return reject(error.message)
                            })
                        })
                    }
                    var status = "PENDING"
                    const start = async() => {
                      for(var file of com_this.finalUploadDocs){
                          iziToast.info({
                                timeout: 600000,
                                close: false,
                                icon: "fas fa-spinner fa-spin",
                                animateInside : true,
                                pauseOnHover: false,
                                message: 'Uploading ' + file.doc_type,
                                position: 'bottomRight'
                            });	
                            await uploadDocument(file).then((status) => {
                                iziToast.destroy();
                            })
                        }
                        return "COMPLETED";
                    }

                    return start(function(){
                        return new Promise((resolve, reject) => {
                            return resolve(status)
                        })
                    })
               
// 
            },
            save_record_portal(){
                const com_this = this;
                this.defaultNestedAttrParams();
                this.seafarer['save_mode'] = "CREATE_RECORD";
                this.seafarer['user_id'] = this.officer.id;
                this.seafarer['remote_req_id'] = this.remote_reg_request.id;
                this.formData = new vxFormData({
                        data : this.seafarer,
                        alertify : true
                    })
                    console.log(this.formData);
                    this.formData.post('/admin/seafarer_remote/create')
                        .then(response => {
                            console.log(response);
                            if(response.status == "SUCCESS"){
                                // this.record_id = response.data.record_id;
                                if(true){
                                    com_this.save_record_docs(this.record_id = response.data.record_id)
                                            .then(res => {
                                                if(res = "COMPLETED"){
                                                    save_btn.stop();
                                                    save_record_approval.stop()
                                                    com_this.ladda_btn_disable = false
                                                    com_this.showConfirm({
                                                        title : "Seafarer Registration",
                                                        msg : "Registration sent approval"
                                                    },[
                                                        {
                                                            text: "Dismiss",
                                                            action : "redo",
                                                            id : "id1"
                                                        }  
                                                        
                                                    ]);

                                                    document.getElementById('id1').addEventListener('click', function () {
                                                        location.reload()
                                                    });
                                                }
                                            })
                                    
                                }else{
                                     save_btn.stop();
                                     save_record_approval.stop()
                                                    com_this.ladda_btn_disable = false
                                                    com_this.showConfirm({
                                                        title : "Seafarer Registration",
                                                        msg : "Registration Successful"
                                                    },[
                                                        {
                                                            text: "New Entry",
                                                            action : "redo",
                                                            id : "id1"
                                                        },   
                                                        {
                                                            text: "View CV",
                                                            action : "redo",
                                                            id : "id2"
                                                        },
                                                    ]);

                                                    document.getElementById('id1').addEventListener('click', function () {
                                                        location.reload()
                                                    });


                                                    document.getElementById('id2').addEventListener('click', function () {
                                                        window.location.replace("/seafarer/view/"+com_this.record_id);
                                                    });
                                }
                            }
                        })
                        .catch(data => {
                            save_btn.stop();
                            save_record_approval.stop()
                            this.ladda_btn_disable = false

                        });
            },
            refresh_fields(){
                // this.unattached_files = [];
            },
            defaultNestedAttrParams(){
                this.seafarer.seafarer_refs_attributes.forEach(attr => {
                    attr["trans_code"] = this.seafarer.trans_code;
                });
                this.seafarer.seafarer_emg_contacts_attributes.forEach(attr => {
                    attr["trans_code"] = this.seafarer.trans_code;
                })
            },
            update_file_count(file){
                this.file_attach(file.com_id);
                var file_count = file.count;
                this.files_count += file_count;
            },
            update_file_remove(file_rem_count){
                this.files_count -= file_rem_count;
            },
            track_uploads(uploaded_file){
                console.log(uploaded_file)

                this.up_file_count += uploaded_file.no_uploaded;
                
                this.uploaded_doc_ids.push(uploaded_file.doc_saved);
                
                if((this.files_count - this.up_file_count) == 0){
                    this.file_up_complete = true;
                }
            },
            file_not_attach(file){
                console.log(file);
                console.log("attaching...."+file.com_id);
                if(!this.unattached_files.includes(file.com_id)){
                    this.unattached_files.push(file.com_id)
                }
            },
            mapToBoot(swap){


                
            },
            file_attach(com_id){
                

                var index = this.unattached_files.indexOf(com_id);
                if (index > -1) {
                    this.unattached_files.splice(index, 1);
                }
            }
        },
        computed : {
            disabled_save_button(){
                if(this.check_if_docs_attached == true || this.seafarer.trans_code == "SVL002"){
                    return false;
                }else{
                    return true
                }
            },
            check_if_docs_attached() {
                var BreakException = {};

                var not_attached = true;
                try {
                    this.track_doc_attached.forEach(function(doc) {
                        if (doc.attached == false){
                            not_attached = false;
                            throw BreakException;
                        }else{
                            not_attached = true;
                        }
                    });
                    } catch (e) {
                    if (e !== BreakException) throw e;
                    }
                return not_attached;
            },
            enable_other_attach(){
                if(this.other_doc_name == "" && ( this.other_doc.document_type == null || this.other_doc.document_type == "")){
                    return true
                }else{
                    return false
                }
                
            },
            com_file_not_att(){
                 if((this.unattached_files.length > 0) || (this.form_validation == false) ){
                    return false;
                }else{
                    return true;
                }
            }  
        },
        watch : {
            seafarer : {
                handler(){
                    if(this.use_next_kin_emg_check){
                        this.use_next_kin_emg();
                    }
                },
                deep : true
            },
            setup_options : {
                handler : function(options) {
                    // this.seafarer.company_id = options.company_id;
                },
                deep : true
            },
            seaf_type_id : {
                handler : function(seaf_type_id){
                    this.seafarer.seafarer_type_id = seaf_type_id;
                    this.seafarer.seaf_type_id = seaf_type_id;

                }
            },
            form_validation : {
                handler : function(check,oldValue){
                    // console.log("watching form validatons");
                    // console.log("Form Validation wath");
                    // var evt = document.createEvent("Event");
                    // evt.initEvent("formValid",true,true);

                    // // custom param
                    // evt.scan_or_not = false

                    // console.log("Send or post documents");
                    // document.dispatchEvent(evt);
                    // if(check == true){
                    //     document.getElementById('save_record').click()
                    // }
                deep : true
                }
            },
            docs_to_upload : {
                handler : function(docs){
                    if(docs.length > 0){
                        this.seaf_docs = docs
                        this.seaf_docs.map(doc => {
                            this.track_doc_attached.push({
                                id : doc.req_doc_id,
                                attached : false
                            })
                        });
                        
                    }
                }
            },
            file_up_complete : {
                handler : function(check){
                    if(check == true){
                       this.save_mode = "CREATE_RECORD";
                       this.save_record_portal();
                    }
                }
            },
            pmpt_value : {
                handler : function(value){
                    this.other_docs.push({
                        doc_desc : value,
                        req_doc_id : 12,
                        date_value : ""
                    })
                }
            }
        },
        mounted(){
const com_this = this; 

                $("#seafarer_ppe_shoe_size").keyup(function(e){
                    com_this.$set(com_this.seafarer,"ppe_boot_size",com_this.seafarer.ppe_shoe_size)

                });
                  $("#seafarer_ppe_boot_size").keyup(function(e){
                    com_this.$set(com_this.seafarer,"ppe_shoe_size",com_this.seafarer.ppe_boot_size)

                });
            
        $(".form-control.naija_number").keyup(function(){
        var prefix = "+234"
    
        if(this.value.indexOf(prefix) !== 0 ){
            this.value = prefix + this.value;
        }
    });

      $(".form-control.naija_number").keypress(function (e) {
     //if the letter is not digit then display error and don't type anything
     if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
        //display error message
               return false;
    }
   });
        window.save_btn = Ladda.create( document.getElementById('save_record'));
        window.save_record_approval = Ladda.create( document.getElementById('save_record_approval'));
        
        // l.start();
            

            //  nation.on('change',function(){
            //      sob.html(options.filter('[value="' + this.value + '"]' ));
            //  })
            //  .val(this.value)
            //  .trigger('change').on('change',function(){
            //      console.log(this.value);
            //      this.vm.$emit('input',this.value);
            //  });
             
        },
        created(){
            const element = document.getElementById("rails_data");
            this.places = JSON.parse(element.getAttribute('data')).data;
            // console.log(rails_data);
        }
    }
</script>

<style scoped>
    body {
        padding: 5%;
        font-size: 16px;
        line-height: 1.6;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    
    .form-group {
        margin-bottom: 0;
        padding: 10px 0;
    }
    
    .form-group:first-child {
        border-color: transparent;
    }
    
    .form-group.has-icon .control-group {
        position: relative;
    }
    
    .form-group.has-icon .control-group .btn-search {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 16px;
        height: 16px;
        border: transparent;
        background: transparent;
    }
    
    .form-group.has-icon .control-group .btn-search > .icon {
        top: 0;
        left: 0;
    }
    
    .form-group.has-icon .control-group .icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        fill: #757575;
    }
    
    .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
        color: white !important
    }
    
    .btn-primary:hover {
        background-color: #38474f;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
    
    label {
        cursor: pointer;
    }
    
    :-ms-input-placeholder {
        color: #ccc;
    }
    
    ::-moz-placeholder {
        color: #ccc;
    }
    
    ::-webkit-input-placeholder {
        color: #ccc;
    }
    /* control search */
    
    .control-search {
        -webkit-border-radius: 40px;
        border-radius: 40px;
    }
    /* group addon */
    
    .input-group-addon {
        border-width: 2px;
    }
    .paper_no_shw{
        padding:30px !important
    }
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    display: none !important
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel{
    font-size: 10px !important
}
input[type="text"]{
    text-transform: uppercase !important
}
select.form-control:not([size]):not([multiple]) {
    height: calc(2.30rem + 6px) !important;
}

</style>