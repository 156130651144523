import FormError from './form_error.js';

class FormData {

    constructor(options){

		var data = options.data;
		this.alert_options = options.alert_options;
		this.alertify = options.alertify;

		this.originalData = data;
    	
    	for(let param in data){
    		this[param] = data[param];
    	}

    	 this.errors = new FormError();
    	 this.formSubmitted = {msg : '','status':''};
	}

	getGaurdian(){
		alert(this.batch);
	}

	alertify(){
		return this.alertify;
	}


	parentAddtoWard(){
		this.gaurdian_name = this.dad_name;
	}

	alertOptions(){
		return this.alert_options;
	}

	getAcademicYear(){
		return moment.year() + "/" + moment.add(1,"year").year();
	}

	reset() {
        for (let field in this.originalData) {
            this[field] = '';
        }

        this.errors.clear();
    }

	//Submit Form

	post(url){
		this.errors.clear();
		return new Promise((resolve,reject) => {
			this.submit("post",url)
				.then(data => {
					resolve(data);
				})
				.catch(data => {
					reject(data);
				})
		})
	}

	getFormData(){
		let data = {};
		
		for(let field in this.originalData){
			data[field] = this[field];
		}
		return data;
	}

	submit(method,url){
		return new Promise((resolve,reject) => {
			axios[method](url,this.getFormData())
			    .then(response => {
			    	if(response.data.status == "SUCCESS" || response.data.status == "PROCEED"){
						this.onSuccess(response);
						resolve(response.data);
			    	}else{
						this.onError(response);
						reject(response);
			    	}
			    })	
			    .catch(response => {
			    	//console.log(response);
			    });
		});
	}

	onSuccess(data){
		this.formSubmitted.status = data.data.status;
		this.formSubmitted.msg = data.data.msg;
	}

	onError(data){
		this.errors.loadErrors(data.data.errors);
		this.errors.setErrorMsg(data.data.msg);
		//console.log(this.errors.getErrorfields());
	}
}

export default FormData;