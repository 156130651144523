<template>
  <div class="btn-group btn-group-sm">
    <button class="btn btn-default" title="Display row"
      :class="{ '-nested-comp-open-btn': isDisplayRowVisible }"
      @click="toggleNestedComp('DisplayRow')">
      <i class="fa fa-list-ul"></i>
    </button>
    <button class="btn btn-default" title="View Record"
      :class="{ '-nested-comp-open-btn': isDisplayRowVisible }"
      @click="viewRecord()">
      <i class="fa fa-file"></i>
    </button>
    <button class="btn btn-default" title="Delete Record"
      :class="{ '-nested-comp-open-btn': isDisplayRowVisible }"
      @click="deleteRow()">
      <i class="fa fa-trash"></i>
    </button>
    <!-- <button class="btn btn-default" title="Edit Record"
      :class="{ '-nested-comp-open-btn': isDisplayRowVisible }"
      @click="editRecord()">
      <i class="fa fa-pen"></i>
    </button> -->
    <!-- <button class="btn btn-default" title="Friends"
      :class="{ '-nested-comp-open-btn': isFriendsTableVisible }"
      @click="toggleNestedComp('FriendsTable')">
      <i class="fa fa-users"></i> {{ row.friends.length }}
    </button> -->
  </div>
</template>
<script>

export default {
  props: ['row', 'nested'],
  mounted () {
    $(this.$el).find('button[title]').tooltip()
  },
  computed: {
    isDisplayRowVisible () {
      if (this.nested.comp !== 'DisplayRow') return
      return this.nested.visible
    },
    isFriendsTableVisible () {
      if (this.nested.comp !== 'FriendsTable') return
      return this.nested.visible
    }
  },
  methods: {
    toggleNestedComp (comp) {
      const { nested } = this
      if (nested.comp === comp) return nested.$toggle()
      nested.$toggle(comp, true)
    },
    deleteRow(){
      var evt = document.createEvent("Event");
      evt.initEvent("deleteRecord",true,true);
      evt.record_id = this.row.id;
      document.dispatchEvent(evt);
    },
    editRecord(){
      var evt = document.createEvent("Event");
      evt.initEvent("editRecord",true,true);
      evt.record_id = this.row.id;
      document.dispatchEvent(evt);
    },
    viewRecord(){
      var evt = document.createEvent("Event");
      evt.initEvent("viewRecord",true,true);
      evt.record_id = this.row.id;
      document.dispatchEvent(evt);
    }
  },
}
</script>
<style>
.-nested-comp-open-btn {
  color: #fff !important;
  background-color: #337ab7 !important;
}
</style>
