<template>
  <div class="AppMessage-container">
<div class="AppMessage">
    <div>
        <input id="CheckBox-1" class="details-expander" type="checkbox"/>
        <label for="CheckBox-1" class="Title">
            <p><i class="fa fa-envelope-o" aria-hidden="true" style="font-size: 23px;margin-right: 10px"></i><div class="notifications">1</div> {{request_header}}</p>
            <div class="Chevron"></div>
            <div class="col-md-8">
                <div style="float:right">
				<button href="#" id="approve_btn" @click.prevent =  "approve_request" class="btn btn-primary"><i class="fas fa-check-circle"></i> <strong>Approve</strong></button>
				<button href="#" id="revert_btn" @click.prevent =  "revert_the_req" class="btn btn-info"><i class="fas fa-edit"></i> <strong>Revert</strong></button>
				<button href="#" id="reject_btn" @click.prevent =  "request_reject" class="btn btn-danger"><i class="fas fa-trash"></i> <strong>Reject</strong></button>
				</div>
            </div>
        </label>

        <div class="Content">
            <div class="inside-content">
                <div class="content-messages">
					<div v-for = "comment in record_access.comments">
						<div class="unread"><i class="fa fa-exclamation-triangle" style="color: #d4bf13;"></i> one unread message</div>
						<div v-if = "comment.user.id != current_user.id" style="width:inherit; float:left" class="">
							<h1></h1>
							<div class="ssfcu-message">{{comment.body}}</div>
							<div class="ssfcu-author">
								<div>{{comment.created_at}}</div>
							</div>
						</div>
						<div v-if = "comment.user.id == current_user.id" style="width:inherit; float:right">
							<div class="user-message">{{comment.body}}</div>
							<div class="user-author">
								<div>{{comment.created_at}}</div>
							</div>
						</div>
					</div>
                </div>
                <div class="content-field">
                    <h5>Add a comment (*optional)</h5>
                    <textarea v-model="request_access_comment" id="par-txt" style="width: 100%;flex-grow:1"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
  </div>
</template>


<script>
import request_access_mix from '../mixins/request_access.jsx';
import alertify from '../mixins/alertify.jsx';

export default {
	props : {
		type : {

		},
		request_header : {
			default : "View more about this request"
		},
		record_access : {

		},
		approval_url : {
			default  : "/access_request/perform_action"
		},
		expected_param :{
			
		}
	},
	mixins : [request_access_mix,alertify],
	data(){
		return {
			current_user : []
		}
	},
	created(){
		this.current_user = this.$store.state;

	},
    mounted(){
        $( ".Title" ).click(function() {
    //turn off the "new notification" styling on expand bar once clicked
    $( ".unread" ).delay(3000).slideUp();
    $( ".notifications" ).delay(3000).fadeOut(800);
    setTimeout(function() {
        $(".fa-envelope-o").addClass("change-color");
        $(".Chevron").addClass("change-color");
        $(".Title").addClass("Title-revised");
        $(".AppMessage").addClass("AppMessage-revised");
    }, 3100);
});
    }
}
</script>

<style scoped>
.AppMessage-container{
	width:100%;
}
*{box-sizing:border-box}
a {cursor: pointer;}

.Title {
	position: relative;
	z-index: 599;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	transition: all 0.2s linear;
	display: flex;
	align-items: center;
	color:#325bb2;
	font-weight:bold;
	background: #ffffcc!important;

	border-bottom: 1px solid #f8f8f8;
	padding: 10px 24px;
	margin:0;
	outline-width: 0;
}
.Title:hover {
	cursor: pointer;
	background-color:#f5f5f5;
}
.Title p { 
	color: #325bb2;
	font-weight: 600; 
	margin: 0; 
	padding: 0;
}
.AppMessage{border:1px solid #bbc9e8;    box-shadow: 0 0 10px rgba(50, 91, 178, .1);}
.AppMessage input{visibility: hidden;display:none}
.AppMessage input[type="checkbox"] ~ .Content{display: none;}
.AppMessage input[type="checkbox"]:checked ~ .Content{display: flex;}
.AppMessage input[type="checkbox"]:checked + .Title {border-bottom: 1px solid rgba(200, 200, 200, 0.6);background-color: rgb(250, 250, 250)}
.AppMessage input[type="checkbox"]:checked + .Title + .Content {max-height: 200px;}
.AppMessage input[type="checkbox"]:checked + .Title + .Content p {opacity: 1;}
.Chevron{
	font-family: 'FontAwesome';
	font-size: 24px;
	line-height:1;
	height: 25px;
	width: 16px;
	transition: all .4s ease;
	margin-left: 5px
}
.Chevron::after{
	content: "\f107";
	color: #325bb2;
	text-align: center;
}

.Content{background: #fefefe;}
.notifications{
	background-color: #325bb2;
	color: white;
	margin-right: 15px;
    border-radius: 15px;
    width: 20px;
    height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 10px;
	top: 18px;
	padding-bottom: 2px;
	font-size: 8px;
}
.inside-content{display: flex !important;width: 100%; height: 200px}
.content-messages{flex-grow: 1;overflow-y: scroll;display: flex;flex-direction: column;position: relative;}
.content-field{padding:15px;width:300px;display: flex;flex-direction: column;background: #fcfcfc;border-left:1px solid #eaeaea;}
.content-field h4{
	margin-top:0;
	font-size: 16pt;
	margin-bottom: 0px;
}
textarea {
	padding: 10px;
	line-height: 1.5;
	border: 1px solid #ccc;
}
.ssfcu-message{
	max-width: 500px;
	width: 90%;
	background-color: #eee;
	padding:10px 15px;
	border-radius:12px;
	border-bottom-left-radius: 0;
	margin: 15px 15px 8px 15px;
}
.user-message{
	max-width: 500px;
	width: 90%;
	background-color: #d9e0f1;
	padding:10px 15px;
	border-radius:12px;
	border-bottom-right-radius: 0;
	align-self: flex-end;
	margin: 15px 15px 8px 15px;
}
.ssfcu-author, .user-author{
	margin: 0px 15px 15px 15px;
}
.user-author{
	align-self: flex-end;
	text-align: right;
}
.AppMessage input[type="checkbox"]:checked + .Title > .Chevron{
	transform: rotate(180deg);
	transition: all .4s ease;
}
.details-expander{
	margin: 0;
	height: 0;
	outline-width: 0;
}
.details-expander:focus{outline-width: 0;}

.icon-item{
	animation: apply-scroll 1.5s cubic-bezier(.48,.03,.55,.98) 0s infinite alternate;
	-webkit-animation: apply-scroll 1.5s cubic-bezier(.48,.03,.55,.98) 0s infinite alternate;
}
.progress-bar{
	background-color: #92CD00
}
.btn-gray{
	background-color:#dddddd
}

.Title-revised{
	background:#ffffcc !important;
	color: #333;
}
.AppMessage-revised{
	box-shadow: 0 0 10px rgba(50, 91, 178, .1);
	border: 1px solid rgba(200, 200, 200, 0.6);
}
.unread{
	text-align: center;
	padding: 7px;
	font-size: 12px;
	background: #fffde9;
	border-bottom: 1px solid #d4d898;
	width: 100%;
	text-transform: uppercase;
	margin-left: 0;
	margin-top:0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.change-color{
	color:#333 !important;
}
.change-color::after{
	color:#333 !important;
}
.wait-anim{
	width:70px;
}
.circle {
	height: 10px;
	width: 10px;
	background-color: #B6B5BA;
	border-radius: 50%;
	display: inline-block;
	transform:translateY(-5px);
	margin-right:2px;
}
#circle1 {animation: circle1 .8s cubic-bezier(.48,.03,.55,.98) 0s infinite alternate;
	background-color: #9E9DA2}
	#circle2 {animation: circle1 .8s cubic-bezier(.48,.03,.55,.98) .2s infinite alternate;}
	#circle3 {animation: circle1 .8s cubic-bezier(.48,.03,.55,.98) .4s infinite alternate;}

@-webkit-keyframes circle1 {
	0% {transform:translateY(-5px);}
	100% {transform:translateY(5px);}
}
@keyframes circle1 {
	0% {transform:translateY(-5px);}
	100% {transform:translateY(5px);}
}


@-webkit-keyframes apply-scroll {
	0% {transform:translateX(-7px) translateY(0px);color:#333;}
	100% {transform:translateX(7px) translateY(0px);color:#325bb2;}
}
@keyframes apply-scroll {
	0% {transform:translateX(-7px) translateY(0px);color:#333;}
	100% {transform:translateX(7px) translateY(0px);color:#325bb2;}
}

.AppStatusChild:last-of-type{
	animation: apply-bg 1.5s cubic-bezier(.48,.03,.55,.98) 0s infinite alternate;
	-webkit-animation: apply-bg 1.5s cubic-bezier(.48,.03,.55,.98) 0s infinite alternate;
	background-color:rgb(244, 247, 255);
}

@-webkit-keyframes apply-bg {
	0% {border:1px solid #bbc9e8;box-shadow:0 0 10px rgba(50, 91, 178, .1);}
	100% {border:1px solid #9EB1DA;box-shadow:0 0 20px rgba(50, 91, 178, .5);}
}
@keyframes apply-bg {
	0% {border:1px solid #bbc9e8;box-shadow:0 0 10px rgba(50, 91, 178, .1);}
	100% {border:1px solid #9EB1DA;box-shadow:0 0 20px rgba(50, 91, 178, .5);}
}

@media screen and (max-width: 700px){
	.AppStatus, .AppMessage{
		max-width:500px;
		width: 100%;
	}
	.inside-content{flex-wrap: wrap;height:500px;}
	.content-messages{height:250px}
	.content-field{width: 100%;border-left:0;border-top:1px solid #eaeaea;height:250px}
	.ssfcu-message,.user-message{width: 90%}
}
.btn {
	padding-top:7px !important;
	font-size: 12px !important
}
</style>

