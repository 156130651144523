<template>

<form>
  <h1>Documents</h1>
  <div class="tree" v-for = "(file,index) in parsed_files">
 
 
    <div>
      <input :id = "'n-'+index" type="checkbox">
      <label :for = "'n-'+index">{{file.doc_type}}</label>
      <div class="sub">
        <a target = "_blank" :href = "'/document/seafarer_document?doc_desc='+ file.doc_url">{{file.doc_data.metadata.filename}}</a>
      </div>
    </div>
    
    
  </div>
   <a class="zip_file_btn" :href = "'/document/seafarer_doc_zip?seafarer_id='+ seafarer_id">Download as ZIP</a>
</form>
</template>


<script>
export default {
  props : {
    seafarer_id : {

    },
    folder_base_path : {

  },
    files : [Object,Array,String]
  },
  data(){
    return {
      parsed_files : []
    }
  },
  mounted(){
    this.parsed_files = this.files
    this.parsed_files.map(fil => {
      fil.doc_data = JSON.parse(fil.doc_data)
    })
  }
}
</script>



<style scoped>
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700);

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  min-height: 100%;
}

body {
  margin: 40px;
  font: 1.2em/1.2 'Noto Sans', sans-serif;
  background: linear-gradient(90deg, #b9c3c9, #6b7c87);
}

form {
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: 20px;
	border-top: 30px solid #5c5d5e;
	border-radius: 10px;
  background-color:white;
	box-shadow: 0 0 50px rgba(0, 0, 0, .2);
}

form::before {
	content: '';
	position: absolute;
	top: -20px;
	left: 15px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
  background-color: #adadae;
	box-shadow:
    20px 0 0 #adadae,
    40px 0 0 #adadae;
}

h1 {
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #adadae;
  color: #5c5d5e;
  font-size: 1.1em;
}

.tree {
  padding: 5px 0;
}

.tree::after {
  content: '';
  display: block;
  clear: left;
}

.tree div {
  clear: left;
}

input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

label, a {
  display: block;
  float: left;
  clear: left;
  position: relative;
  padding: 5px;
  border-radius: 5px;
  color: #5c5d5e;
  text-decoration: none;
  cursor: pointer;
}

label::before, a::before {
  display: block;
  position: absolute;
  top: 6px;
  left: -15px;
  font-family: 'FontAwesome';
}

label::before {
  content: '\f07b'; /* closed folder */
}

input:checked + label::before {
  content: '\f07c'; /* open folder */
}

a::before {
  content: '\f068'; /* dash */
}

input:focus + label, a:focus {
  outline: none;
  background-color: #b9c3c9;
}

.sub {
  display: none;
  float: left;
  margin-left: 30px;
}

input:checked ~ .sub {
  display: block;
}

.zip_file_btn {
  display: block;
  width: 100%;
  text-decoration:none;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: #e8ebed;
  background-color: #6b7c87;
  font-family: inherit;
  font-size: .9em;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.zip_file_btn:focus {
  outline: none;
  box-shadow: 0 0 0 4px #b9c3c9;
}
</style>
