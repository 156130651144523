var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "drop" } }, [
      _vm._v("Drop an XLSX / XLSM / XLSB / ODS / XLS / XML slip file here")
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.more_than_one
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-8" }, [
            _vm.is_sheet_seleted == false
              ? _c("p", { staticClass: "alert alert-danger" }, [
                  _c("i", { staticClass: "fas fa-exclamation-circle fa-fw" }),
                  _vm._v(
                    " Multiple sheets detected. Select a sheet to proccess"
                  )
                ])
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.more_than_one
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                _vm._v("Sheet / Tab")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  staticClass: "form-control",
                  attrs: { name: "", id: "" },
                  on: {
                    change: function($event) {
                      return _vm.updateSelected($event.target.value)
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", selected: "", disabled: "" } },
                    [_vm._v("----- select sheet ------")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.sheets_no, function(sheet) {
                    return _c("option", { domProps: { value: sheet } }, [
                      _vm._v(_vm._s(sheet))
                    ])
                  })
                ],
                2
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary",
        attrs: { disabled: _vm.is_sheet_seleted == false, id: "process_btn" },
        on: { click: _vm.send_rows }
      },
      [_vm._v("Proceed")]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "text-left pd-6",
        staticStyle: { padding: "15px 0px 0px 0px", "font-weight": "bold" }
      },
      [_c("h4", [_vm._v("OR")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "fileUpload blue-btn btn width100",
        staticStyle: { "margin-top": "15px" }
      },
      [
        _c("span", [_vm._v("Upload payslip file")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "uploadlogo",
          attrs: {
            type: "file",
            name: "xlfile",
            id: "xlf",
            accept: ".xls,.xlsx"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }