var mixin = {
  data(){
      return {
          pmpt_value : null,
          show_loading : false
      }
  },
  methods: {
      closeToasts:function(){
          alertify.closeAll();
      },
      toast:function(options){
          var alert_type = options.alert_type
          var msg = options.msg
          var delay = options.delay ? options.delay : 10

          var alrt = alertify[alert_type](msg).delay(delay).setContent(msg);
     
        },
        
        alert:function(options){
            
          var alert_type = options.alert_type;
          var title = options.title;
          var alert_msg = options.alert_msg;
          var msg = options.msg

          return alertify.alert(title, msg, () =>
          alertify[alert_type](alert_msg))
        },
        alertWithTitle:function(options){

          var alert_type = options.alert_type;
          var title = options.title;
          var msg = options.msg;
          var alert_msg = options.alert_msg;
          var delay = options.delay ? options.delay : 10;
          var header = options.header;

          alertify.alertWithTitle(title, msg, () =>
            alertify[alert_type](alert_msg).delay(delay)
                .setContent('Wait time updated to ' +delay + " seconds"))
                .setHeader(header)
        },
        confirm:function(options){

          var ok_type = options.ok_type;
          var cancel_type = options.cancel_type;
          var msg_ok = options.msg_ok;
          var msg_cancel = options.msg_cancel;
          var header = options.header;
          var alert = options.alert;
          

          alertify.confirm("This is comfirm", ()=>
              {
                  if(alert){
                    var alrt = alertify[ok_type](msg_ok)
                  }
                  if(typeof options.delay !== 'undefined'){
                      alrt.delay(10000).setContent('Wait time updated to '+options.delay+' Seconds');
                  }
              }
          ,() =>{
              if(alert){
                  var alrt = alertify[cancel_type](msg_cancel)
              }
              if(typeof options.delay !== 'undefined'){
                  alrt.delay(10000).setContent('Wait time updated to '+options.delay+' Seconds');
              }
          })
          .setHeader(header)
        },
        confirmWithTitle:function(options){

          var ok_type = options.ok_type;
          var cancel_type = options.cancel_type;
          var title = options.title;
          var msg = options.msg;
          var msg_ok = options.msg_ok;
          var msg_cancel = options.msg_cancel;

          alertify.confirmWithTitle(title, msg, ()=>
              alertify[ok_type](msg_ok)
            ,() =>alertify[cancel_type](msg_cancel)
            )
        },
        prompt:function(options){
          var com_this = this;
          var title = options.title;
          var msg = options.msg; 
          var ok_type = options.ok_type; 
          var cancel_type = options.cancel_type; 
          var default_value = options.default_value; 
          var cancel_msg = options.cancel_msg; 
          var msg_ok = options.msg_ok; 

          alertify.prompt(title,msg, default_value, (evt, value)=>
              {
                  alertify[ok_type](msg_ok);
                  com_this.pmpt_value = value;
              }
            , () => {
              alertify[cancel_type](cancel_msg)
            }
            )
        },
        promptWithTitle:function(options){
          var title = options.title;
          var com_this = this;
          var msg = options.msg; 
          var ok_type = options.ok_type; 
          var cancel_type = options.cancel_type; 
          var default_value = options.default_value; 
          var cancel_msg = options.cancel_msg; 
          var msg_ok = options.msg_ok; 

          alertify.promptWithTitle(title, msg, default_value, (evt, value)=>
              {
                  alertify[ok_type](msg_ok);
                  com_this.pmpt_value = value;
              }
              , () => {
                alertify[cancel_type](cancel_msg)
              }
            )
        },
      promptWithTypeColor:function(){
          alertify.promptWithTitle("prompt title", "This is prompt", "default value", (evt, value)=>
              alertify.success("ok: " + value)
            , () => alertify.error("cancel")
            ).set('type', 'color')
        },
        showConfirm(options,buttons) {

            this.setUpDialog(buttons);

          return alertify
            .myConfirm(options.msg)
            .setHeader(options.title)
            .set({
              onok: function() {
                alertify.success('ok');
              },
              oncontinue: function() {
                alertify.success('continue');
                location.reload();

              },
              oncancel: function() {
                location.reload();
                alertify.error('cancel');
              }
            });
        },
        setUpDialog(buttons){
          if(alertify.myConfirm){
              alertify.myConfirm(function(){
                this.destroy();
              });
          }
          alertify.dialog('myConfirm', function() {
              var settings;
              return {
                setup: function() {
                  var settings = alertify.confirm().settings;
                  for (var prop in settings)
                    this.settings[prop] = settings[prop];
                  var setup = alertify.confirm().setup();
                  buttons.forEach(btn => {
                      setup.buttons.push({ 
                          text: "<span id="+btn.id+">"+btn.text+"</span>",
                          key: 67 /*c*/ ,
                          scope: 'auxiliary',
                        });
                  })
                  return setup;
                },
                settings: {
                  oncontinue: null
                },
                callback: function(closeEvent) {
                  if (closeEvent.index == 1) {
                    if (typeof this.get('oncontinue') === 'function') {
                     var returnValue = this.get('oncontinue').call(this, closeEvent);
                      if (typeof returnValue !== 'undefined') {
                        closeEvent.cancel = !returnValue;
                      }
                    }
                  } else {
                    alertify.confirm().callback.call(this, closeEvent);
                    window.location.reload();
                  }
                }
              };
            }, false, 'confirm');
        }
  },
  mounted(){
     
  }
}

export default mixin;
