var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tbody",
    [
      _vm.data.length
        ? [
            _vm._l(_vm.data, function(item) {
              return [
                _c(
                  "tr",
                  [
                    _vm.shouldRenderSelection
                      ? _c(
                          "td",
                          [
                            _c("multi-select", {
                              attrs: {
                                dis_el: item["has_auth"] == false,
                                selection: _vm.selection,
                                row: item
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.columns, function(col) {
                      return _c(
                        "td",
                        { class: col.tdClass, style: col.tdStyle },
                        [
                          col.tdComp
                            ? _c(
                                _vm.forDynCompIs(col.tdComp),
                                _vm._b(
                                  {
                                    tag: "component",
                                    attrs: {
                                      row: item,
                                      field: col.field,
                                      value: item[col.field],
                                      nested: item.__nested__
                                    }
                                  },
                                  "component",
                                  _vm.$props,
                                  false
                                )
                              )
                            : [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item[col.field]) +
                                    "\n          "
                                )
                              ]
                        ],
                        2
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  item.__nested__ && item.__nested__.visible
                    ? _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: _vm.colLen } },
                          [
                            _c(
                              _vm.forDynCompIs(item.__nested__.comp),
                              _vm._b(
                                {
                                  tag: "component",
                                  attrs: { row: item, nested: item.__nested__ }
                                },
                                "component",
                                _vm.$props,
                                false
                              )
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            })
          ]
        : !_vm.leftFixed && !_vm.rightFixed
        ? _c("tr", [
            _c(
              "td",
              {
                staticClass: "text-center text-muted",
                attrs: { colspan: _vm.colLen }
              },
              [
                _vm._v(
                  "\n      ( " +
                    _vm._s(_vm.$i18nForDatatable("No Data")) +
                    " )\n    "
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }