var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("table", [
      _c(
        "tbody",
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.documents, function(doc, index) {
            return _c(
              "div",
              { staticStyle: { "margin-bottom": "20px!important" } },
              [
                _c("tr", [
                  _vm.check_expired(doc.expiry_date)
                    ? _c("span", { staticStyle: { color: "darkred" } }, [
                        _c("i", { staticClass: "fas fa-exclamation-circle" }),
                        _vm._v(" (EXPIRED) ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "progress-title",
                      attrs: { id: "pro_title_" + doc.document_id }
                    },
                    [_vm._v(_vm._s(doc.doc_type))]
                  ),
                  _vm._v(" "),
                  doc.expiry_date != null
                    ? _c("div", { staticClass: "checkbox" }, [
                        _c("div", { staticClass: "check" }, [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            on: {
                              change: function($event) {
                                return _vm.updateIds(doc.document_id)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm._m(1, true)
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  doc.expiry_date == null
                    ? _c("div", { staticStyle: { float: "right" } }, [
                        _c("b", { staticStyle: { "font-size": "10px" } }, [
                          _vm._v("Not applicable")
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("tr", [
                  !_vm.check_expired(doc.expiry_date)
                    ? _c("td", [
                        _c(
                          "i",
                          { staticStyle: { "font-size": "10px!important" } },
                          [
                            _vm._v(
                              _vm._s(doc.date_of_issue) +
                                " - " +
                                _vm._s(doc.expiry_date)
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "progress progress-small" }, [
                      _c("div", {
                        staticClass: "bar",
                        attrs: {
                          duration: doc.duration,
                          period_type: doc.period_type,
                          elapsed_percent: _vm.get_elapsed_percentage(
                            doc.duration
                          ),
                          expiry_date: doc.expiry_date,
                          date_of_issue: doc.date_of_issue
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr"),
                _vm._v(" "),
                _c("hr")
              ]
            )
          }),
          _vm._v(" "),
          _c("span", [
            _c(
              "button",
              {
                staticClass: "label label-primary pull-right vx-send-no",
                staticStyle: { padding: "5px !important" },
                attrs: {
                  disabled: _vm.enable_send_mail,
                  id: "send_not",
                  href: ""
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.send_notification($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-envelope" }),
                _vm._v(" Send Notification")
              ]
            )
          ])
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "basicModal_progress",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "basicModal_progress",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h4",
                { staticClass: "modal-title", attrs: { id: "myModalLabel" } },
                [
                  _vm._v(
                    _vm._s(_vm.seafarer_info.lastname) +
                      " " +
                      _vm._s(_vm.seafarer_info.othername) +
                      " " +
                      _vm._s(_vm.seafarer_info.firstname) +
                      " (" +
                      _vm._s(_vm.seafarer_info.rank) +
                      ")"
                  )
                ]
              ),
              _vm._v(" "),
              _vm._m(2)
            ]),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "button-group-pills text-center",
                attrs: { "data-toggle": "buttons" }
              },
              [
                _c("label", { staticClass: "btn btn-default active" }, [
                  _c("input", {
                    attrs: { type: "checkbox", name: "options", checked: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("MOBILE : "),
                    _c("i", { staticClass: "fas fa-mobile" }),
                    _vm._v(" " + _vm._s(_vm.seafarer_info.phone))
                  ])
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "btn btn-default" }, [
                  _c("input", { attrs: { type: "checkbox", name: "options" } }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("EMAIL : "),
                    _c("i", { staticClass: "fas fa-envelope" }),
                    _vm._v(" " + _vm._s(_vm.seafarer_info.email))
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { attrs: { id: "modal_body_progress" } }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.enable_send
                ? _c(
                    "span",
                    { staticStyle: { "font-size": "small", color: "crimson" } },
                    [_c("b", [_vm._v("Email or Phone must be available")])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { padding: "5px !important" },
                  attrs: {
                    disabled: _vm.enable_send,
                    type: "button",
                    id: "send_notification"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submitEmail($event)
                    }
                  }
                },
                [_vm._v("Notify")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button", "data-dismiss": "modal" }
                },
                [_vm._v("Cancel")]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { staticClass: "pull-right vx-send-no", attrs: { href: "" } }, [
        _vm._v("Select documents to send notifications ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check-container" }, [
      _c("div", { staticClass: "check-off" }),
      _vm._v(" "),
      _c("div", { staticClass: "check-on" }, [_c("i")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("h4", { staticClass: "text-center" }, [
        _vm._v("Select Notification Options")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }