<template>
    
</template>

<script>
import form_mixins from '../../mixins/form_mix.jsx';
import alert_mixins from '../../mixins/alertify.jsx';
import vx_select from '../../utils/vx_select.vue';
import comment_box from '../../utils/comment_box.vue';
import FormData from '../../../scripts/form_data.js';

export default {
    object_name : "seafarer_embarkment",
    components : {vx_select,comment_box},
    form_ids : ['sea_embar_form_id'],
    mixins : [form_mixins,alert_mixins],
    props : ['seafarer_obj'],
    persist : ['name','options'],
    data(){
        return {
          formData : null,
            name : '',
            embark_options : {},
            options : {},
            query_fields : {},
            seafarer_results : [],
            selected_ferar : null,
            show_seafarer_details : null,
            show_search_table : false,
            vessel_info : null,
            enable_search : true
        }
    },
    created(){

  
    },
    methods : {
      save_record(){
                save_btn.start();
                this.seafarer_embarkment["seafarer_id"] = this.selected_ferar;
                const com_this = this;

                this.formData = new FormData({
                    data : this.seafarer_embarkment,
                    alertify : true
                })
                this.formData.post('/seafarer_embarkment/process_embarkment')
                    .then(response => {
                        if(response.status == "SUCCESS"){
                            save_btn.stop();
                            this.record_id = response.data.record_id;
                            this.showConfirm({
                                title : "Vessel Embarkment",
                                msg : response.msg
                            },[
                                {
                                    text: "New Entry",
                                    action : "redo",
                                    id : "id1"
                                },   
                                {
                                    text: "Seafarer Profile",
                                    action : "redo",
                                    id : "id2"
                                },
                            ]);

                            document.getElementById('id1').addEventListener('click', function () {
                                location.reload()
                            });


                            document.getElementById('id2').addEventListener('click', function () {
                               window.location.replace("/seafarer/view/"+com_this.record_id);
                            });
                        }
                    })
                    .catch(data => {
                      console.log(212);
                        save_btn.stop();
                    });
            },
      perform_search(){
        this.show_seafarer_details = false;
        this.show_search_table = false;
        axios.post("/seafarer_embarkment/embark_search",this.query_fields)
             .then(res => {
                  console.log(res);
                  if(res.data.status == "FAILURE"){
                    this.toast({
                        alert_type : "error",
                        msg : res.data.msg,
                        delay : 10
                    })
                  }
               if(res.data.status == "SUCCESS"){
                  this.seafarer_results = res.data.data;
                  if(res.data.data.length > 0){
                    this.show_search_table = true;
                  }
               }
             })
             .catch(res => {

             })
      },
        updateQuery(field){
        if(this.query_fields[field]){
          delete this.query_fields[field]
          delete this.options[field]
        }
        this.updateQueryFields()
      },
      refreshOptions(){
        this.options = {}
        
        this.query_fields = {}
        this.show_search_table = false
        this.show_seafarer_details = false

      },
      getFieldName(name){
        var found_name = null;
        this.seafarer_obj.forEach(obj => {
          if(obj.field == name){
            found_name =  obj.field_name;
          }
        });
        return found_name;
      },
      processEmbark(id){
         this.selected_ferar = id;
         this.show_search_table = false;
         this.show_seafarer_details = true;
      },
      update_vessel_info(value){
        axios.get('/seafarer_embarkment/vessel_info',{
          params : {
            vessel_id : value
          }
        })
        .then(res => {
          this.vessel_info = res.data.data
        })
      },
      updateQueryFields(){
        this.enable_search = false;
        for (var key in this.options) {
          if(this.options[key]){
            if(this.options[key] == true){
              if (!this.query_fields.hasOwnProperty(key)) {
                this.enable_search = true;
            }else{
              if(this.query_fields[key] == "" || this.query_fields[key] == null){
                  this.enable_search = true;
              }
            }
           }
          }
        }
      }
    },
    watch : {
        query_fields : {
          handler : function(options) {
            this.updateQueryFields()
          },
          deep : true
        }
    },
    computed : { 
      selected_seafarer(){
          return this.seafarer_results.filter(obj => {
          return obj.id == this.selected_ferar
        })
      }
    },
    mounted(){
         window.save_btn = Ladda.create( document.getElementById('save_record'));

      this.embark_options = this.seafarer_obj.reduce(function (r, a) {
        r[a.group_name] = r[a.group_name] || [];
        r[a.group_name].push(a);
        return r;
    }, Object.create(null));


       $('li.dropdown.dropdown-large a').on('click', function (event) {
            $(this).parent().toggleClass('open');
        });

        $('body').on('click', function (e) {
            if (!$('li.dropdown.dropdown-large').is(e.target) 
                && $('li.dropdown.dropdown-large').has(e.target).length === 0 
                && $('.open').has(e.target).length === 0
            ) {
                $('li.dropdown.dropdown-large').removeClass('open');
            }
        });

        $('#rwd-table-large tbody tr').on('click',function() {
           console.log(this);
        });

    }

}
</script>


<style scoped>
.dropdown-large {
  position: static !important;
}
.dropdown-menu-large {
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0px;
}
.dropdown-menu-large > li > ul {
  padding: 0;
  margin: 0;
}
.dropdown-menu-large > li > ul > li {
  list-style: none;
}
.dropdown-menu-large > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}
.dropdown-menu-large > li ul > li > a:hover,
.dropdown-menu-large > li ul > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu-large .disabled > a,
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  color: #999999;
}
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.dropdown-menu-large .dropdown-header {
  color: #428bca;
  font-size: 14px;
  padding: 0 !important;
  margin-bottom: 5px !important
}
@media (max-width: 768px) {
  .dropdown-menu-large {
    margin-left: 0 ;
    margin-right: 0 ;
  }
  .dropdown-menu-large > li {
    margin-bottom: 30px;
  }
  .dropdown-menu-large > li:last-child {
    margin-bottom: 0;
  }
  .dropdown-menu-large .dropdown-header {
    padding: 3px 15px !important;
  }
}

.dropdown-toggle::after {
  display : none 
}


.new {
  padding: 50px;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  
}

label {
  font-family: Arial, Helvetica, sans-serif !important
}

.form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 6px;
    width: 5px;
    height: 12px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}
 .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
        color: white !important
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
    }
    
    .btn-primary:hover {
        background-color: #566f7b;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
.vx-custom-show{
  display: none;
  position: absolute
}
/* Include CSS Normalize */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

main {
  padding: 2em;
  float: left;
  width: 100%;
}

a {
  color: #222;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid #222;
}
a:hover {
  color: #008cba;
}

table {
  border-collapse: collapse;
  width: 100%;
  float: left;
  margin: 0 0 2em;
}
table thead {
  border-bottom: 3px solid #008cba;
}
table thead th {
  padding: 0.35em 0 .35em;
  font-weight: 400;
  text-align: left;
  font-family: 'Arial';
  font-size: 1em;
}
table tbody {
  border-bottom: 3px solid #008cba;
}
table tbody tr {
  border-bottom: 2px solid #ddd;
}
table tbody tr td {
  padding: .75em 0;;
  text-transform: uppercase
}
table tbody tr td a {
  color: #008cba;
  text-decoration: none;
  display: inline-block;
  margin: 0 .5em 0 0;
}
table tbody tr td a:hover, table tbody tr td a:active, table tbody tr td a:focus {
  color: #006687;
  border: none;
}
table tfoot td {
  padding: 0.35em 0 .35em;
  text-align: left;
  font-family: 'Helvetica', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.25em;
}

@media screen and (min-width: 1000px) {
  table {
    width: 100%;
  }
  table thead {
    border-bottom: 3px solid #008cba;
  }
  table tbody tr {
    border-bottom: 1px solid #ddd;
  }
}
#rwd-table {
  display: block;
}
#rwd-table tbody {
  border: none;
}
#rwd-table tbody, #rwd-table th, #rwd-table td, #rwd-table tr, #rwd-table tfoot {
  display: block;
}
#rwd-table thead {
  display: none;
}
#rwd-table tr {
  float: left;
  width: 100%;
  margin: 0 0 1em;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px 0px #ddd;
  border-radius: 4px;
  border-top: 5px solid #008cba;
}
#rwd-table tr td {
  padding: .5em .5em .5em 50%;
  float: left;
  width: 100%;
}
#rwd-table tr td:before {
  width: 100%;
  display: block;
  float: left;
  padding: .5em .5em .5em 0;
  clear: both;
  margin: -.5em 0 0 -100%;
  font-family: 'Helvetica', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.125em;
}
@media screen and (min-width: 600px) {
  #rwd-table {
    display: table;
    border: none;
  }
  #rwd-table tbody {
    border-bottom: 3px solid #008cba;
  }
  #rwd-table th, #rwd-table td {
    display: table-cell;
  }
  #rwd-table tr {
    display: table-row;
    border: none;
    border-bottom: 1px solid #eee;
    float: none;
    margin: 0;
    box-shadow: none;
  }
  #rwd-table tr td {
    padding: .75em 0 0.75em .25em;
    float: none;
    width: auto;
  }
  #rwd-table tr td:before {
    padding: 0 !important;
  }
  #rwd-table thead {
    display: table-header-group;
  }
  #rwd-table tbody, #rwd-table tfoot {
    display: table-row-group;
  }
  #rwd-table tr:nth-of-type(even) {
    background: none;
  }
}

#rwd-table-large {
  display: block;
}
#rwd-table-large tbody {
  border: none;
}
#rwd-table-large tbody, #rwd-table-large th, #rwd-table-large td, #rwd-table-large tr, #rwd-table-large tfoot {
  display: block;
}
#rwd-table-large thead {
  display: none;
}
#rwd-table-large tr {
  float: left;
  width: 100%;
  margin: 0 0 1em;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px 0px #ddd;
  border-radius: 4px;
  border-top: 5px solid #008cba;
}
#rwd-table-large tr td {
  padding: .5em .5em .5em 50%;
  float: left;
  width: 100%;
}
#rwd-table-large tr td:before {
  width: 100%;
  display: block;
  float: left;
  padding: .5em .5em .5em 0;                                                        
  clear: both;
  margin: -.5em 0 0 -100%;
  font-family: 'Helvetica', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.125em;
}
@media screen and (min-width: 1000px) {
  #rwd-table-large {
    display: table;
    border: none;
  }
  #rwd-table-large tbody {
    border-bottom: 3px solid #008cba;
  }
  #rwd-table-large th, #rwd-table-large td {
    display: table-cell;
  }
  #rwd-table-large tr {
    display: table-row;
    border: none;
    border-bottom: 1px solid #eee;
    float: none;
    margin: 0;
    box-shadow: none;
  }
  #rwd-table-large tr td {
    padding: .75em 0 0.75em .25em;
    float: none;
    width: auto;
  }
  #rwd-table-large tr td:before {
    padding: 0 !important;
  }
  #rwd-table-large thead {
    display: table-header-group;
  }
  #rwd-table-large tbody, #rwd-table-large tfoot {
    display: table-row-group;
  }
  #rwd-table-large tr:nth-of-type(even) {
    background: none;
  }
}

#rwd-table tbody tr td:nth-of-type(1):before {
  content: "Heading Col 1";
}
@media screen and (min-width: 600px) {
  #rwd-table tbody tr td:nth-of-type(1):before {
    content: '';
  }
}
#rwd-table tbody tr td:nth-of-type(2):before {
  content: "Heading Col 2";
}
@media screen and (min-width: 600px) {
  #rwd-table tbody tr td:nth-of-type(2):before {
    content: '';
  }
}
#rwd-table tbody tr td:nth-of-type(3):before {
  content: "Heading Col 3";
}
@media screen and (min-width: 600px) {
  #rwd-table tbody tr td:nth-of-type(3):before {
    content: '';
  }
}

#rwd-table-large tbody tr td:nth-of-type(1):before {
  content: "Heading Col 1";
}
@media screen and (min-width: 1000px) {
  #rwd-table-large tbody tr td:nth-of-type(1):before {
    content: '';
  }
}
#rwd-table-large tbody tr td:nth-of-type(2):before {
  content: "Heading Col 2";
}
@media screen and (min-width: 1000px) {
  #rwd-table-large tbody tr td:nth-of-type(2):before {
    content: '';
  }
}
#rwd-table-large tbody tr td:nth-of-type(3):before {
  content: "Heading Col 3";
}
@media screen and (min-width: 1000px) {
  #rwd-table-large tbody tr td:nth-of-type(3):before {
    content: '';
  }
}
#rwd-table-large tbody tr td:nth-of-type(4):before {
  content: "Heading Col 4";
}
@media screen and (min-width: 1000px) {
  #rwd-table-large tbody tr td:nth-of-type(4):before {
    content: '';
  }
}
.fade-leave-active {
  transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.vx-form-group{
  margin : 0px 0px 22px 0px !important;
}   
.vx-form-group input{
  font-size : 12px !important
} 
.vx-bold-text1  {
  text-transform: uppercase !important;
  color: #191970 !important;
}   
.vx-bold-text2  {
  text-transform: uppercase !important;
  color : #A61414 !important;
}   
</style>


