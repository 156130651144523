<template>
	<div>
<div id="drop">Drop an XLSX / XLSM / XLSB / ODS / XLS / XML slip file here</div>
<div class="text-left pd-6" style="padding:15px 0px 0px 0px; font-weight:bold"><h4>OR</h4></div>
    <div class="fileUpload blue-btn btn width100" style="margin-top:15px">
        <span>Upload payslip file</span>
        <input type="file" class="uploadlogo" name="xlfile" id="xlf" accept=".xls,.xlsx" />
    </div>
<br />
<hr>	
<div class="row" v-if="more_than_one">
	<div class="col-md-8">
		<p class="alert alert-danger" v-if="is_sheet_seleted == false"><i class="fas fa-exclamation-circle fa-fw"></i> Multiple sheets detected. Select a sheet to proccess</p>
	</div>
</div>
 <div class="row" v-if="more_than_one"> 
    <div class="col-md-4">
        <div class="form-group">
            <label for="exampleInputEmail1">Sheet / Tab</label>
			<select name="" id="" class="form-control" @change="updateSelected($event.target.value)">
				<option value="" selected disabled>----- select sheet ------</option>
				<option v-for="sheet in sheets_no" :value="sheet">{{sheet}}</option>
			</select>
        </div>
    </div>
</div>
<button :disabled="is_sheet_seleted == false" id="process_btn" class="btn btn-primary" @click="send_rows">Proceed</button>
</div>
</template>

<script>
export default {
	data(){
		return {
			sheets_no : [],
			total_sheets : [],
			selected_sheet : []
		}
	},
	methods : {
		send_rows(){
			this.$emit('selected_sheet',this.selected_sheet)
		},
		updateSelected(value){
			this.selected_sheet = this.total_sheets[value]
		}
	},
	mounted(){
		const com_this = this;
	var X = XLSX;
var XW = {
	/* worker message */
	msg: 'xlsx',
	/* worker scripts */
	worker: './xlsxworker.js'
};

var global_wb;

var process_wb = (function() {
	// var OUT = document.getElementById('out');
	// var HTMLOUT = document.getElementById('htmlout');

	var get_format = (function() {
		var radios = document.getElementsByName( "format" );
		return function() {
			for(var i = 0; i < radios.length; ++i) if(radios[i].checked || radios.length === 1) return radios[i].value;
		};
	})();

	var to_json = function to_json(workbook) {
		var result = {};
		com_this.sheets_no = []
		com_this.total_sheets = []
		com_this.selected_sheet = []
		workbook.SheetNames.forEach(function(sheetName) {
			var roa = X.utils.sheet_to_json(workbook.Sheets[sheetName], {header:1});
			if(roa.length) result[sheetName] = roa;
		});

		com_this.total_sheets = result;
		com_this.sheets_no = Object.keys(result);
		if(Object.keys(result).length == 1){
			com_this.selected_sheet = result[Object.keys(result)[0]]
		}
		return JSON.stringify(result, 2, 2);
	};

	var to_csv = function to_csv(workbook) {
		var result = [];
		workbook.SheetNames.forEach(function(sheetName) {
			var csv = X.utils.sheet_to_csv(workbook.Sheets[sheetName]);
			if(csv.length){
				result.push("SHEET: " + sheetName);
				result.push("");
				result.push(csv);
			}
		});
		return result.join("\n");
	};

	var to_fmla = function to_fmla(workbook) {
		var result = [];
		workbook.SheetNames.forEach(function(sheetName) {
			var formulae = X.utils.get_formulae(workbook.Sheets[sheetName]);
			if(formulae.length){
				result.push("SHEET: " + sheetName);
				result.push("");
				result.push(formulae.join("\n"));
			}
		});
		return result.join("\n");
	};

	var to_html = function to_html(workbook) {
		HTMLOUT.innerHTML = "";
		workbook.SheetNames.forEach(function(sheetName) {
			var htmlstr = X.write(workbook, {sheet:sheetName, type:'string', bookType:'html'});
			HTMLOUT.innerHTML += htmlstr;
		});
		return "";
	};

	return function process_wb(wb) {
		global_wb = wb;
		var output = "";
		output = to_json(wb)
		// if(OUT.innerText === undefined) OUT.textContent = output;
		// else OUT.innerText = output;
		// if(typeof console !== 'undefined') console.log("output", new Date());
	};
})();

var setfmt = window.setfmt = function setfmt() { if(global_wb) process_wb(global_wb); };

var b64it = window.b64it = (function() {
	// var tarea = document.getElementById('b64data');
	// return function b64it() {
	// 	if(typeof console !== 'undefined') console.log("onload", new Date());
	// 	var wb = X.read(tarea.value, {type:'base64', WTF:false});
	// 	process_wb(wb);
	// };
})();

var do_file = (function() {
	var rABS = typeof FileReader !== "undefined" && (FileReader.prototype||{}).readAsBinaryString;
	var domrabs = false;
	if(!rABS) domrabs.disabled = !(domrabs.checked = false);

	var use_worker = typeof Worker !== 'undefined';
	var domwork = false;
	if(!use_worker) domwork.disabled = !(domwork.checked = false);

	var xw = function xw(data, cb) {
		var worker = new Worker(XW.worker);
		worker.onmessage = function(e) {
			switch(e.data.t) {
				case 'ready': break;
				case 'e': console.error(e.data.d); break;
				case XW.msg: cb(JSON.parse(e.data.d)); break;
			}
		};
		worker.postMessage({d:data,b:rABS?'binary':'array'});
	};

	return function do_file(files) {
		rABS = domrabs.checked;
		use_worker = domwork.checked;
		var f = files[0];
		var reader = new FileReader();
		reader.onload = function(e) {
			if(typeof console !== 'undefined') console.log("onload", new Date(), rABS, use_worker);
			var data = e.target.result;
			if(!rABS) data = new Uint8Array(data);
			if(use_worker) xw(data, process_wb);
			else process_wb(X.read(data, {type: rABS ? 'binary' : 'array'}));
		};
		if(rABS) reader.readAsBinaryString(f);
		else reader.readAsArrayBuffer(f);
	};
})();

(function() {
	var drop = document.getElementById('drop');
	if(!drop.addEventListener) return;

	function handleDrop(e) {
		e.stopPropagation();
		e.preventDefault();
		do_file(e.dataTransfer.files);
	}

	function handleDragover(e) {
		e.stopPropagation();
		e.preventDefault();
		e.dataTransfer.dropEffect = 'copy';
	}

	drop.addEventListener('dragenter', handleDragover, false);
	drop.addEventListener('dragover', handleDragover, false);
	drop.addEventListener('drop', handleDrop, false);
})();

(function() {
	var xlf = document.getElementById('xlf');
	if(!xlf.addEventListener) return;
	function handleFile(e) { do_file(e.target.files); }
	xlf.addEventListener('change', handleFile, false);
})();
	var _gaq = _gaq || [];
	_gaq.push(['_setAccount', 'UA-36810333-1']);
	_gaq.push(['_trackPageview']);

	(function() {
		var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
		ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
		var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
	})();
	},
	computed : {
		is_sheet_seleted(){
			if(this.selected_sheet.length > 0){
				return true
			}else{
				return false
			}
		},
		more_than_one(){
			if(this.sheets_no.length > 1){
				return true;
			}else{
				return false
			}
		}
	}
}
</script>

<style scoped>
.head {
  font-size: 25px;
  font-weight: 200;
}
 .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
        color: white !important
    }
    
    .btn-primary:hover {
        background-color: #38474f;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
.blue-btn:hover,
.blue-btn:active,
.blue-btn:focus,
.blue-btn {
  background: transparent;
  border: solid 1px #27a9e0;
  border-radius: 3px;
  color: #27a9e0;
  font-size: 16px;
  margin-bottom: 20px;
  outline: none !important;
  padding: 10px 20px;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  height: 43px;
  margin-top: 0;
}

.fileUpload input.uploadlogo {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 42px;
}

/*Chrome fix*/
input::-webkit-file-upload-button {
  cursor: pointer !important;
  height: 42px;
  width: 100%;
}
#drop{
	border:2px dashed #bbb;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	border-radius:5px;
	padding:25px;
	text-align:center;
	font:17pt bold,"Vollkorn";color:#bbb
}
#b64data{
	width:100%;
}
</style>
