<template>
  <div>
                <div class="" style="margin:10px 0px 10px 0px">
                <button class="btn" :id="'download-xlsx'"><i class="fas fa-file-excel"></i> Download Excel</button>
                <button class="btn" :id="'download-pdf'"><i class="fas fa-file-pdf"></i> Download PDF</button>
                <button class="btn" :id="'download-csv'"><i class="fas fa-file-csv"></i> Download CSV</button>
            </div>
<table class="table table-condensed" id="vx_table" style="border-collapse:collapse;">
    <thead>
        <tr>
            <th>#</th>
            <th>Date</th>
            <th>Description</th>
            <th>Credit</th>
            <th>Debit</th>
            <th>Balance</th>
        </tr>
    </thead>
    <tbody>
        <tr data-toggle="collapse" data-target="#demo1" class="accordion-toggle">
            <td>1</td>
            <td>05 May 2013</td>
            <td>Credit Account</td>
            <td class="text-success">$150.00</td>
            <td class="text-error"></td>
            <td class="text-success">$150.00</td>
        </tr>
        <tr>
            <td colspan="6" class="hiddenRow">
                <div class="accordian-body collapse" id="demo1">
                    <table>
                        <thead>
                            <th>item 1</th>
                            <th>item 2</th>
                            <th>item 3</th>
                        </thead>
                    </table>
                </div>
            </td>
        </tr>
		
        <tr data-toggle="collapse" data-target="#demo2" class="accordion-toggle">
            <td>2</td>
            <td>05 May 2013</td>
            <td>Credit Account</td>
            <td class="text-success">$11.00</td>
            <td class="text-error"></td>
            <td class="text-success">$161.00</td>
        </tr>
        <tr>
            <td colspan="6" class="hiddenRow"><div id="demo2" class="accordian-body collapse">Demo Content2</div></td>
        </tr>
		
        <tr data-toggle="collapse" data-target="#demo3" class="accordion-toggle">
            <td>3</td>
            <td>05 May 2013</td>
            <td>Credit Account</td>
            <td class="text-success">$500.00</td>
            <td class="text-error"></td>
            <td class="text-success">$661.00</td>
        </tr>
        <tr>
            <td colspan="6"  class="hiddenRow"><div id="demo3" class="accordian-body collapse">Demo Content3</div></td>
        </tr>

        <tr data-toggle="collapse" data-target="#demo4" class="accordion-toggle">
            <td>4</td>
            <td>05 May 2013</td>
            <td>Credit Account</td>
            <td class="text-success">$500.00</td>
            <td class="text-error"></td>
            <td class="text-success">$661.00</td>
        </tr>
        <tr>
            <td colspan="6"  class="hiddenRow"><div id="demo4" class="accordian-body collapse">Demo Content4</div></td>
        </tr>

        <tr data-toggle="collapse" data-target="#demo5" class="accordion-toggle">
            <td>5</td>
            <td>05 May 2013</td>
            <td>Credit Account</td>
            <td class="text-success">$500.00</td>
            <td class="text-error"></td>
            <td class="text-success">$661.00</td>
        </tr>
        <tr>
            <td colspan="6"  class="hiddenRow"><div id="demo5" class="accordian-body collapse">Demo Content5</div></td>
        </tr>		
    </tbody>
</table>
      </div>
</template>


<script>
export default {
    mounted(){
      $('.accordian-body').on('show.bs.collapse', function () {
    $(this).closest("table")
        .find(".collapse.in")
        .not(this)
        .collapse('toggle')
})
 $("#download-csv").click(function(){
            $("#vx_table").tableExport();
        });
        $("#download-xlsx").click(function(){
            window["table"].download("xlsx", "data.xlsx", {sheetName:"My Data"});
        });

        //trigger download of data.pdf file
        $("#download-pdf").click(function(){
            window["table"].download("pdf", "data.pdf", {
                orientation:"landscape", //set page orientation to portrait
                title:com_this.pdf_heading, //add title to report
            });
        });
    }
    
    
}
</script>

<style>

.hiddenRow {
    padding: 0 4px !important;
    background-color: #eeeeee;
    font-size: 13px;
}
</style>

