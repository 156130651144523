import { render, staticRenderFns } from "./nested-DisplayRow.vue?vue&type=template&id=61770017&"
import script from "./nested-DisplayRow.vue?vue&type=script&lang=js&"
export * from "./nested-DisplayRow.vue?vue&type=script&lang=js&"
import style0 from "./nested-DisplayRow.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/oem/work/Rails/tosdere_erp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('61770017', component.options)
    } else {
      api.reload('61770017', component.options)
    }
    module.hot.accept("./nested-DisplayRow.vue?vue&type=template&id=61770017&", function () {
      api.rerender('61770017', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/utils/comps/nested-DisplayRow.vue"
export default component.exports