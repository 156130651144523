var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "AppMessage-container" }, [
    _c("div", { staticClass: "AppMessage" }, [
      _c("div", [
        _c("input", {
          staticClass: "details-expander",
          attrs: { id: "CheckBox-1", type: "checkbox" }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "Title", attrs: { for: "CheckBox-1" } }, [
          _vm._m(0),
          _c("div", { staticClass: "notifications" }, [_vm._v("1")]),
          _vm._v(" " + _vm._s(_vm.request_header)),
          _c("p"),
          _vm._v(" "),
          _c("div", { staticClass: "Chevron" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-8" }, [
            _c("div", { staticStyle: { float: "right" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { href: "#", id: "approve_btn" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.approve_request($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-check-circle" }),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Approve")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { href: "#", id: "revert_btn" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.revert_the_req($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-edit" }),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Revert")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { href: "#", id: "reject_btn" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.request_reject($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-trash" }),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Reject")])
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "Content" }, [
          _c("div", { staticClass: "inside-content" }, [
            _c(
              "div",
              { staticClass: "content-messages" },
              _vm._l(_vm.record_access.comments, function(comment) {
                return _c("div", [
                  _vm._m(1, true),
                  _vm._v(" "),
                  comment.user.id != _vm.current_user.id
                    ? _c(
                        "div",
                        { staticStyle: { width: "inherit", float: "left" } },
                        [
                          _c("h1"),
                          _vm._v(" "),
                          _c("div", { staticClass: "ssfcu-message" }, [
                            _vm._v(_vm._s(comment.body))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ssfcu-author" }, [
                            _c("div", [_vm._v(_vm._s(comment.created_at))])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  comment.user.id == _vm.current_user.id
                    ? _c(
                        "div",
                        { staticStyle: { width: "inherit", float: "right" } },
                        [
                          _c("div", { staticClass: "user-message" }, [
                            _vm._v(_vm._s(comment.body))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "user-author" }, [
                            _c("div", [_vm._v(_vm._s(comment.created_at))])
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content-field" }, [
              _c("h5", [_vm._v("Add a comment (*optional)")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.request_access_comment,
                    expression: "request_access_comment"
                  }
                ],
                staticStyle: { width: "100%", "flex-grow": "1" },
                attrs: { id: "par-txt" },
                domProps: { value: _vm.request_access_comment },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.request_access_comment = $event.target.value
                  }
                }
              })
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", {
        staticClass: "fa fa-envelope-o",
        staticStyle: { "font-size": "23px", "margin-right": "10px" },
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "unread" }, [
      _c("i", {
        staticClass: "fa fa-exclamation-triangle",
        staticStyle: { color: "#d4bf13" }
      }),
      _vm._v(" one unread message")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }