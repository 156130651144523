<template>
    
</template>

<script>
import tnl_frm_1_entry from './tnl_frm_1_entry.vue';
import select_reg_type from './select_reg_type.vue';
import tnl_frm_1_entry_portal from './tnl_frm_1_entry_portal.vue';

export default {
    components : {tnl_frm_1_entry,select_reg_type,tnl_frm_1_entry_portal},
    props : {
        record_mode : {
            default : null
        },
        seafarer : {

        },
    },
    data(){
        return {
            toggle_show_setup : true,
            toggle_show_form : false,
            seafarer_position_id : null,
            docs_to_upload : [],
            setup_options : [],
            record_id : null,
        }
    },
    methods : {
        showRegForm(doc_list){
            this.docs_to_upload = doc_list;
            this.toggle_show_setup = false;
            this.toggle_show_form = true;
            setTimeout(() => {
             body_sizer()
                
            }, 100);
        },
        set_seaf_type(id){
            this.seafarer_position_id = id;
        },
        loadOptions(options){
            this.setup_options = options;
        },
        showSpinner: function() {
    
            
    		// var l = Ladda.create(document.querySelector('.ladda-button'));
    		// l.start();
    		// setTimeout(function() {
        	// 	l.stop();
    		// }, 1000);
    	}
    },
    mounted(){     
    if(this.record_mode == "UPDATE"){
            this.toggle_show_setup = false;
            this.toggle_show_form = true;
            this.record_id = this.seafarer.id

        }
    }
}
</script>

<style>

@import 'ladda/dist/ladda.min.css';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>


