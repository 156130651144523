<template>
   
</template>

<script>
import scan_upload from '../../utils/scan_upload.vue';
import vx_select from '../../utils/vx_select.vue';
import vx_field_set from '../../utils/fieldset.vue';
import form_mixins from '../../mixins/form_mix.jsx';
import alert_mixins from '../../mixins/alertify.jsx';
import FormData from '../../../scripts/form_data.js';

    export default {
        object_name : "seafarer",
        form_ids : ['start_reg_id'],
        components : {scan_upload,vx_field_set,vx_select},
        mixins : [form_mixins,alert_mixins],
        props : ['req_docs','doc_list'],
        data(){
            return {
                rail_form_obj_name : "",
                formData : null,
                req_doc_ids : [],
                opt_doc_ids : [],
                doc_to_upload : []
            }
        },
        methods : {
            routerPush(){
                this.$router.push({ path: 'about' })
            },
            loadSetup(){
                
                if(this.doc_to_upload.length > 0){
                    this.$emit('options_loaded',{
                        doc_to_upload : this.doc_to_upload,
                        seaf_type_id : this.seafarer.seaf_type_id,
                        company_id : this.seafarer.company_id,
                    })
                    this.$emit('doc_upload_set',this.doc_to_upload);
                    this.$emit('seafarer_type',this.seafarer.seaf_type_id);
                }
                 
                 

                // this.formData = new FormData({
                //     data : this.seafarer,
                //     alertify : true
                // })
                // const com_this = this;
                // axios.post('/admin/seafarer/start_process_reg',this.doc_to_upload)
                //     .then(response => {
                //         if(true){
                //             com_this.showConfirm({
                //                 title : "Seafarer Registration",
                //                 msg : response.msg
                //             },[
                //                 {
                //                     text: "New Entry",
                //                     action : "redo",
                //                     id : "id1"
                //                 },
                //                 {
                //                     text: "Go to List",
                //                     action : "redo",
                //                     id : "id2"
                //                 },    
                //                 {
                //                     text: "View CV",
                //                     action : "redo",
                //                     id : "id2"
                //                 },
                //             ]);

                     
                //         }
                //     })
                //     .catch(data => {
                //         console.log("form posted");
                //     });
            },
            containsObject(obj, list) {
                var i;
                for (i = 0; i < list.length; i++) {
                    if (list[i] === obj) {
                        return true;
                    }
                }

                return false;
            },
            set_required(element,sel_doc){
                if(element.checked){
                    this.doc_to_upload.push(sel_doc);
                }else{
                    // console.log(this.containsObject(sel_doc,this.doc_to_upload));
                    this.doc_to_upload.splice(this.doc_to_upload.indexOf(sel_doc),1);
                }
            },
            checkDocList(doc_id){
                this.doc_list.forEach(doc => {
                    if(doc_id == doc.id){
                         doc["date_value"] = "";
                         this.doc_to_upload.push(doc);
                    }
                });//
            },
        },
        watch : {
            seafarer : {
                handler : function(seafarer){
                    const com_this = this;
                    this.req_doc_ids = [];
                    this.opt_doc_ids = [];
                    this.doc_to_upload = [];
                    this.req_docs.forEach(rq_d => {
                        if(rq_d.seafarer_type_id == seafarer.seaf_type_id){
                            // console.log(rq_d);
                            if(rq_d.required == true){
                                com_this.checkDocList(rq_d.document_type_id);
                                com_this.req_doc_ids.push(rq_d.document_type_id)
                            }
                            if(rq_d.required == false){
                                if(rq_d.name !== "OTHER"){
                                    this.opt_doc_ids.push(rq_d.document_type_id)
                                }
                            }
                        }
                    })
                    // console.log(this.loadReqDocs);
                },
                deep: true
            },
            doc_to_upload : {
                handler : function(object){
                    this.req_docs.forEach(rq_doc => {
                        this.doc_to_upload.forEach(doc => {
                            
                            if(rq_doc.document_type_id == doc.id){
                                doc["req_doc_id"] = rq_doc.req_doc_id
                            }
                        })
                    })
                }
            }
        },
        computed : {
            loadSetupBtn(){
                if(!this.seafarer.seaf_type_id){
                    return true;
                }

            },
             loadReqDocs(doc_ids){
                return this.doc_list.filter(doc => {
                    if(this.req_doc_ids.includes(doc.id)){
                        return this.req_doc_ids.includes(doc.id);
                    }
                });//
            },
            loadOptDocs(doc_ids){
                return this.doc_list.filter(doc => {
                    return this.opt_doc_ids.includes(doc.id)
                });
            }
            
        },
        mounted(){
            
        },
        created(){

        }
    }
</script>

<style scoped>
.doc_list{
    margin-left:15px
}
.optional_heading{
color:teal;
font-weight: bold !important
}
.required_heading{
    color:crimson;
    font-weight: bold !important
}
.push_down{
    margin-top:30px;
}
.entry_heading h5{
margin-bottom: -8px;
color:#191970;
text-transform: uppercase
}
    body {
        padding: 5%;
        font-size: 16px;
        line-height: 1.6;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    
    .form-group {
        margin-bottom: 0;
        padding: 10px 0;
    }
    
    .form-group:first-child {
        border-color: transparent;
    }
    
    .form-group.has-icon .control-group {
        position: relative;
    }
    
    .form-group.has-icon .control-group .btn-search {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 16px;
        height: 16px;
        border: transparent;
        background: transparent;
    }
    
    .form-group.has-icon .control-group .btn-search > .icon {
        top: 0;
        left: 0;
    }
    
    .form-group.has-icon .control-group .icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        fill: #757575;
    }
    
    .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
    }
    
    .btn-primary:hover {
        background-color: #566f7b;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
    
    label {
        cursor: pointer;
    }
    
    :-ms-input-placeholder {
        color: #ccc;
    }
    
    ::-moz-placeholder {
        color: #ccc;
    }
    
    ::-webkit-input-placeholder {
        color: #ccc;
    }
    /* control search */
    
    .control-search {
        -webkit-border-radius: 40px;
        border-radius: 40px;
    }
    /* group addon */
    
    .input-group-addon {
        border-width: 2px;
    }
    .paper_no_shw{
        padding:30px !important
    }
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    display: none !important
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel{
    font-size: 10px !important
}
.radio, .checkbox {
    margin-bottom: 0px !important
}

.doc_section{
    margin-top:15px !important
}

</style>