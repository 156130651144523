<template>
    
</template>

<script>
export default {
    created(){
        console.log("Hellow");
    }
}
</script>

<style scoped>

</style>




