var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { attrs: { name: "PageSizeSelect" } }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.query.limit,
            expression: "query.limit"
          }
        ],
        staticClass: "form-control input-sm -page-size-select",
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.query,
                "limit",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            function($event) {
              _vm.query.offset = 0 /* reset to the first page */
            }
          ]
        }
      },
      _vm._l(_vm.pageSizeOptions, function(i) {
        return _c("option", { domProps: { value: i } }, [_vm._v(_vm._s(i))])
      }),
      0
    ),
    _vm._v("\n  " + _vm._s(_vm.$i18nForDatatable("items / page")) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }