<template>
    <div>
        <div id="example-table"></div>
    </div>
</template>

<script>
import alert_mixins from '../mixins/alertify.jsx';
export default {
    mixins : [alert_mixins],
    props : {
        vessel : {

        }
    },
    data(){
        return {

        }
    },
    methods : {
        view(data){
            window.location = "/vessel/view/"+data.id
        },
        deleteVessel(data){
            const com_this = this;
             alertify.confirm('Are you sure to delete this vessel?',function(e){
                if(e){
                axios.post('/vessel/delete_vessel',{
                    id : data.getRow().getData().id
                }).then(res => {
                    if(res.data.status == "SUCCESS"){
                        com_this.alert({
                            alert_type : "success",
                            title : "Vessel deletion",
                            alert_msg : "success",
                            msg : "Vessel successfully deleted."
                        }).set({title: "Vessel deletions"});
                        data.getRow().delete()
                    }
                }).catch(res => {
                    alert(res.data)
                }) 
                    }
                }).set({title: "Vessel deletion"});
        }
    },
    mounted(){
                const com_this = this;
            var deleteIcon = function(){
                    return "<i class='fas fa-trash-alt'></i>";
                }
                var obj0 = {
                    formatter: deleteIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.deleteVessel(cell)}
                }
                var viewIcon = function(){
                    return "<i class='fas fa-file'></i>";
                }
                var obj1 = {
                    formatter: viewIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.view(cell.getRow().getData())}
                }

        var table = new Tabulator("#example-table", {
            height:"311px",
            layout:"fitColumns",
            ajaxURL:"/vessel/current_embarkation?vessel_id="+this.vessel.id,
            ajaxProgressiveLoad:"scroll",
            paginationSize:20,
            placeholder:"No Data Set",
            columns:[
                {title:"ID", field:"seaf_name", sorter:"string",width : 40},
                {title:"Seafarer Name", field:"seaf_name", sorter:"string"},
                {title:"Position",field: "seaf_position", sorter:"number"},
                {title:"Gender", field:"seaf_gender", sorter:"string"},
                {title:"Mob", field:"seaf_mob", align:"center"},
                {title:"Email", field:"seaf_email", sorter:"string", sortable:false},
                {title:"Embarkation Date", field:"start_date", sorter:"string", sortable:false},
                {title:"Disembarkation Date", field:"end_date", sorter:"string", sortable:false},
            ],
        });
        // table.addColumn(obj0)
        // table.addColumn(obj1)











    }
}
</script>

<style scoped>
.tabulator-col{
    height: 100% !important;
}


</style>
