<template>
</template>


<script>
import readXlsxFile from 'read-excel-file'
import payroll_table from '../../utils/payroll_table.vue';
import spreadsheet from '../../utils/spreadsheet.vue';
import FormData from '../../../scripts/form_data';
import alert_mixins from '../../mixins/alertify.jsx';
import vx_select from '../../utils/vx_select.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { format } from 'util';




export default {
    components : {payroll_table,vx_select,flatPickr,spreadsheet},
    mixins : [alert_mixins],
    data(){
       return {
            config: {
              wrap: true,
              altInput: true,
              dateFormat: 'm-Y',
              altFormat: 'm-Y',
              allowInput: true,
            },  
            error_bag : {
                slip_period : {
                  entered : false
                },
                vessel_id : {
                  entered : false
                },
                selected_row_header : {
                  entered : false
                }
              },
            company_id : "",
            vessel_id : "",
            excel_rows : [],
            seafarer_col_id : null,
            dispatch_mail : false,
            seafarer_header : true,
            show_slip_errs : false,
            slip_period : "",
            enable_process_payroll : false,
            selected_row_header : [],
            new_array : [],
            spread_msg : "Specify spreadsheet header by selecting row in table"
       }
    },
    methods : {
        set_excel_rows(rows){
          this.seafarer_header = true;
          this.seafarer_col_id = null;
          this.show_slip_errs = false;
          this.selected_row_header = [];
          this.spread_msg = "Specify spreadsheet header by selecting row in table";
          this.excel_rows = rows;
        },
        check_selected_period(){
          axios.get(check_selected_period, {
            params : {
              slip_period : this.slip_period,
              vessel_id : this.vessel_id
            }
          })
          .then(res => {
            if(res.data.status == "ERROR"){
              return false
            }
            if(res.data.status == "SUCCESSS"){
              return true
            }
          })
        },
        process_payslip(){
          if(this.errors_found){
            this.show_slip_errs = true;
            return null;
          }
          window.btn_process_slip = Ladda.create(document.getElementById("btn_process_slip"));
          btn_process_slip.start();
          var new_rows = this.excel_rows;
          for (let m_index = 0; m_index < new_rows.length; m_index++) {
            for (let index = 0; index < new_rows[m_index].length; index++) {
                if(new_rows[m_index][index] == "" || new_rows[m_index][index] == null){
                  new_rows[m_index][index] = ""
                }
              
            }
    
          }
          axios.post("/payroll/begin_process",{
            slip_period : this.slip_period,
            table_headers : this.selected_row_header,
            table_rows : new_rows,
            dispatch_mail : this.dispatch_mail,
            vessel_id : this.vessel_id
          }).then(res => {
            if(res.data.status == "SUCCESS"){
              this.alert({
                alert_type : "success",
                title : "Seafarer payslip",
                alert_msg : "success",
                msg : res.data.msg
                  }).set({onclose:function(){
                 window.location.reload();
                 }})
            }
            if(res.data.status == "ERROR"){
              this.alert({
                alert_type : "error",
                title : "Seafarer payslip",
                alert_msg : "error",
                msg : res.data.msg
                  })
                  btn_process_slip.stop()
            }
          })
        },
        selected_row(row){
          const com_this = this;
          this.selected_row_header = [];
          this.seafarer_col_id = null
          this.seafarer_header = true;
          row.forEach(function(value,index){
            if(value != null){
              if(value.toLowerCase() == "seafarer_id"){
                com_this.seafarer_col_id = value;
              }
            }
            com_this.selected_row_header.push({
              index : index,
              value : value,
              required : true
            })
          }); 
          if(com_this.seafarer_col_id == null){
              this.alert({
                alert_type : "error",
                title : "Seafarer payslip",
                alert_msg : "error",
                msg : "Please ensure slip has header / column 'SEAFARER_ID' "
                })
          this.show_slip_errs = true;    
          this.seafarer_header = false;  
          this.spread_msg = "Selection must contain header/column 'SEAFARER_ID'"
          }else{
            this.spread_msg = "Select header(s) to exclude from payslip"
          }
          
          
        },
        validateFields(){
          if(this.slip_period = ""){
            this.show_slip_errs = true;
          }
        },
        toggle_check(obj){
          this.selected_row_header.forEach(objc => {
            if(objc.index == obj.index){
              objc.required = !obj.required
            }
          });
        },
        bgn_process_slip(){
          process_btn.start()
            const com_this = this;
            var temp_rows = []
            const input = document.getElementById('excel_upload_file')
                readXlsxFile(input.files[0]).then((rows) => {
                    // com_this.excel_rows = rows;
                    temp_rows = rows;
                    process_btn.stop()
                     for (let indx = 0; indx < temp_rows.length; indx++) {
                  for (let index = 0; index < temp_rows[indx].length; index++) {

                      var formats = [
                          moment.ISO_8601,
                          "MM/DD/YYYY  :)  HH*mm*ss"
                      ];
                      if(moment(temp_rows[indx][index], formats, true).isValid()){
                        temp_rows[indx][index] = moment(temp_rows[indx][index],formats,"MM-DD-YYYY");
                      }
                    
                  }

                  this.excel_rows = temp_rows;
                  
                }
                })
               
    }
    },
    mounted(){ 
      window.process_btn = Ladda.create( document.getElementById('process_btn'));
        const com_this = this;
        $(".uploadlogo").change(function() {
            var filename = readURL(this);
            $(this).parent().children('span').html(filename);
        });

  // Read File and return value  
  function readURL(input) {
    var url = input.value;
    var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
    if (input.files && input.files[0] && (
      ext == "xls" || ext == "xlsx"
      )) {
      var path = $(input).val();
      var filename = path.replace(/^.*\\/, "");
      // $('.fileUpload span').html('Uploaded Proof : ' + filename);
      com_this.enable_process_payroll = true;
      return "Uploaded file : "+filename;
    } else {
      $(input).val("");
      return "Only spreadsheets are allowed!";
    }
  }
      
    },
    computed : {
      errors_found(){
        if(this.seafarer_header == false){
          return true;
        }
        if(this.error_bag.vessel_id.entered == false){
          return true;
        }
        if(this.error_bag.slip_period.entered == false){
          return true
        }
        if(this.error_bag.selected_row_header.entered == false){
          return true
        }
        return false;
      }
    },
    watch : {
      slip_period : {
        handler(){
          if(this.slip_period == ""){
            this.error_bag.slip_period.entered = false
          }else{
            this.error_bag.slip_period.entered = true
          }
        }
      },
  selected_row_header : {
    handler(){
      if(this.selected_row_header.length > 0){
        this.error_bag.selected_row_header.entered = true;
      }else{
        this.error_bag.selected_row_header.entered = false
      }
    }
  },
  vessel_id : { 
    handler(){
      if(this.vessel_id == "" || this.vessel_id == null){
        this.error_bag.vessel_id.entered = false;
      }else{
        this.error_bag.vessel_id.entered = true
      }
    }
  }
    }
}
</script>

<style scoped>

.head {
  font-size: 25px;
  font-weight: 200;
}

.blue-btn:hover,
.blue-btn:active,
.blue-btn:focus,
.blue-btn {
  background: transparent;
  border: solid 1px #27a9e0;
  border-radius: 3px;
  color: #27a9e0;
  font-size: 16px;
  margin-bottom: 20px;
  outline: none !important;
  padding: 10px 20px;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  height: 43px;
  margin-top: 0;
}

.fileUpload input.uploadlogo {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 42px;
}

/*Chrome fix*/
input::-webkit-file-upload-button {
  cursor: pointer !important;
  height: 42px;
  width: 100%;
}

 body {
        padding: 5%;
        font-size: 16px;
        line-height: 1.6;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    
    .form-group {
        margin-bottom: 0;
        padding: 10px 0;
    }
    
    .form-group:first-child {
        border-color: transparent;
    }
    
    .form-group.has-icon .control-group {
        position: relative;
    }
    
    .form-group.has-icon .control-group .btn-search {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 16px;
        height: 16px;
        border: transparent;
        background: transparent;
    }
    
    .form-group.has-icon .control-group .btn-search > .icon {
        top: 0;
        left: 0;
    }
    
    .form-group.has-icon .control-group .icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        fill: #757575;
    }
    
    .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
    .msg_error{
      color : crimson !important
    }
    .default_msg{
      color : teal !important
    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
        color: white !important
    }
    
    .btn-primary:hover {
        background-color: #38474f;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
    
    label {
        cursor: pointer;
    }
    
    :-ms-input-placeholder {
        color: #ccc;
    }
    
    ::-moz-placeholder {
        color: #ccc;
    }
    
    ::-webkit-input-placeholder {
        color: #ccc;
    }
    /* control search */
    
    .control-search {
        -webkit-border-radius: 40px;
        border-radius: 40px;
    }
    /* group addon */
    
    .input-group-addon {
        border-width: 2px;
    }
    .paper_no_shw{
        padding:30px !important
    }
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    display: none !important
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel{
    font-size: 10px !important
}
input[type="text"]{
    text-transform: uppercase !important
}

/* 
Based on Bootstrap's .sr-only class. 
General accessible hide class that also avoids potential weird scrolling 
some text-indent methods have if the element is focusable. 
If you are already using Bootstrap, you may use the .sr-only class
on the checkbox and radio input elements instead if you prefer. 
*/
.a11y-checkbox, 
.a11y-radio {
  position: absolute;
  border: 0;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
}

/* 
Gather the magic. 
<label> must be relative (psuedo elements will be position absolute)
& inline-block or block (e.g. not inline. so padding is applied consistantly across various browsers)
*/
.a11y-checkbox-label, 
.a11y-radio-label {
  position: relative;
  display: inline-block;
  margin: 0 0 5px 0;
  padding: 0 0 0 30px;
}

/*
Cast the magic.
Create the box where our custom images will live.
*/
input[type="checkbox"] + .a11y-checkbox-label:before,
input[type="radio"] + .a11y-radio-label:before {
  position: absolute;
  top: 0rem;
  left: 0;
  width: 26px;
  height: 26px;
  line-height: 26px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.form-group.form-control input{
  font-size: medium !important
}
/*
Invoke the magic.
Load the custom images as content rather than background images so they still render in high-contrast mode.
*/

/* Checkbox - Default State */
input[type="checkbox"] + .a11y-checkbox-label:before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAANElEQVR42mP4TyfAMGoR1SwqLCykCibKIkrBqEWjFo1aNGrRqEWjFlHDIrpUfKONk0FnEQAcTRTfX8LFVAAAAABJRU5ErkJggg==);
}

/* Radio Button - Default State */
input[type="radio"] + .a11y-radio-label:before {
  content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABQ0lEQVR42u1WSwqDMBDtoQVd6E7xs3LjEVzoKRT1DH5wr95A8TPlZRVoNWlKC4UOBOL83mQyM/FGX6LbH+ijQF3XUZqm5DgOaZrGFvbgQfY20DRNFEUReZ5HWZbROI60ritb2IMHGXSgqwTU9z1ZlkVVVdG+76cOICuKgkzTZDYvASE6gLRtK30H0IXN2ckegI7joDAMWZSvEmxgCx9CIETm+/5luq7SCNtnmXgASpKE8jxXLmPYwocQyLZtVlGqhDuCDyEQegTlq0rbtjEfQiDDMJiyKs3zLAfkuq6w+a5oGAa51MVx/HYxwIcQqK5rCoJAubxh2zTNZxu2LEv5huVHkOxk5kfQWWtIDdVnEfIZUB6q/MmQCjwFuGR8o/SXZWHVBR5k0FF+JviIcbkYKyhb9Iiu66wNwIPs6sT/n5PfAboDjREsqed+e4MAAAAASUVORK5CYII=);
}

/*
Mmmooorrree power! 
Swap the image based on state.
*/

/* Checkbox - Selected State */
input[type="checkbox"]:checked + .a11y-checkbox-label::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAA9UlEQVR42mP4TyfAMGoR1SySL9lPFTxq0eCxyG/i2f9Xn37+79h5knYW2bWf+P/2yy+wvitPPtPGIsP6I//vvf4G1vPh2+//Tl0U+ki75jCGmEblof9nH3wEq//5++//kKnnKIuj6JkXwK7NXnwVLqZUduD/jsuvwWr//fv/P3PRFcoTw6l7H8Biv//8+x83+yJYbMGRJ3C1TZtuUyfVgeLhxvMvYPHvv/7+X37iGVzd3EOPqZu8LZqP/X/45huKmm2XXoGDkOr5yL7jxP+XH3+C5U/f//BfvfIg7TKse++p/+cffvxvUHdktKwbKRaNNk4GnUUAgVikctopkvMAAAAASUVORK5CYII=);
}

/* Radio Button - Selected State */
input[type="radio"]:checked + .a11y-radio-label::before {
  content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABI0lEQVR42mP4TyfAMGoRTS06evvd/8o1N//bd5z4r1J+EIxBbJDYkVvvKLfozsuv/8Onn/8vX7IfLwapAakly6ITd9//16o+RNASGAapBekhySKQ63RqDhNtCQyD9ODyGYZF//79/x8w+SzJlsAwSC/IDIIWHQZGLrmWwPBhLAkEw6KK1TcotghkBkGL7NpP4DQgbvbF/y8//gRjEBuXOpAZBC0C5RFcBoAsgAEQG5c6kBkELcKXpJEtevWJQoscO0/iNCBhziWwBc8+/ACzcamzJSboCpdfozgxgMwgaNHBG28ptujQzbeDKMNSWgTdJrYIomuhiuwzYoIRpIbsagI5zkCRCypWQMlWufzAf82qQ/+duk6CxUBy2OJktM0w9CwCADnp82FkjM15AAAAAElFTkSuQmCC);
}

/* Checkbox - Default Focused State */
input[type="checkbox"]:focus + .a11y-checkbox-label::before {
  content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABIUlEQVR42u3Wz0rDQBCA8X0TX9JX8uDNuwePooJ40YtQQYxaKrWttmnzp2N/pQ8QMSwIWRgo6cz3ZWeX7KbYje02omm3Ue+iatpY122UVRurqvlVqFGLgYWJbSQ/PJTwvW5iuqxistjE22wdxWcZL9NuIVeNWgwszPogS81BsijrGM83MZqs4qH4irvnedw8zeJq1C3kqlGLgYWJzZEY2f3xOF7ui87vP+Ls9j1Or1/j5LLoFHLVqMXAwsTmSPppqt5CwtHxRS+BhYnNkUxNX03Z20j668DAwsTmSHaKRdRfU+9LhIWJzZFsSzvGYupzXyIsTGyOvcj21FOL2pcICxN7EA2iQfQfRVk+Qdk+qtmOiWwHX7ajPNvlJNt1K9cF8gfi5rg8W6WU/gAAAABJRU5ErkJggg==);
}

/* Radio Button - Default Focused State */
input[type="radio"]:focus + .a11y-radio-label::before {
  content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAACxUlEQVR42r2WW2sTURDH96so+qCIlzfBVxUU9Nt4QW0qCr4oYn1W8PZspb5V0LSbpuAFKhZi01jFxDQXm9rdjSbZ3Yznd7IRs91s01U6cJbDmfn/Z86cmXPWkBjpdkV89XH9rnQ8X9quL61gMGcNnacGtnFiRC32ySFrtj2xfrmy5nSkbrWlutEbzFlDhw22YPwhHo3wDrwgesDrzY6s/mjJ5/pP+bjqyIeSJQtfe4M5a+iwwRYM2KgdGmEnRGarKGsq4hVF8r5oydxyQ6YX6/J8oSqT7yp6MGcNHTbYggELR9jZH0d9J6SirCLMlR3JFtY14dVneTk78VYOpTKy+3xaD+asocMGWzBgrb+cDTgir2zZDpwslmx5lVuTuy++yLEb83L85mu5N1OU5WpTEzCYs4YOG2zBgIUDLjj7Z6YdcYjkl0POlW15mfsu16YKcuCKKY+z3wYiCwu6B5mS7LtkagxYOOCC0w2wBg6JkMNcqTV1zolu/2VTzHxDRpXZpYbGgIUDLjjhxofhBbuhcjjUKZXvo9ezOsrtysO5ksbCAReccOPDYGscHmVKJOOTeTmh8h6Xrrg0goUDLjjhxofBgdF49ATlekZV0v3ZoiQVsHDABSfc+DDIIV1OA1KmB8dMXVFJpaCwcMAFJ9z4MLi3qBC6nUakR1AkFaKHAy44KxstfTducrT34ow2TipOy4t2FE7d4fGM3n5SWao4A6mr9VMXLobTt9/8czGcUhybiiFc3qmneTl5K3l5gx2LKu9hDUvzbVcexTVs1BU0EVxBmQRX0J3pIVfQVpfqE3Wp+jHvNLqRLtVhzwQAdkYqeAo45E8qStfrBUV1sYYOG2zBxD4T0Q+frVNAvlPqcLlWKNtd59Ky50Jajqg2YA0dNtiCiX34op5ytk6eR37KlS2YLZ/yHfs52dHfragfSKL7Hz+QvwFay95sEEon3AAAAABJRU5ErkJggg==);
}

/* Checkbox - Selected Focused State */
input[type="checkbox"]:checked:focus + .a11y-checkbox-label::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAB/0lEQVR42r2Wz0sbQRTH9z8p9NKCl3rrqUUv3jx5E3ovvRZBEAU9qAUPPXiQIpQWKb2JhyJ48FfQGNHa1ogg2MYmURuricb82N3k634GFYtWs7rugwfhZd7nO/Pm7cxY8qxaldxKVY7ntltRyamoaFdUsF1fTg65MGDBhI1Z/CDIgHzJ1f6xrcxhWdvZklIHRSX3a3PGkkMuDFgwnVMxyz0VOSw62s2VtZkpaC11pJWtnBZ/ZrWwWZszlhxyYcCCCRsNC0XU+WNj99gkTcT3NLq8o8+xtEbmUzU5Y8khFwYsmLDRsKgnS2UWDHjwejIQhwUTNhoWS6OuLJnZBCUECyZsNCw6hU2kviw9KCFYMGGjYdGWdAybSZ2DEoIFEzYaRoj2pKZsql9g89slxb0SNfQv/BOHBRP2nYWe9Ub1N2+bD3I1eXQ/QvVdEdNVWLbgqPHNHVdU1zF7Kfa4fUZLv3JGpOx1VMvg8qUxvoRah1bMbF99jJ/HHrZNafxHRmdn5MsPq1eu2JdQzIthjlvVi3ffTOx9JKkz6x7b+G9pfQmxD+vbeQPlW/gUTZ+LDM/8vnYPfe/R0545JfYKumhfvv8xJQxUCH/eFzUHJMZH+Kh9+sauvHV7Nw3E9DWR05POSE3tf6VQKEdQaIdqaNdEaBdfaFd5aI+T0J5bYT0gTwDdRUgilpwj2QAAAABJRU5ErkJggg==);
}

/* Radio Button - Selected Focused State */
input[type="radio"]:checked:focus  + .a11y-radio-label::before {
  content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAACdklEQVR42r2W3UobQRTH502K0nqp9q7QJ1DQRxAforbemIiFXpbaW30GFb1TqClae6FC0gopKlpaiZr4UXcTzcduTue37ohddzdJIwmcMMz8P2bOnJlZJTG/el3E1X81ty5Vx5VKzZWyH7TpY8zRATbup8I6jThipYoj1k1NzotVKVgVOb26Ddr0MQYGLBw3wlEFV+D4s4d8WarK8Z+yHBau5cdxUb4fWZL+dRu06WMMDFg4cMNWqIImzMzWs8zrGR9okcxvS77sXcjyTkGW0qcyv33iBW36GAMDFg5cNIJmd0bGhFTk9AyzuaJs7F96gpMLuzI8vSV9iXXpfp3ygjZ9jIEBCweudc/sHyPyypJt32TnyJbV7Ll8XPkpL999lSevVmMDDFg4cNFAC02zZ54Rm0h+2eRszpZP2TOZWtyXZ+OfG5qYAAsHLhpooVnzV6UwZJls5kG+5OWc2fW0YGICDlw00EITbTyU46+GymFTF3W+X7zdaNnEBFw00EITbTwUS2PzKFNmkpzf/W8TE2ighSbaeCg2jIPHmaBch3QltWuEBlpooo2HIoeccg4gZdo7sRYpMDKTubsZaEfh0EALTbTxUNxbEDntHETOSJQAOPOjHYVDAy00T67K3t34wCiupO8bcQu0ZBRMXX9yPVJgdPabZ8CBpN1M6vImdcFiGHy/2XYxDGiNB8UQLO/EXPvlPRFW3h07sGFX0HQbV9CH5YgrqGOXatQzAYGVNZNGMGDhxD4T4Q+f7aWAfCf05nKtULZdYyl5+iYlz/UxoI8xMGDhxD58YU85SyfPTT/lGgun4VPesY+Tjn5uhX1AMrvH+ID8C1mcpWxuv0aqAAAAAElFTkSuQmCC);
}

/* Checkbox - Disabled State */
input[type="checkbox"]:disabled + .a11y-checkbox-label {
  color:#666;
}
input[type="checkbox"]:disabled + .a11y-checkbox-label::before {
  content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAkUlEQVR42u3WMQrAIAwFUO9/E0UEQXB0chHvlBJB6NIaYywUDHySIfDIFgUflTqQGGSMAa116ysZQohYayGlBLVWVkgQLuWcwTkHMca9EC6XUsB7z8KmoBVsGuJiLIiDsaFZbAmawZYhKiYCUTAxaISJQm+YOPSEbYHuWAihzdugjuFVCG6FehAjQxI5z8n/oAtIFaAVSCl5xgAAAABJRU5ErkJggg==);
}

/* Radio Button - Disabled State */
input[type="radio"]:disabled + .a11y-radio-label {
  color:#666;
}
input[type="radio"]:disabled + .a11y-radio-label::before {
  content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABSElEQVR42u2WWYqEMBCG+/5HEVdERXF5EBWXE3gBlze9QA1/IEOQdCex6YGBbigQU399lViV6gf90e/xBX0UtG0bTdNEcRyTbdvM8Ix3WHsbdJ4nVVVFvu9TURTUdR2N48gMz3iHNfjA9xZo33fyPI/KsmSZvzL4wBcaIxCycxyHwjBUQrjVdc1292xnUhCOJM9zCoKAkiTRhmFn0GqB1nVlmUE4DIMxDFrEUILgjKy40BTGtUoQyhYVJYpNYNAihhKEHkH5XgPowqBFDCXIdV0pSISJRyvz0QIh4+vRidY0DQuEJpWtt21LURSpQX3fv8xYBYMWMZSgZVl+y/sOTLu8ecPqXD1XmFHD8isImeFa0YWlaco0x3F87lLNsowsy6J5nu+PCT4KrmMC1SWuqeaS1uDDx0XmKFscE3qNN67sw3//M/xP0A85wNINWnbF9wAAAABJRU5ErkJggg==);
}

.vx_selct_btn {
  position: relative;
  padding : 5px 8px 5px 8px
}

.button-group input {
  display: none;
}

.button-group input + label{
background-color: grey
}
.button-group input:checked + label,
.button-group input:checked + label:active {
  background-color: darkseagreen;
}


</style>

