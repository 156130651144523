<template>
    <div class="row">
        <div class="tab-wrap full-width-tabs col-md-10" style="padding: 0 !important">

            <ul class="nav nav-tabs">
              <li v-for = "(tab,index) in tabs" :class="[{active : tab.tab_active},'take-all-space-you-can']"
                data-toggle="tab" :href="'#'+id_gen(tab,index)"
                @click.prevent="selectTab(tab,index)" 
               >
                <a class="vx-tab-bg" data-toggle="tab" href="#home">{{tab.title}}</a></li>
            </ul>

            <div class="tab-content" v-for = "(tab,index) in tabs">
              <div v-html="tab.$el.innerHTML" :id="id_gen(tab,index)" :class="[{active : tab.tab_active},{'in' : tab.tab_active},'tab-pane','fade']"></div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    data(){
      return {
        tabs : []
      }
    },
    methods : {
      id_gen(tab,index){
        return tab.title.toLowerCase().replace(/ /g,'_')+"_"+index;
      },
      selectTab(clicked_tab){
        const com_this = this;
        this.tabs.forEach(tab => {
          tab.tab_active = (tab.title == clicked_tab.title);
          if(tab.title == clicked_tab.title){
            com_this.$emit("update_id",clicked_tab.component_id);

          }
        });
      }
    },
    mounted(){
      this.tabs = this.$children;
    }
}
</script>

<style scoped>
.tab-wrap {
  transition: 0.3s box-shadow ease;
  border-radius: 6px;
  max-width: 100%;
  position: relative;
  list-style: none;
  background-color: #fff;
  margin: 20px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.tab-wrap:hover {
  box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
}
.tab {
  display: none;
}
.tab:checked:nth-of-type(1) ~ .tab__content:nth-of-type(1) {
 
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(2) ~ .tab__content:nth-of-type(2) {
 
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(3) ~ .tab__content:nth-of-type(3) {
 
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(4) ~ .tab__content:nth-of-type(4) {
 
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(5) ~ .tab__content:nth-of-type(5) {
 
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative; 
  top: 0;
  z-index: 100;
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(6) ~ .tab__content:nth-of-type(6) {
 
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:first-of-type:not(:last-of-type) + label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tab:not(:first-of-type):not(:last-of-type) + label {
  border-radius: 0;
}
.tab:last-of-type:not(:first-of-type) + label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tab:checked + label {
   background-color :#008B8B;
  color: whitesmoke;
  box-shadow: 0 -1px 0 #fff inset;
  cursor: default;
}
.tab:checked + label:hover {
  box-shadow: 0 -1px 0 #fff inset;
  background-color :#008B8B;
  color: whitesmoke
}
.tab + label {
  box-shadow: 0 -1px 0 #eee inset;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #333;
  flex-grow: 3;
  text-align: center;
  background-color: #f2f2f2;
  user-select: none;
  text-align: center;
  transition: 0.3s background-color ease, 0.3s box-shadow ease;
  height: 30px;
  box-sizing: border-box;
  padding: 5px;
  font-size:smaller;
  word-wrap: break-word !important;
}
.tab + label:hover {
  background-color: #f9f9f9;
  box-shadow: 0 1px 0 #f4f4f4 inset;
}
.tab__content {
  padding: 10px 25px;
  background-color: transparent;
  width: 100%;
  left: 0;
  border-radius: 6px;
}

h1, h2 {
  margin: 0;
  color: #444;
  text-align: center;
  font-weight: 400;
}
h2 {
  font-size: 1em;
  margin-bottom: 30px;
}
h3 {
  font-weight: 400;
}
p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.full-width-tabs > ul.nav.nav-tabs {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.full-width-tabs > ul.nav.nav-tabs > li {
    float: none;
    display: table-cell;
}
.full-width-tabs > ul.nav.nav-tabs > li > a {
    text-align: center;
}
.take-all-space-you-can{
    width:100%;
}
li.active a{
  border-top-right-radius: 0 !important;
border-bottom-right-radius: 0 !important;
background-color: #008B8B !important;
color: whitesmoke !important;
cursor: default !important;
}

.nav-tabs > li > a {
  margin-right: 0 !important;
  padding:5px !important

}


.take-all-space-you-can:hover a{
  text-decoration: none;
}
.nav-tabs {
  padding-right:0 !important
}
 .vx-tab-bg{
  background-color: #f2f2f2 !important;
  border-radius: 0 !important;
  color:black !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

}
.nav-tabs li:last-child a{
  border-top-right-radius: 6px !important
}
.nav-tabs li:first-child a{
  border-top-left-radius: 6px !important
}


li{
  margin: 0 !important
}

.tab-wrap ~ div{
 display:none !important
}


</style>


