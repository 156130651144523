var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    { attrs: { multiple: _vm.multiple_sel } },
    [_c("option"), _vm._v(" "), _vm._t("default", [_c("option")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }