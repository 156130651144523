class FormError{
	constructor(){
		this.errors = {};
		this.err_keys = null;
		this.error_msg = null
	}

	loadErrors(errors){
		this.errors = errors;
		//return Object.keys(this.errors);
	}

	deleteError(field){
		delete this.errors[field];
	}

	getErrors(){
		return this.errors;
	}

	getErrorfields(){
	 return this.errors ? Object.keys(this.errors) : []
	}

	setErrorMsg(msg){
		this.error_msg = msg;
	}
	getErrorMsg(){
		return this.error_msg;
	}

	clear(field) {
        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};
    }

	

	clearFieldError(strip_field){
	  var field = strip_field.split("[");

	  var rm_field = null;

	  if(field.length < 2){
		rm_field = field[1].split("]")[0];
	  }else{
		rm_field = field[1].split("]")[0];
			for (let index = 2; index < field.length; index++) {
				rm_field = rm_field + "." + field[index].split("]")[0];
			}
		}
		var san_nested = rm_field.split("_attributes.0.");
		if(san_nested.length > 0){
			rm_field = san_nested.join(".");
		}
		console.log(rm_field);
		delete this.errors[rm_field];
	}

}

export default FormError;