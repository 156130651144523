var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user_is_admin
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-md-4",
              staticStyle: { "margin-bottom": "5px" }
            },
            [
              _c("label", [_vm._v("Filter by User/Author")]),
              _vm._v(" "),
              _c("vx_select", {
                attrs: {
                  remote_options: true,
                  remote_url: "/search/get_users",
                  placeholder: "User"
                },
                model: {
                  value: _vm.user_id,
                  callback: function($$v) {
                    _vm.user_id = $$v
                  },
                  expression: "user_id"
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "paper_no_shw col-md-12",
        staticStyle: { "margin-bottom": "15px" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-4 col-xs-12 col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                _vm._v("Vessel Name")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.vessel_name,
                    expression: "vessel_name"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.vessel_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.vessel_name = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3" },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                    _vm._v("Company")
                  ]),
                  _vm._v(" "),
                  _c("vx_select", {
                    attrs: {
                      remote_options: true,
                      remote_url: "/search/get_companies",
                      placeholder: "Companies"
                    },
                    model: {
                      value: _vm.company_id,
                      callback: function($$v) {
                        _vm.company_id = $$v
                      },
                      expression: "company_id"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3" },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                    _vm._v("Flagship")
                  ]),
                  _vm._v(" "),
                  _c("vx_select", {
                    attrs: {
                      remote_options: true,
                      remote_url: "/search/get_countries",
                      placeholder: "Flagship"
                    },
                    model: {
                      value: _vm.country_id,
                      callback: function($$v) {
                        _vm.country_id = $$v
                      },
                      expression: "country_id"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-offset-0 col-lg-1 col-xs-12 col-sm-1" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { "margin-top": "15px" },
                  on: { click: _vm.search }
                },
                [_vm._v("Search")]
              )
            ]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "project box col-md-12",
      staticStyle: { "margin-bottom": "15px" },
      attrs: { id: "example-table" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }