<template>
    <div>
        <slot name="tab_content"></slot>
    </div>
</template>

<script>

export default {
    props : {
        title : {
            required : true,
            type : String
        },
        is_active : {
            default : false,
            type : Boolean
        },
        component_id :{
            type : [String,Array,Object]
        }
    },
    data(){
        return {
            tab_active : null
        }
    },
    mounted(){
        this.tab_active = this.is_active;
    }
}
</script>

