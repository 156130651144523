var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.useComplexMode
    ? _c(
        "div",
        { staticClass: "-complex-table", attrs: { name: "ComplexTable" } },
        [
          _vm._l(["Header", "Body", "Footer"], function(x) {
            return [
              x !== "Footer" || (x === "Footer" && _vm.summary)
                ? _c(
                    "div",
                    {
                      ref: "wrappers",
                      refInFor: true,
                      class: "-table-" + x.toLowerCase(),
                      style: [
                        x !== "Header" && {
                          marginTop: "-" + _vm.scrollWidth + "px"
                        },
                        x === "Body" && {
                          maxHeight: _vm.fixHeaderAndSetBodyMaxHeight + "px"
                        }
                      ],
                      attrs: { name: "Table" + x + "Wrapper" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { name: "NormalTable" + x } },
                        [
                          _c(
                            "table-frame",
                            _vm._b(
                              {},
                              "table-frame",
                              _vm.propsToNormalTable,
                              false
                            ),
                            [
                              _c(
                                "Table" + x,
                                _vm._b(
                                  { tag: "component" },
                                  "component",
                                  _vm.propsToNormalTable,
                                  false
                                )
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.leftFixedColumns.length
                        ? _c(
                            "div",
                            {
                              staticClass: "-left-fixed -fixed-table",
                              style: { left: _vm.offsetLeft + "px" },
                              attrs: { name: "LeftFixedTable" + x }
                            },
                            [
                              _c(
                                "table-frame",
                                _vm._b(
                                  { attrs: { "left-fixed": "" } },
                                  "table-frame",
                                  _vm.propsToLeftFixedTable,
                                  false
                                ),
                                [
                                  _c(
                                    "Table" + x,
                                    _vm._b(
                                      {
                                        tag: "component",
                                        attrs: { "left-fixed": "" }
                                      },
                                      "component",
                                      _vm.propsToLeftFixedTable,
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.rightFixedColumns.length
                        ? _c(
                            "div",
                            {
                              staticClass: "-right-fixed -fixed-table",
                              style: { right: "-" + _vm.offsetLeft + "px" },
                              attrs: { name: "RightFixedTable" + x }
                            },
                            [
                              _c(
                                "table-frame",
                                _vm._b(
                                  { attrs: { "right-fixed": "" } },
                                  "table-frame",
                                  _vm.propsToRightFixedTable,
                                  false
                                ),
                                [
                                  _c(
                                    "Table" + x,
                                    _vm._b(
                                      {
                                        tag: "component",
                                        attrs: { "right-fixed": "" }
                                      },
                                      "component",
                                      _vm.propsToRightFixedTable,
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _c(
        "div",
        { attrs: { name: "SimpleTable" } },
        [
          _c(
            "table-frame",
            _vm._b({}, "table-frame", _vm.propsToNormalTable, false),
            [
              _c(
                "table-header",
                _vm._b({}, "table-header", _vm.propsToNormalTable, false)
              ),
              _vm._v(" "),
              _c(
                "table-body",
                _vm._b({}, "table-body", _vm.propsToNormalTable, false)
              ),
              _vm._v(" "),
              _vm.summary
                ? _c(
                    "table-footer",
                    _vm._b({}, "table-footer", _vm.propsToNormalTable, false)
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }