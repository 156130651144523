var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [_c("code", [_vm._v("query: " + _vm._s(_vm.query))])]),
      _vm._v(" "),
      _c("datatable", _vm._b({}, "datatable", _vm.$data, false), [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: { disabled: !_vm.selection.length },
            on: { click: _vm.alertSelectedUids }
          },
          [
            _c("i", { staticClass: "fa fa-commenting-o" }),
            _vm._v("\n      Alert selected uid(s)\n    ")
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }