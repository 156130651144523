var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "btn-group btn-group-sm" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-default",
        class: { "-nested-comp-open-btn": _vm.isDisplayRowVisible },
        attrs: { title: "Display row" },
        on: {
          click: function($event) {
            return _vm.toggleNestedComp("DisplayRow")
          }
        }
      },
      [_c("i", { staticClass: "fa fa-list-ul" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-default",
        class: { "-nested-comp-open-btn": _vm.isDisplayRowVisible },
        attrs: { title: "View Record" },
        on: {
          click: function($event) {
            return _vm.viewRecord()
          }
        }
      },
      [_c("i", { staticClass: "fa fa-file" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-default",
        class: { "-nested-comp-open-btn": _vm.isDisplayRowVisible },
        attrs: { title: "Delete Record" },
        on: {
          click: function($event) {
            return _vm.deleteRow()
          }
        }
      },
      [_c("i", { staticClass: "fa fa-trash" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }