import alert_mixins from './alertify.jsx';

var form_mix = {
  mixins : [alert_mixins],
    data(){
        return {
            alertifed : false,
            toasted : false,
            alertOject : null,
            form_validation : false,
            save_mode : "CREATE_RECORD",
            record_id : null
        }
    },
    methods : {
        resetFields(){
          $(".vx-field-error").remove();
          $(".vx-input-error").removeClass('vx-input-error');
        },
        errorValidation(){
        },
        insertAfter(newNode, referenceNode) {
            var elementExists = referenceNode.nextSibling;
            if(elementExists){
              elementExists.parentNode.removeChild(elementExists);
            }
            referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
          },
          toTitleCase(str) {
            return str.replace(
              /\w\S*/g,
              function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
              }
              );
          },
          object_size(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        },
          stripFormErrorMsg(text,start,end){
            return text.slice(start,end);
          },
          clearNameFields(field){
            if(field.getAttribute('name') == "seafarer[pd_firstname]" || field.getAttribute('name') == "seafarer[pd_othername]" || field.getAttribute('name') == "seafarer[pd_lastname]"){
              var field_names = ["seafarer_pd_lastname","seafarer_pd_othername","seafarer_pd_firstname"]
              for(var field_name of field_names){
                var field_el = document.getElementById(field_name)
                var field_er = $("#"+field_name).parent().next().text();
                var res = field_er.match(/(Match Found)/g);
                if(res){
                  this.clearErrorField(field_el,false,false)
                }
                
              }
            }
          },
          clearErrorField(field,custom = false,check_name_field = true){
            if(check_name_field == true){
              this.clearNameFields(field);
            }
            if(custom){
              var elementExists = field.parentElement;
            }else{
             var elementExists = field.parentElement.parentElement;
            }
            if(elementExists){
              const rem_err = elementExists.getElementsByClassName('vx-field-error')[0];
              if(typeof rem_err !== 'undefined'){
                field.classList.remove('vx-input-error');
                elementExists.removeChild(rem_err);
                console.log(field.name);
                this.formData.errors.clearFieldError(field.name);
              }
           }
      },
      clear_errors(element){
        this.clearErrorField(element);
      }
    },
    mounted(){
      var com_this = this;
      com_this.$options.form_ids.forEach(form_id => {
        const events  = ["keydown","change"];
        events.forEach(evnt => {
          document.getElementById(form_id).addEventListener(evnt, function(e) {
            com_this.clearErrorField(e.target);
          });
        });
        $('#'+form_id).on('focus', 'input[type=number]', function (e) {
          $(this).on('mousewheel.disableScroll', function (e) {
            e.preventDefault()
          })
        })
        $('#'+form_id).on('blur', 'input[type=number]', function (e) {
          $(this).off('mousewheel.disableScroll')
        })
      });
    },
    watch : {
      formData : {


        handler : function(formData){
            if(formData.errors.getErrorfields().length > 0){
              for(let field of formData.errors.getErrorfields()){

                var nested_field = field.split(".");

                if(nested_field.length > 1){
                  var field_elmnts = "["+nested_field[0]+"_attributes"+"][0]";
                  for (let index = 1; index < nested_field.length; index++) {
                    field_elmnts = field_elmnts + "["+nested_field[index]+"]" 
                  }
                }else{
                  field_elmnts = "["+field+"]";
                }

                var object_name = this.$options.object_name;
                
                var exact_field = object_name + field_elmnts;

                var element = document.getElementsByName(exact_field);
                if(element){
                  var el_name = null;
                  element.forEach(elmt => {
                    var e = document.createElement('span');
                    e.classList.add('vx-field-error');
                    var msg = field +" "+ formData.errors.getErrors()[field][0];
                    msg = this.stripFormErrorMsg(msg);
                    e.innerHTML = this.toTitleCase(msg.replace("_"," "));
                    if(elmt.nodeName == el_name || el_name == null){
                      elmt.classList.add('vx-input-error');
                      this.insertAfter(e,elmt.parentElement)
                    }
                    el_name = elmt.nodeName;
                  });
                }else{
                  console.log(exact_field +" doesn't Exist on Form");
                }
              }
              this.toasted = true;
            }
            if(formData.errors.getErrorfields().length < 1){
              this.toasted = false;
            }
          },
          deep: true
        },
      toasted : {
        handler : function(toast){
          if(toast){
            if(this.formData.alertify){
             this.alertOject =  this.toast({
                alert_type : 'error',
                msg : this.formData.errors.getErrorMsg(),
                delay : 500,
              });
            }
          }
          if(!toast){
            if(this.alertOject){
              this.alertOject.dismiss();
            }
          }
        }
      }  
    }
}

export default form_mix;