<template>
    
</template>


<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import alert_mixins from '../../mixins/alertify.jsx';


export default {
    mixins : [alert_mixins],
components: {
      flatPickr
    },
    data(){
        return {
            date: moment(new Date()).add(1,'days').format("YYYY-MM-DD HH:mm:ss"),
        // Get more form https://chmln.github.io/flatpickr/options/
            config: {
                wrap: true,
                altFormat: 'M	j, Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                enableTime: true,
                allowInput: true,
            },  
            show_gen_link : false,
            mobile_check : null,
            email_check : true,
            enc_key : null,
            secret : null,
            email_add : "",
            mobile_no : "",
            remote_link : "",
        }
    },
    methods : {
        generate_link(){
            gen_btn.start();
            const com_this = this;
            axios.get('/seafarer/gen_remote_link',{
                params : {
                    date : this.date
                }
            })
                  .then(res => {
                      console.log(res);
                      if(res.data.status == "SUCCESS"){
                          gen_btn.stop()
                          com_this.remote_link = res.data.data.url;
                          com_this.show_gen_link = true;
                          com_this.enc_key = res.data.data.enc_key;
                          com_this.secret = res.data.data.secret;
                      }
                  })
        },
        send_remote_link(){
            const com_this = this;
            save_btn.start();
            axios.post('/seafarer/send_remote_link',{
                date: this.date,
                email_add: this.email_add,
                mobile_no: this.mobile_no,
                url: this.remote_link,
                enc_key: this.enc_key,
                secret: this.secret
            })
            .then(res => {
                if(res.data.status == "SUCCESS"){
                    save_btn.stop()
                        com_this.alert({
                            alert_type : "success",
                            title : "Remote registration",
                            alert_msg : "success",
                            msg : "Registration link sent"
                        }).set({onclose:function(){
                               window.location.reload();
                               }})
                }
            })
        }
    },

    computed : {
        phone_check(){
            return this.mobile_check
        },
        mail_check(){
            return this.email_check
        },
        btn_send_active(){
            if( this.phone_check ==  true || this.mail_check == true){
                if(this.phone_check && this.mobile_no == ""){
                    return true
                }
                if(this.email_check && this.email_add == ""){
                    return true
                }
                return false
            }else{
                return true;
            }
        }
    },
    mounted(){
        window.save_btn = Ladda.create( document.getElementById('remote_save_record'));
        window.gen_btn = Ladda.create( document.getElementById('gen_btn'));
        
    }
    
}
</script>

<style scoped>
.btn{
    height: 86% !important
}
.button-group-pills .btn {
  border-radius: 15px;
  line-height: 0.7;
  margin-left: 10px;
  border-color: #bbbbbb;
  background-color: #FFF;
  color: #14a4be;
}
.button-group-pills .btn.active {
  border-color: #14a4be;
  background-color: #14a4be;
  color: #FFF;
  box-shadow: none;
}
.button-group-pills .btn:hover {
  border-color: #158b9f;
  background-color: #158b9f;
  color: #FFF;
}

    body {
        padding: 5%;
        font-size: 16px;
        line-height: 1.6;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    
    .form-group {
        margin-bottom: 0;
        padding: 10px 0;
    }
    
    .form-group:first-child {
        border-color: transparent;
    }
    
    .form-group.has-icon .control-group {
        position: relative;
    }
    
    .form-group.has-icon .control-group .btn-search {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 16px;
        height: 16px;
        border: transparent;
        background: transparent;
    }
    
    .form-group.has-icon .control-group .btn-search > .icon {
        top: 0;
        left: 0;
    }
    
    .form-group.has-icon .control-group .icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        fill: #757575;
    }
    
    .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
        color: white !important
    }
    
    .btn-primary:hover {
        background-color: #38474f;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
    
    label {
        cursor: pointer;
    }
    
    :-ms-input-placeholder {
        color: #ccc;
    }
    
    ::-moz-placeholder {
        color: #ccc;
    }
    
    ::-webkit-input-placeholder {
        color: #ccc;
    }
    /* control search */
    
    .control-search {
        -webkit-border-radius: 40px;
        border-radius: 40px;
    }
    /* group addon */
    
    .input-group-addon {
        border-width: 2px;
    }
    .paper_no_shw{
        padding:30px !important
    }
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    display: none !important
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel{
    font-size: 10px !important
}
input[type="text"]{
    text-transform: uppercase !important
}
</style>

