import Vue from 'vue/dist/vue.esm'
// import SuiVue from 'semantic-ui-vue';
import vueJquery from 'vue-jquery';
import VueRouter from 'vue-router';
import VueDataScooper from './vue_scooper.js';
import Datatable from 'vue2-datatable-component'

import VuePersist from 'vue-persist'
import * as Ladda from 'ladda';




import VueAlertify from 'vue-alertify';
 // import Moment from 'moment/moment.js';
// import $ from 'jquery';

// import '@progress/kendo-ui';
// import '@progress/kendo-theme-default/dist/all.css'
// import { Upload, UploadInstaller } from '@progress/kendo-upload-vue-wrapper';
import JSZip from 'jszip';



window.Vue = Vue;
// window.Vuex = Vuex;
// window.moment = new Moment();
window.axios = require('axios');
window.Ladda = require('ladda');
window.alertify = require('alertifyjs')
window.iziToast = require('izitoast')
window.JSZip = require('jszip')

iziToast.settings({
    timeout: 3000, // default timeout
    resetOnHover: true,
    // icon: '', // icon class
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
    position: 'topRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
    onOpen: function () {
      console.log('callback abriu!');
    },
    onClose: function () {
      console.log("callback fechou!");
    }
  });


// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// const csrfToken = document.querySelector("meta[name=csrf-token]").content;
// window.axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

// Vue.use(SuiVue);
Vue.use(vueJquery);
// Vue.use(VueAlertify);
// Vue.use(UploadInstaller)
// Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueDataScooper);
// Vue.use(Datatable)
Vue.use(VuePersist)

const locale = {}

  // this might be the simplest i18n solution
Vue.prototype.$i18nForDatatable = srcTxt => locale[srcTxt] || srcTxt
Vue.config.devtools = true
