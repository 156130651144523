<template>
  <div class="-nested-dsp-row-comp">
    <button class="btn btn-xs btn-link -nested-dsp-row-close-btn"
      @click="nested.$toggle(false)">
      <i class="fa fa-times fa-lg"></i>
    </button>
    <dl class="dl-horizontal">
      <div class="row">
      <template v-for="(val, key) in row">
            <div class="col-md-12" v-if = "Array.isArray(val)">
              <h5 class="vx-bold-text1" style="text-transform:uppercase;margin-bottom:5px !important; margin-top:15px;!important">{{ key }}</h5>
              <div class="col-md-12">
                <div class="">
                  <table class="table">
                    <tbody>
                      <tr v-for = "(values,index) in val">
                       <th v-for = "(valu,indx) in values">
                         
                           <td>{{val[indx]}}</td>
                         
                       </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="col-md-3">
                <div class="heading_title">
                    <div class="heading_text">
                        <h5 class="vx-bold-text1">{{ key }}</h5>
                    </div>
                    <div class="heading_text">
                        <h2>{{ val }}</h2>
                    </div>
                </div>
            </div>

      </template>
        </div>

    </dl>
  </div>
</template>
<script>
export default {
  props: ['row', 'nested']
}
</script>
<style>
.-nested-dsp-row-comp {
  position: relative;
  padding: 10px;
}
.-nested-dsp-row-close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
