var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ]
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-lg",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.scanImage()
              }
            }
          },
          [_vm._v(" Documents")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-lg",
            attrs: { type: "button" },
            on: { click: _vm.showModalDemo }
          },
          [_vm._v(" Demo")]
        ),
        _vm._v(" "),
        _c("br")
      ]
    ),
    _vm._v(" "),
    _c("canvas", {
      staticStyle: { display: "none" },
      attrs: { id: "the-canvas" + _vm.unq_id_gen }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: { role: "dialog", id: "scan_modal" + _vm.unq_id_gen }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "col-md-5" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row Content",
                      attrs: { id: "selectedFiles" + _vm.unq_id_gen }
                    },
                    _vm._l(_vm.scannedImages, function(scanImage, index) {
                      return _c(
                        "div",
                        {
                          staticClass: "col-sm-5 col-md-5 text-center snip0013"
                        },
                        [
                          _c("img", {
                            staticClass: "selFile",
                            attrs: {
                              height: "100%",
                              width: "100%",
                              src: scanImage.image_base_64,
                              "data-file": scanImage.data_file,
                              title: "Click to remove"
                            }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            _vm._s(scanImage.scan_no) +
                              "\n                                "
                          ),
                          _c("div", [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.delScanDoc(index)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-trash left-icon"
                                })
                              ]
                            )
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-7" }, [
                  _c(
                    "div",
                    { attrs: { id: "iframeContainer" + _vm.unq_id_gen } },
                    [
                      _c("iframe", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.scan_prev_show,
                            expression: "scan_prev_show"
                          }
                        ],
                        attrs: {
                          src: "",
                          id: "pdf_prev_iframe" + _vm.unq_id_gen
                        }
                      })
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c("div", [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loadDemo,
                          expression: "loadDemo"
                        }
                      ],
                      staticClass: "btn btn-info",
                      attrs: { type: "button" },
                      on: { click: _vm.loadDemoScan }
                    },
                    [_vm._v("Scan Demo Scan")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.scanImage()
                        }
                      }
                    },
                    [_vm._v("Scan Documents")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: {
                        id: "attachFile" + _vm.unq_id_gen,
                        type: "button"
                      },
                      on: { click: _vm.sendFile }
                    },
                    [_vm._v("Attach Scanned File")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: { click: _vm.modalClose }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal-header", staticStyle: { padding: "0 !important" } },
      [
        _c("div", { staticStyle: { width: "100%", overflow: "hidden" } }, [
          _c("div", { staticStyle: { width: "60px", float: "left" } }, [
            _c("button", { staticClass: "print-button" }, [
              _c("span", { staticClass: "print-icon" })
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-left": "60px", padding: "20px 0px" } },
            [
              _c("h4", { staticStyle: { "text-transform": "uppercase" } }, [
                _vm._v("Dynamic Positioning (Advanced) "),
                _c("i", { staticClass: "fas fa-check-circle" })
              ])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }