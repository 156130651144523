<template>
    <div>
        <div class="row" v-if="is_super_admin">
            <div class="col-md-4" style="margin-bottom:5px">
                <label>Filter by User/Author</label>
                <vx_select v-model="user_id" :remote_options="true" :remote_url="'/search/get_users'" placeholder="User">
                </vx_select>
            </div>
        </div>
  
        <div class="d-flex flex-nowrap bd-highlight">
            <div class="p-2 bd-highlight col-md-3">
                <label for="exampleInputEmail1">Company</label>
                        <vx_select 
                        v-if="is_super_admin == true"
                            filter_field = "user_id" 
                            :filter_value = "user_id"
                            v-model="company_id"
                            :remote_options="true"
                            :remote_url="'/search/get_companies'"
                            placeholder="Companies">
                        </vx_select>

                        <vx_select 
                        v-if="is_super_admin == false"
                            v-model="company_id"
                            :remote_options="true"
                            :remote_url="'/search/get_companies'"
                            placeholder="Companies">
                        </vx_select>
            </div>
            <div class="p-2 bd-highlight col-md-3">
                <label for="exampleInputEmail1">Vessel</label>
                        <vx_select 
                            v-model="vessel_id"
                            :filter_value = "company_id"
                            filter_field = "company_id"
                            :remote_options="true"
                            :remote_url="'/search/get_vessels'"
                            placeholder="Vessel">
                        </vx_select>
            </div>
            <div class="p-2 col-md-2 bd-highlight">
                <!-- <input type="text" v-model="seafarer_id" style="margin:20px 0px 0px 5px" placeholder="Seafarer ID" class="form-control"> -->

                <div class="" :id="'container'+unique_id">
                    <label for="">Go to period</label>
                    <vx_select v-if="is_super_admin == false" filter_field = "vessel_id" :filter_value = "vessel_id"  :payslip="true" v-model="selected_period" :options="select_options" placeholder="Select Period">
                    </vx_select>

                    <vx_select v-if="is_super_admin" filter_field = "vessel_id" :filter_value = "vessel_id" :payslip="true" v-model="selected_period" :options="select_options" placeholder="Select Period">
                    </vx_select>
                </div>            </div>
            <div class="p-2 col-md-3 bd-highlight">
                <input type="text" v-model="seaf_name" style="margin:20px 0px 0px 5px" placeholder="Seafarer's Name" class="form-control">
            </div> 
        </div>
            <div class="d-flex flex-nowrap bd-highlight">
                <div class="p-2 bd-highlight">
                    <button :disabled="!selected_period" style="margin:20px 0px 0px 5px" class="btn" id="find_period" @click="loadDate"><i class="fas fa-search fa-fw"></i> Search</button>
                </div>
                <div class="p-2 bd-highlight">
                    <button :disabled="!selected_period" v-if="!is_super_admin" style="margin:20px 0px 0px 5px" class="btn btn-danger" id="delete_period_btn" @click="deletePeriod"><i class="fas fa-trash fa-fw"></i> Delete Period</button>
                </div>
            </div>   
            <hr>
        <p class="" style="">
            <input @change="toggle_row_select" class="vx_input_2" type="checkbox" id="sel_payslip">
            <label for="sel_payslip">Select All</label>
        </p>
        <div style="margin-bottom:15px" class="btn-group" role="group" aria-label="Basic example">
            <button type="button" :disabled="selected_ids.length == 0" @click="enter_bulk_email" class="btn btn-secondary btn-info"><i class="fas fa-envelope"></i> Send bulk to mail</button>
        </div>
        <div class="accordion_headers" style="margin-bottom:15px">
            <div class="accordion">
                <div class="heading">Payslip headers</div>
                <div class="contents">
                    <div class="row">
                        <p><i class="fas fa-exclamation-circle fa-fw"></i> Click on headers to exlude / include in payslip</p>
                        <div class="bg-light" style="padding: 5px; border:1px solid gray">
                            <span class="button-group" v-for="(x,index) in selected_row_header">
                            <input :id="'checkbox1' + index"  @click = "toggle_check(x)" :checked="x.required" type="checkbox" v-if="x.value != '' && x.value != null">
                            <label class="vx_selct_btn" :for="'checkbox1'+index" v-if="x.value != '' && x.value != null">{{x.value}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <button style="margin-top:5px" @click="updateSlipHeaders" id="update_header" class="btn btn-primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">

            </div>
        </div>
        <div :id="'example-table'+unique_id"></div>
        <div class="modal" id="preview_slip" tabindex="-1" role="dialog" aria-labelledby="disEmbarkModal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myModalLabel">Pay slip preview</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-lg-offset-0 col-lg-6 col-xs-12 col-sm-6" style="margin-top:15px">
                                <div class="form-group">

                                    <span>
                                    <label for="exampleInputEmail1">Send Email</label>
                                    <br>
                                                                    <p style="margin:0 !important">
                                    <input @click = "toggle_manual_email"  class="vx_input_2" type="checkbox" id="test2">
                                    <label for="test2"> Enter email address</label>
                                </p>
                                    </span>
                                    <div class="div_colLeft" style="margin-right:5px">
                                        <input type="text" v-show="manual_email_show" style="margin-top:5px" v-model="manual_email" class="form-control" placeholder="seafarer's email">

                                    </div>
                                    <div class="div_colwrap">
                                        <button @click="sendManualMail" id="send_manual_mail" :disabled="allow_manual_email" class="btn btn-primary" style="margin: 3px 0px 0px 0px;">Send</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button @click="print_slip" class="btn btn-m btn-info pull-right" style="margin-right:15px"><i class="fas fa-print fw"></i> Print Paylsip</button>
                        </div>
                    </div>
                    <div class="modal-body" id="slip_content">

                    </div>
                </div>
            </div>
        </div>
<div class="modal" id="bulk_modal" tabindex="-1" role="dialog" aria-labelledby="disEmbarkModal" aria-hidden="true">
    <div class="modal-dialog vx_modal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Bulk payslip mailing</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
               <div class="row" style="padding:15px">
                    <div class="col-md-12">
                            <label>Provide a valid email address</label>
                <input type="text" class="form-control" v-model="bulk_email">
                    </div>
               </div>
            </div>
            <div id="modal_body"></div>
            <div class="modal-footer">
                <div class="pull-left row" style="margin:5px 12px 0px 0px">
                    <button @click.prevent="send_bulk_mails" :disabled = "bulk_email == ''" style="color:white" id="request_access_btn" role="button" class="btn btn-primary save_record">Send mail</button>
                 </div>
            </div>
        </div>
    </div>
</div>
        <loader v-if="show_loading"></loader>
        
    </div>
</template>

<script>
const uuidv1 = require('uuid/v1');
import vx_select from './vx_select.vue';
import loader from './loader.vue';
import alert_mixins from '../mixins/alertify.jsx';
export default {
    components : {vx_select,loader},
    mixins : [alert_mixins],
    props : {
        is_super_admin : {
            default : false
        },
        remote_headers : {
            default : false
        },
        options : {
            default : false
        },
        pdf_heading : {
            default : "Report"
        },
        "columns" : {},
        "group_by" : {
           default : false, 
        },
        remote_url : {

        },
        placeholder : {
            default : "Search by name"
        }
    },
    created(){
        this.loadPeriods();
        this.loadDate()
    },
    mounted(){
      window.update_header = Ladda.create( document.getElementById('update_header'));
        this.tab_columns = this.columns;
        const com_this = this
        const table = "table" + this.unique_id
        window[table] = new Tabulator("#example-table"+this.unique_id, {
            height:"400px",
            layout:"fitColumns",
            groupToggleElement:"header",
            movableRows:true,
            selectable:true,
            groupBy:this.group_by,
            columns: this.tab_columns,
            pagination:"local", //enable local pagination.
            paginationSize:20,
            rowSelectionChanged:function(data, rows){
                com_this.selected_ids = data.map(element => {
                    return {
                        record_id: element.id,
                        seafarer_id_gen: element.SEAFARER_ID
                    }
                });
            },
        });
        $("#download-csv"+com_this.unique_id).click(function(){
            window["table" + com_this.unique_id].download("csv", "data.csv");
        });
        $("#download-xlsx"+com_this.unique_id).click(function(){
            window["table" + com_this.unique_id].download("xlsx", "data.xlsx", {sheetName:"My Data"});
        });

        //trigger download of data.pdf file
        $("#download-pdf"+com_this.unique_id).click(function(){
            window["table" + com_this.unique_id].download("pdf", "data.pdf", {
                orientation:"landscape", //set page orientation to portrait
                title:com_this.pdf_heading, //add title to report
            });
        });

         $(".accordion").on("click", ".heading", function() {

       $(this).toggleClass("active").next().slideToggle();

       $(".contents").not($(this).next()).slideUp(300);
                    
       $(this).siblings().removeClass("active");
       });
                
    },

    data(){
        return {
            selected_ids : [],
            tab_columns : null,
            user_id : "",
            seaf_name : "",
            company_id : "",
            vessel_id : "",
            select_all_val : false,
            unique_id : uuidv1(),
            selected_period : null,
            seafarer_id : "",
            bulk_email  : "",
            select_options : [],
            manual_email_show :  false,
            selected_row_header : [],
            query_param : null,
            record_id : null,
            seafarer_id : null,
            select_all_val : false,
            manual_email : "",
            selected_ids : [],
             res_data : [],
             rows : [],
             slip_info_id : null,
             toggle_group_show : "vessel_name"
        }
    },
    methods : {
        enter_bulk_email(){
          $("#bulk_modal").modal('show');
        },
        print_slip(){
            window.frames["thepage"].focus();
            window.frames["thepage"].print();
        },
        toggle_row_select(){
            this.select_all_val != this.select_all_val;
            if(this.select_all_val == false){
                window["table" + this.unique_id].selectRow();
            }else{
                window["table" + this.unique_id].deselectRow();
            }
        },
        send_bulk_mails(){
            const self = this;
            axios.post("/payroll/send_bulk_mails",{
                selected_ids : this.selected_ids,
                bulk_email : this.bulk_email,
            })
            .then(res => {
                if(res.data.status == "SUCCESS"){
                    this.alert({
                          alert_type : "success",
                          title : "Seafarer payslip bulk mailing..",
                          alert_msg : "Mail sent",
                          msg : res.data.msg
                    }).set({onclose : function(){
                        $("#bulk_modal").modal('hide');
                        self.bulk_email = '';
                    }})
                }
                if(res.data.status == "FAIL"){
                    this.alert({
                          alert_type : "error",
                          title : "Seafarer payslip bulk mailing..",
                          alert_msg : "Error processing bulk emailing",
                          msg : "<i class='fas fa-exclamation-circle'></i>"+" "+res.data.msg
                    })
                }
            })
        },
        sendManualMail(){
      window.sen_man_btn = Ladda.create( document.getElementById('send_manual_mail'));
        sen_man_btn.start();
            axios.post("/payroll/send_manual_mail",{
               slip_id : this.record_id,
               seafarer_id_gen : this.seafarer_id,
               email : this.manual_email, 
            })
            .then(res => {
                if(res.data.status == "SUCCESS"){
                    this.alert({
                          alert_type : "success",
                          title : "Seafarer payslip",
                          alert_msg : "success",
                          msg : res.data.msg
                    }).set({onclose:function(){
                    $("#preview_slip").modal('hide');
                 }})
                 sen_man_btn.stop();
                }
                if(res.data.status == "ERROR"){
                    this.alert({
                          alert_type : "error",
                          title : "Seafarer payslip",
                          alert_msg : "error",
                          msg : res.data.msg
                    })
                    sen_man_btn.stop();
                }
            })
        },
        toggle_manual_email(){
            this.manual_email = "";
            this.manual_email_show = !this.manual_email_show;
        },
        findSlipPeriod(){

        },
        updateSlipHeaders(){
            update_header.start()
            axios.post("/payroll/update_slip_header",{
                slip_info_id : this.slip_info_id,
                headers : this.selected_row_header
            })
            .then(res => {
                if(res.data.status == "SUCCESS"){
                    update_header.stop();
                    this.alert({
                          alert_type : "success",
                          title : "Seafarer payslip",
                          alert_msg : "success",
                          msg : res.data.msg
                        })
                }
            })
            .catch(res => {
                update_header.stop();
                this.alert({
                          alert_type : "error",
                          title : "Seafarer payslip",
                          alert_msg : "error",
                          msg : res.data
                        })
            })
        },
        send_ids(){
            this.$emit('table_ids',this.selected_ids
            );
        },
        toggle_check(obj){
          this.selected_row_header.forEach(objc => {
            if(objc.index == obj.index){
              objc.required = !obj.required
            }
          });
        },
        delete_slip(data){
            this.show_loading = true;
            const record_id = data.getRow().getData().id
            axios.post("/payroll/delete_slip",{
                record_id : record_id
            })
            .then(res => {
                data.getRow().delete();
                this.show_loading = false;
                if(res.data.status == "SUCCESS"){
                        this.show_loading = false;
                        this.alert({
                          alert_type : "success",
                          title : "Seafarer payslip",
                          alert_msg : "success",
                          msg : res.data.msg
                        })
                     
                }
            }).catch(res => {
                this.show_loading = false;
                        this.alert({
                          alert_type : "error",
                          title : "Seafarer payslip",
                          alert_msg : "error",
                          msg : res.data
                        })
            })
        },
        perform_mail(data){
            this.show_loading = true;
            this.manual_email_show = false;
            this.manual_email = "";
            this.record_id = data.id
            this.seafarer_id = data.SEAFARER_ID
            axios.get('/search/find_seafarer_payslip',{
                params : {
                    record_id : this.record_id,
                    seafarer_id : this.seafarer_id,
                    user_id : this.user_id
                }
            })
            .then(res => {
                if(res.data.status == "FAIL"){
                    this.show_loading = false;
                        this.alert({
                          alert_type : "error",
                          title : "Seafarer payslip",
                          alert_msg : "error",
                          msg : res.data.msg
                        })
                }else{
                    this.show_loading = false;
                    var iFrame = $('<iframe align="middle" id="thepage" name="thepage" style="width:100%;height:100%;"></iframe>');
$('#slip_content').empty();
$('#slip_content').append(iFrame);


                var iFrameDoc = iFrame[0].contentDocument || iFrame[0].contentWindow.document;
                iFrameDoc.write(res.data);
                iFrameDoc.close();
                $("#preview_slip").modal('show');
            }
                                
            });
        },
        toggle_row_select(){
            this.select_all_val != this.select_all_val;
            if(this.select_all_val == false){
                window["table" + this.unique_id].selectRow();
            }else{
                window["table" + this.unique_id].deselectRow();;
            }
        },
        deletePeriod(){
            const com_this = this;
            window.delete_period_btn = Ladda.create( document.getElementById('delete_period_btn'));
        alertify.confirm('Alert are you sure you want to delete',function(e){
            if(e){
                delete_period_btn.start()
                axios.post("/payroll/delete_period",{
                    record_id : com_this.selected_period,
                    vessel_id : com_this.vessel_id
                })
                .then(res => {
                    delete_period_btn.stop()
                    if(res.data.status == "SUCCESS"){
                        com_this.alert({
                            alert_type : "success",
                            title : "Seafarer payslip",
                            alert_msg : "success",
                            msg : res.data.msg
                        }).set({onclose:function(){
                            location.reload()
                        }})
                    }
                    if(res.data.status == "ERROR"){
                        delete_period_btn.stop()
                        com_this.alert({
                            alert_type : "error",
                            title : "Seafarer payslip",
                            alert_msg : "error",
                            msg : res.data.msg
                        })
                    }
                })
            }
        })
        .set({title: "Deleting record..."});
             
        },

        loadDate(){
            this.show_loading = true;
            var period = null;
            this.manual_email_show = false;
            this.manual_email = "";
            if(this.selected_period != null){
                this.select_options.forEach(opt => {
                    if(opt.id == this.selected_period){
                        period = opt.text
                    }
                })
            }
            const com_this = this;
                axios.get(this.remote_url,{
                    params : {
                        period : period,
                        seafarer_id : this.seafarer_id,
                        seaf_name : this.seaf_name,
                        vessel_id : this.vessel_id
                    }
                })
                 .then(res => {
                    this.tab_columns = res.data.columns;
                    this.res_data = res.data.data
                    this.selected_row_header = res.data.headers
                    this.slip_info_id = res.data.info_id
                    this.show_loading = false;
                 })    
        },
        loadPeriods(){
            axios.get("/search/payslip_periods")
                 .then(res => {
                     if(res.data.status == "SUCCESS"){
                         this.select_options = res.data.data.periods;
                     }
                 })
        },
        search(){
            axios.get(this.remote_url,{
                params : {
                name : this.query_param
                }
            })
            .then(res => {
                this.res_data =res.data.data
            });
        }
    },
    computed :{
        allow_manual_email(){
            if(this.manual_email_show == true && this.manual_email == ""){
                return true
            }else{
                return false
            }
        }
    },
    watch : {
        res_data : {
            handler : function(){
                const com_this = this;
                window["table" + this.unique_id].redraw()
                if(com_this.tab_columns){
                    window["table" + this.unique_id].setColumns(this.tab_columns);
                }

                var deleteIcon = function(){
                    return "<i class='fa fa-trash'></i>";
                }

                var previewIcon = function(){
                    return "<i class='fa fa-envelope'></i>";
                }
                

                var obj0 = {
                    formatter: deleteIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.delete_slip(cell)}
                }
                
                var obj1 = {
                    formatter: previewIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.perform_mail(cell.getRow().getData())}
                }

                if(com_this.tab_columns){
                    com_this.tab_columns.unshift(obj0); 
                    com_this.tab_columns.unshift(obj1); 
                }

                window["table" + this.unique_id].setData(this.res_data).then(function(){
                 setTimeout(function(){ 
                
                if(com_this.tab_columns){
                  window["table" + com_this.unique_id].setColumns(com_this.tab_columns);
                  window["table" + com_this.unique_id].redraw()
                }
                }, 4000);
                })
                
            },
            deep : true
        }
    }
}
</script>

<style scoped>
.tabulator {
    font-size: 11.5px !important;
}
.w-240 {
  width: 240px;
}
.fa-file-excel{
  color: green !important;
}
.fa-file-csv{
  color: blue !important;
}
caption {
  margin-bottom: -30px !important
}
.modal-content {
    width: 160% !important
}


.button-group input {
  display: none;
}

.button-group input + label{
background-color: grey
}
.button-group input:checked + label,
.button-group input:checked + label:active {
  background-color: darkseagreen;
}


@media (min-width: 576px){
    .modal-dialog {
        margin: 0% 0% 0% 20% !important;
    }
}

@media (min-width: 576px){
.modal-dialog.vx_modal {
    max-width: 300px;
    margin: 0 auto !important;
}
}
.accordion {
    background: linear-gradient(to bottom right, #FFF, #f7f7f7);
    background: #0097a7;
    border-radius: 3px;
    box-shadow: 0 10px 15px -20px rgba(0, 0, 0, 0.3), 0 30px 45px -30px rgba(0, 0, 0, 0.3), 0 80px 55px -30px rgba(0, 0, 0, 0.1);
}
.vx_selct_btn {
  position: relative;
  padding : 5px 8px 5px 8px
}

.heading {
    color: #FFF;
    font-size:12px;
    border-bottom: 1px solid #e7e7e7;
	letter-spacing: 0.8px;
    padding: 5px;
    cursor: pointer;  
}
.heading:nth-last-child(2){
    border-bottom:0; 
}
.form-group {
        margin-bottom: 0;
        padding: 10px 0;
    }
    
    .form-group:first-child {
        border-color: transparent;
    }
    
    .form-group.has-icon .control-group {
        position: relative;
    }
    
    .form-group.has-icon .control-group .btn-search {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 16px;
        height: 16px;
        border: transparent;
        background: transparent;
    }
    
    .form-group.has-icon .control-group .btn-search > .icon {
        top: 0;
        left: 0;
    }
    
    .form-group.has-icon .control-group .icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        fill: #757575;
    }
    
    .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
.heading:hover {
    background: #00838f;
    border-radius: 0;
}
.heading:first-child:hover {
    border-radius: 3px 3px 0 0;
}
.heading:nth-last-child(2):hover{
    border-radius:0 0 3px 3px;
}
.heading::before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    border-top: 7px solid #f5f5f5;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    float: right;
    transform: rotate(0);
    transition: all 0.5s;
    margin-top: 5px;
}
.active.heading::before {
    transform: rotate(-180deg);
}
.not-active.heading::before {
    transform: rotate(0deg);
}
.contents {
	display: none;
	background: #FFFAFA;
	padding: 15px;
    color: #7f8fa4;
    font-size: 13px;
    line-height: 1.5;
}

</style>



