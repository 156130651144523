var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown", attrs: { id: "notifications-btn" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-menu box-md float-right" }, [
        _c("div", { staticClass: "scrollable-content scrollable-slim-box" }, [
          _c(
            "ul",
            { staticClass: "no-border notifications-box" },
            _vm._l(_vm.notifications, function(notify) {
              return _c("li", [
                _c("span", {
                  class: [
                    "bg-danger",
                    "icon-notification",
                    "glyph-icon",
                    "fas",
                    notify.font_type
                  ]
                }),
                _vm._v(" "),
                _c("span", { staticClass: "notification-text" }, [
                  notify.url != ""
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href:
                              notify.url +
                              "?request_id=" +
                              notify.notifiable_id +
                              "&request_type=" +
                              notify.action.replace(/ /g, "_").toLowerCase()
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(notify.user_email) +
                              " " +
                              _vm._s(notify.action.toLowerCase()) +
                              " " +
                              _vm._s(notify.notifiable)
                          )
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          _vm._s(notify.user_email) +
                            " " +
                            _vm._s(notify.action.toLowerCase()) +
                            "  " +
                            _vm._s(notify.notifiable)
                        )
                      ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "notification-time" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(notify.time) +
                      "\n                        "
                  ),
                  _c("span", { staticClass: "glyph-icon fas fa-clock" })
                ])
              ])
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { "data-toggle": "dropdown", href: "#", title: "" } },
      [
        _c("span", { staticClass: "small-badge bg-yellow" }),
        _vm._v(" "),
        _c("i", {
          staticClass: "glyph-icon fas fa-bell",
          attrs: { id: "alert_bell" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }