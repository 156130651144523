import admin_tosdere from './components/admin/admin_tosdere.vue'
import dashboard from './components/admin/dashboard/dashboard.vue'
import remote_dashboard from './components/admin/dashboard/remote_dashboard.vue'
import tnl_frm_1 from './components/admin/seafarer_reg/tnl_frm_1.vue'
import tnl_frm_1_entry from './components/admin/seafarer_reg/tnl_frm_1_entry.vue'
import tnl_frm_1_entry_portal from './components/admin/seafarer_reg/tnl_frm_1_entry_portal.vue'
import select_reg_type from './components/admin/seafarer_reg/select_reg_type.vue'
import user_settings from './components/admin/user/user_settings.vue'
import registration from './components/admin/seafarer_reg/registration.vue'
import initiate_payroll from './components/admin/payroll/initiate_payroll.vue'
import payslip_list from './components/admin/payroll/payslip_list.vue'
import mails from './components/admin/mails/index.vue'
import document_tracker from './components/admin/document_tracker/document_tracker.vue'
import seafarer_view from './components/admin/seafarer_reg/seafarer_view.vue'
import devise_login from './components/devise/login.vue'
import seafarer_list from './components/admin/seafarer_reg/seafarer_list.vue'
import custom_list from './components/admin/seafarer_reg/custom_list.vue'
import init_seafarer_remote from './components/admin/seafarer_reg/init_seafarer_remote.vue'
import begin_embark from './components/admin/seafarer_embark/begin_embark.vue'
import embarkment_list from './components/admin/seafarer_embark/embarkment_list.vue'
import company_reg from './components/admin/company/company_reg.vue'
import company_list from './components/admin/company/company_list.vue'
import vessel_reg from './components/admin/vessel/vessel_reg.vue'
import vessel_view from './components/admin/vessel/vessel_view.vue'
import vx_notification from './components/utils/vx_notification.vue';
import vessel_list from './components/admin/vessel/vessel_list.vue'
import company_view from './components/admin/company/company_view.vue'
import payslip_table from './components/utils/payslip_table.vue';
import vessel_crew from './components/utils/vessel_crew.vue';
import data_table from './components/utils/data_table.vue';
import vx_datatable from './components/utils/vx_datatable.vue';


const components = {
  initiate_payroll,
    vessel_reg,
    init_seafarer_remote,
    tnl_frm_1_entry_portal,
    devise_login,
    vx_notification,
    mails,
    begin_embark,
    document_tracker,
    user_settings,
    custom_list,
    remote_dashboard,
    embarkment_list,
    vessel_list,
    vessel_crew,
    company_list,
    vx_datatable,
    seafarer_list,
    data_table,
    vessel_view,
    company_view,
    company_reg,
    payslip_list,
    seafarer_view,
    payslip_table,
    registration,
    admin_tosdere,
    dashboard,
    tnl_frm_1,
    tnl_frm_1_entry,
    select_reg_type,
    'foo': {
        template: '<h1>Foo component</h1>'
      },
      'bar': {
        template: '<h1>Bar component</h1>'
      }
}

export default components;