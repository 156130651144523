var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticStyle: { margin: "0" } }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.status,
          expression: "status"
        }
      ],
      staticClass: "vx_input_2",
      staticStyle: { margin: "0", "vertical-align": "middle" },
      attrs: {
        title: _vm.dis_el
          ? "No access to this record"
          : "You have access to this record",
        type: "checkbox",
        disabled: _vm.dis_el,
        name: "MultiSelect",
        id: _vm.uuid
      },
      domProps: {
        checked: Array.isArray(_vm.status)
          ? _vm._i(_vm.status, null) > -1
          : _vm.status
      },
      on: {
        change: [
          function($event) {
            var $$a = _vm.status,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.status = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.status = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.status = $$c
            }
          },
          _vm.toggle
        ]
      }
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.uuid } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }