var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.excel_rows.length > 0,
          expression: "excel_rows.length > 0"
        }
      ],
      staticClass: "fixed_height_pr_tb"
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fixed_content_pr_tb" }, [
      _c("h3", [_vm._v("Sheet Details")]),
      _vm._v(" "),
      _c("div", { attrs: { id: "example" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }