<template>
    <div>
         <v-select :disabled = "disable_com" :clearable="false"  label="name" @input="updateInput"  :filterable="false" :options="options" @search="onSearch">
    <template slot="no-options">
      enter Seafarer's ID to find...
    </template>
    <template slot="option" slot-scope="option">
      <div class="d-center">
        {{ option.full_name }}
        </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
        {{ selected }}
      </div>
    </template>
  </v-select>
    </div>
</template>

<script>

import VueSelect from 'vue-select/src/components/Select.vue'

Vue.component("v-select", VueSelect);

export default {
  props : {
    disable_com : {
      default : false
    }
  },
    data(){
        return {
            selected : null,
            options : [],
            option : []
        }
    },
    methods: {
        updateInput(value){
          this.selected = value.full_name;
           this.$emit('input',value.value);
           this.$emit('input_change',value.value)
        },
        onSearch(search,loading){
            const vm = this;
            loading(true);
            axios.get('/search/search_seafarer',{
                params : {
                    value : search,
                    type : "id"
                }
            })
                .then(res => {
                    vm.options = res.data.data
                    loading(false);
                });
        }
    },
    mounted() {
      const com_this = this;
      $(window).on('clear_v_select', function (e) {
          com_this.selected = "";
      });
    },
}
</script>

<style scoped>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}
.vs--single .vs__selected {
    background-color: transparent;
    border-color: transparent;
}
.v-select, .v-select * {
    box-sizing: border-box;
}
.vs__search, .vs__search:focus {
     width: inherit !important
}
.vs__dropdown-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    padding: 0 0 4px;
    background: none;
    border: 1px solid rgba(60,60,60,.26);
    border-radius: 4px;
    white-space: normal;
}
.vs__selected-options {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0 2px;
    position: relative;
}
.vs__selected {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border: 1px solid rgba(60,60,60,.26);
    border-radius: 4px;
    color: #333;
    line-height: 1.4;
    margin: 4px 2px 0;
    padding: 0 .25em;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}


</style>