import axios from "axios";

var record_access = {
    data(){
        return {
            request_comment : null,
        }
    },
    methods : {
        showRequestModal(){
            $('#requestModal').modal('show');
        },
        sendRequestAccess(options){
        window.request_access_btn = Ladda.create( document.getElementById(options.request_access_btn));

            request_access_btn.start()
            axios.post(options.req_acces_url,{
              record_id : options.record_id,
              comment : this.request_comment
            }).then(response => {
                if(response.data.status == "SUCCESS"){
                  request_access_btn.stop()
                    request_access_btn.stop();
                    this.alert({
                      alert_type : "success",
                      title : "Access request",
                      alert_msg : "success",
                      msg : response.data.msg
                    }).set({onclose:function(){
                       window.location.reload();
                        }})
                      }
                })
                .catch(data => {
                    if(data.data.status == "FAIL"){
                      request_access_btn.stop();
                        this.alert({
                          alert_type : "error",
                          title : "Request Failed",
                          alert_msg : "failed",
                          msg : data.data.msg
                        })
                    }
                    request_access_btn.stop();
                })
            
          },
    },
    mounted(){
    }
    
}

export default record_access;