var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "-col-group", attrs: { name: "ColumnGroup" } },
    [
      _c("label", { staticClass: "-col-group-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.groupName === "undefined" ? "Columns" : _vm.groupName) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.columns, function(col, idx) {
        return _c("li", [
          _c("input", {
            attrs: {
              type: "checkbox",
              id: _vm.uuidGen(col.field || idx),
              name: _vm.groupName,
              disabled: typeof col.visible === "string"
            },
            domProps: { checked: _vm.isColVisible(col) },
            on: {
              change: function($event) {
                return _vm.handleChange(col, $event.target.checked)
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: _vm.uuidGen(col.field || idx) } }, [
            _vm._v("\n      " + _vm._s(col.label || col.title) + "\n      "),
            col.explain
              ? _c("i", {
                  staticClass: "fa fa-info-circle",
                  staticStyle: { cursor: "help" },
                  attrs: { title: col.explain }
                })
              : _vm._e()
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }