import axios from "axios";

var request_access = {
    data(){
        return {
            request_access_comment : null,
            request_id : this.record_access.id,
            request_status : null,
            options : {}
        }
    },
    methods : {
        approve_request(){
            if(this.type == "remote_reg"){
                this.remote_reg_approve();
            }else{
                this.default_approve();
            }
        },
        remote_reg_approve(){
            $("#assign_company_show").modal('show');
        },
        default_approve(){
            window.access_btn = Ladda.create( document.getElementById('approve_btn'));
            access_btn.start()
            this.request_status = "APPROVED";
            this.attempt_request();
        },
        request_reject(){
            window.access_btn = Ladda.create( document.getElementById('reject_btn'));
            access_btn.start()
            this.request_status = "REJECTED";
            this.attempt_request();
        },
        revert_the_req(){
            window.access_btn = Ladda.create( document.getElementById('revert_btn'));
            access_btn.start()
            this.request_status = "REVERTED";
            this.attempt_request();
        },
        attempt_request(){
           axios.post(this.approval_url,{
               options : this.options,
               request_id : this.request_id,
               request_status : this.request_status,
               comment : this.request_access_comment
           }).then(res => {
                if(res.data.status == "SUCCESS"){
                    access_btn.stop()
                    this.alert({
                        alert_type : "success",
                        title : "Request approval",
                        alert_msg : "success",
                        msg : res.data.msg
                      }).set({onclose:function(){
                         window.location.reload();
                        }})
                }
           }).catch(res => {
            access_btn.stop()
            this.alert({
                alert_type : "error",
                title : "Request approval failed",
                alert_msg : "Failed",
                msg : res.data.msg
              }).set({onclose:function(){
                 window.location.reload();
                 }})
           })
        }
    },
    watch : {
        expected_param : {
            handler(){
                window.access_btn = Ladda.create( document.getElementById('approve_btn'));
                access_btn.start()
                this.request_status = "APPROVED";
                this.options = this.expected_param;
                this.attempt_request();
            },
            deep: true
        }
    }
}

export default request_access;