<template>
    <div>
<div class="row" v-if="user_is_admin">
<div class="col-md-4" style="margin-bottom:5px">
    <label>Filter by User/Author</label>
        <vx_select 
        v-model="user_id"
        :remote_options="true"
        :remote_url="'/search/get_users'"
        placeholder="User">
    </vx_select>
</div>
</div>
        <div class="paper_no_shw col-md-12" style="margin-bottom:15px">
            <div class="row">
                <div class="col-lg-4 col-xs-12 col-sm-4">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Vessel Name</label>
                        <input type="text" v-model ="vessel_name" class="form-control">
                    </div>
                </div>

                <div class="col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Company</label>
                        <vx_select 
                            v-model="company_id"
                            :remote_options="true"
                            :remote_url="'/search/get_companies'"
                            placeholder="Companies">
                        </vx_select>
                    </div>
                </div>

                <div class="col-lg-offset-0 col-lg-3 col-xs-12 col-sm-3">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Flagship</label>
                        <vx_select 
                            v-model="country_id"
                            :remote_options="true"
                            :remote_url="'/search/get_countries'"
                            placeholder="Flagship">
                        </vx_select>
                    </div>
                </div>
                <div class="col-lg-offset-0 col-lg-1 col-xs-12 col-sm-1">
               <button style="margin-top:15px" @click="search" class="btn btn-primary">Search</button>

                    </div>

            </div>

            
        </div>
        <div class="project box col-md-12" style="margin-bottom:15px" id="example-table"></div>
    </div>
</template>

<script>
import alert_mixins from '../mixins/alertify.jsx';
import vx_select from '../utils/vx_select.vue'
export default {
    mixins : [alert_mixins],
    components : {vx_select},
    props : {
        user_is_admin : {
            default : false
        }
    },
    data(){
        return {
        vessel_name : "",
        company_id : "",
        country_id : "",
        user_id : "",
        data_row : [
                
            ]
        }
    },
    methods : {
        view(data){
            window.location = "/vessel/view/" + data.id
        },
        search(){
            const com_this = this;
            window.vessel_table.setData("/search/vessels",{
                vessel_name : com_this.vessel_name,
                country_id : com_this.country_id,
                company_id : com_this.company_id,
                user_id : com_this.user_id
            })
        },
        deleteVessel(data){
            const com_this = this;
            alertify.confirm('Warning! Deleting a vessel will remove all associated embarkations with it.',function(e){
                if(e){
                axios.post('/vessel/delete_vessel',{
                    id : data.getRow().getData().id
                }).then(res => {
                    if(res.data.status == "SUCCESS"){
                        com_this.alert({
                            alert_type : "success",
                            title : "Vessel deletion...",
                            alert_msg : "success",
                            msg : res.data.msg
                        }).set({title : "Vessel deletion..."});
                        data.getRow().delete()
                    }
                }).catch(res => {
                    com_this.alert(res.data)
                }) 
                    }
                }).set({title: "Vessel deletion"});
        }
    },
    mounted(){
        const com_this = this;
                    var deleteIcon = function(){
                    return "<i class='fas fa-trash-alt'></i>";
                }
                var obj0 = {
                    formatter: deleteIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.deleteVessel(cell)}
                }
                var viewIcon = function(){
                    return "<i class='fas fa-file'></i>";
                }
                var obj1 = {
                    formatter: viewIcon,
                    width:40,
                    align:"center",
                    cellClick:function(e, cell){com_this.view(cell.getRow().getData())}
                }

        window.vessel_table = new Tabulator("#example-table", {
            height:"500px",
            layout:"fitColumns",
            ajaxURL:"/search/vessels",
            ajaxProgressiveLoad:"scroll",
            paginationSize:20,
            pagination : "remote",
            placeholder:"No Data Set",
            columns:[
                {title:"ID", field:"id", sorter:"string"},
                {title:"Vessel Name", field:"name", sorter:"number"},
                {title:"Company Name", field:"company_name", sorter:"number"},
                {title:"Vessel", field:"vessel_typ_id", sorter:"string"},
                {title:"Kilowat", field:"kilowat", align:"center"},
                {title:"Flagship", field:"flagship", sorter:"string", sortable:false},
                {title:"User", field:"user", sorter:"string", sortable:false},
            ],
            footerElement : '<div style="float:left" id="table-footer"> <span style="font-weight:100">Total records:</span> <b><span class="max"></span></b></div>',
            ajaxResponse:function(url, params, response){
     
        $("#table-footer .max").html(response.total)

        return response; //return the response data to tabulator (you MUST include this bit)
    }
        });
        window.vessel_table.addColumn(obj0)
        window.vessel_table.addColumn(obj1)

    }
}
</script>

<style scoped>
.w-240 {
  width: 240px;
}
.fa-file-excel{
  color: green !important;
}
.fa-file-csv{
  color: blue !important;
}
caption {
  margin-bottom: -30px !important
}

 .form-group.has-icon .control-group .form-control {
        padding-left: 32px;
    }
    
    input.form-control {
        height: 30px !important;

    }
   .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-width: 1px;
        min-height: 20px;

    }
    
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .form-vertical {
        counter-reset: fieldset;
    }
    
    .form-vertical fieldset {
        padding-top: 10px;
        margin: 50px 0;
    }
    
    .form-vertical fieldset > legend:before {
        content: counter(fieldset);
        counter-increment: fieldset;
        position: absolute;
        left: -25px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #41545d;
        color: white;
        font-size: 75%;
        font-weight: bold;
    }
    
    label.checkbox {
        margin-bottom: 15px;
        position: relative;
    }
    
    label.checkbox .icheckbox_flat {
        position: absolute !important;
        top: 0;
        left: 0;
    }
    
    label.checkbox input {
        position: absolute;
        left: 0;
        top: 0;
    }
    
    label.checkbox span {
        padding-left: 35px;
        display: block;
    }
    
    .radio label {
        padding-left: 0;
    }
    
    .radio span {
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .btn {
        position: relative;
        height: auto;
        padding: 10px 16px;
        border-radius: 3px;
        min-width: 80px;
        font-weight: bold;
        line-height: 16px;
        border-width: 2px;
        border-style: solid;
    }
    
    .btn:active {
        top: 1px;
        box-shadow: none;
    }
    
    .btn.large-btn {
        padding: 16px 24px;
        font-size: 16px;
    }
    
    .btn-light {
        background: #bdbdbd;
        border-color: #bdbdbd;
    }
    
    .btn-light:hover {
        background: #b0b0b0;
    }
    
    .btn-default {
        background: white;
        border-color: #bdbdbd;
    }
    
    .btn-default:hover {
        border-color: #b0b0b0;
    }
    
    .btn-primary {
        background-color: #41545d;
        border-color: #41545d;
        color: white !important
    }
    
    .btn-primary:hover {
        background-color: #38474f;
    }
    
    .btn-inverted {
        background-color: #38474f;
        color: #fff !important;
    }
    
    .btn-inverted:hover {
        background-color: #2d3a40;
    }
    
    .btn-group.radio-group .btn {
        height: 50px;
        line-height: 22px;
        padding: 12px 20px;
    }
    
    .btn-group.radio-group .btn span {
        line-height: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    
    .form-control:focus {
        border-color: #41545d;
    }
    /* bootstrap select styles */
    
    .bootstrap-select .btn {
        min-height: 40px;
        border-width: 2px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    
    .bootstrap-select .btn:hover {
        background: white;
    }
    
    .bootstrap-select .btn:focus,
    .bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        background: white;
        outline: 0 !important;
    }
    
    .bootstrap-select .btn.bootstrap-select.btn-group.open .dropdown-toggle {
        border-color: #41545d;
        color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        background: #41545d;
        color: white;
        outline: 0 !important;
    }
    
    label {
        cursor: pointer;
    }
    
    :-ms-input-placeholder {
        color: #ccc;
    }
    
    ::-moz-placeholder {
        color: #ccc;
    }
    
    ::-webkit-input-placeholder {
        color: #ccc;
    }
    /* control search */
    
    .control-search {
        -webkit-border-radius: 40px;
        border-radius: 40px;
    }
    /* group addon */
    
    .paper_no_shw{
        padding:10px !important
    }
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    display: none !important
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel{
    font-size: 10px !important
}
input[type="text"]{
    text-transform: uppercase !important
}
</style>
